import { useModelFulcrumStore } from '@/pinia'
import { XeokitMediator } from '../XeokitMediator'
import { math } from '@xeokit/xeokit-sdk'
import { geometry } from '../plugins/geometry/geometry'

/*eslint-disable no-dupe-class-members*/
export class ModelFulcrum {

  static #_onSceneTick = null

  static #_fulcrumMeshes = null

  static get #_camera() {
    return XeokitMediator.viewer.camera
  }

  static get #_modelFulcrumStore() {
    return useModelFulcrumStore()
  }

  static get modelFulcrumCoord() {
    return this.#_modelFulcrumStore.modelFulcrumCoord
  }

  static init() {
    const center = [0, 0, 0]
    this.fixModelFulcrum(center)
    let newCameraScale = geometry.math.subVec3(center, this.#_camera.eye)
    this.#_camera.scale = math.lenVec3(newCameraScale)
  }
  
  static fixModelFulcrum(modelFulcrumCoord) {
    this.#_modelFulcrumStore.setModelFulcrum(modelFulcrumCoord)
    this.#_deactivateRuntimeScaling()
    this.destroyFulcrum()
    this.#_fulcrumMeshes = XeokitMediator.SceneObjects.createViewPointMeshes(this.#_createScale(modelFulcrumCoord), modelFulcrumCoord)
    this.#_activateRuntimeScaling(modelFulcrumCoord)
  }

  static #_activateRuntimeScaling(worldPos) {
    let tempVec3a = math.vec3()
    let lastDist = -1
    this.#_onSceneTick = XeokitMediator.viewer.scene.on("tick", () => {

      math.subVec3(this.#_camera.eye, worldPos, tempVec3a)
      const dist = Math.abs(math.lenVec3(tempVec3a))

      if (dist !== lastDist) {
        if (this.#_camera.projection === "perspective") {
          const worldSize = (Math.tan(this.#_camera.perspective.fov * math.DEGTORAD)) * dist
          const size = 0.07 * worldSize
          const scale = [size, size, size]

          if(this.#_fulcrumMeshes != null) {
            this.#_fulcrumMeshes.updateScale(scale)
          }
          
          lastDist = dist;
        }
      }

      if (this.#_camera.projection === "ortho") {
        const size = this.#_camera.ortho.scale / 17;
        const scale = [size, size, size]

        if(this.#_fulcrumMeshes != null) {
          this.#_fulcrumMeshes.updateScale(scale)
        }

        lastDist = dist;
      }
    })
  }

  static destroyFulcrum() {
    this.#_fulcrumMeshes?.destroy()
    this.#_fulcrumMeshes = null
  }

  static destroy() {
    this.#_fulcrumMeshes = null
  }

  static #_deactivateRuntimeScaling() {
    XeokitMediator.viewer.scene.off(this.#_onSceneTick);
  }

  static #_createScale(worldPos) {
    let tempVec3a = math.vec3()
    math.subVec3(this.#_camera.eye, worldPos, tempVec3a)
    const dist = Math.abs(math.lenVec3(tempVec3a))
    const worldSize = (Math.tan(this.#_camera.perspective.fov * math.DEGTORAD)) * dist
    const size = 0.07 * worldSize
    const scale = [size, size, size]
    return scale
  }
}
