import { defineStore } from 'pinia'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { projectService } from '@/_services'
import { api } from '@/api'
import { debounce } from 'lodash'

import vuexStore from '@/store'

let debouncedSaveHidden = debounce((projectUuid, types) => {
  api.viewerTools.saveHiddenIfcTypes(projectUuid, types)
}, 500)

function flatlist (tree) {
  let array = []
  tree.forEach(node => {
    let { name } = node
    array.push(name)
    
    if (node.children?.length) {
      array = array.concat(flatlist(node.children))
    }
  })
  return array
}

export const useViewerIfcTypesStore = defineStore('viewer.ifcTypes', {
  state: () => {
    return {
      all: [],
      hidden: [],
    }
  },

  getters: {
    selected: (state) => state.all.filter(type => !state.hidden.includes(type)),
    isAllVisible (state) {
      return state.all.length == this.selected.length
    },
  },

  actions: {
    async fetchModelBasedIfcTypes() {
      let projectUuid = vuexStore.getters['project/projectUuid']
      let hashProject = vuexStore.getters['project/hashProject']

      let data = await projectService.loadParamForCondition(projectUuid, 'CLASS', '', hashProject)
      let items = flatlist(data).sort()
      this.all = items

      return items
    },

    presetHidden (types) {
      this.hidden = Array.from(types)
    },

    selectAll () {
      this.setHidden([])
    },
    
    deselectAll() {
      this.setHidden(Array.from(this.all))
    },
    
    updateVisible (list) {
      let hidden = this.all.filter(type => !list.includes(type))
      this.setHidden(hidden)
    },
    
    setHidden(types) {
      this.hidden = Array.from(types)
      this.changeVisibility()
      this.saveHidden()
    },

    saveHidden() {
      let projectUuid = vuexStore.getters['project/projectUuid']
      debouncedSaveHidden(projectUuid, this.hidden)
    },

    changeVisibility () {
      const scene = XeokitMediator.viewer.scene
      const objectIds = scene.objectIds
      const entities = scene.objects
      const numEntities = objectIds.length

      let shouldHiddenList = []

      for (const type of this.all) {
        if (this.selected.includes(type) == false) {
          const list = XeokitMediator.viewer.metaScene.getObjectIDsByType(type)
          shouldHiddenList.push(...list)
        }
      }

      for (let i = 0; i < numEntities; i++) {
        const objectId = objectIds[i];
        const entity = entities[objectId];

        if (entity) {
          let hideByIfcType = shouldHiddenList.includes(objectId)
          let shouldHidden = hideByIfcType

          entity.visible = shouldHidden ? false : true
        }
      }
    },
  }
})