import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=0646c925&scoped=true&lang=pug&"
import script from "./PageHeader.vue?vue&type=script&lang=js&"
export * from "./PageHeader.vue?vue&type=script&lang=js&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=0646c925&prod&lang=scss&module=true&"
import style1 from "./PageHeader.vue?vue&type=style&index=1&id=0646c925&prod&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0646c925",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VIcon,VProgressCircular,VTextField,VToolbar})
