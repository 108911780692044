import {useProjectionModeStore} from "@/pinia";
import {XeokitMediator} from "@/plugins/xeokit/XeokitMediator";

/*eslint-disable no-dupe-class-members*/
export class ProjectionMode {

  static get #_projectionModeStore() {
    return useProjectionModeStore()
  }

  /**
  * @type {{PERSPECTIVE: string, ORTHO: string}}
  */
  static Modes = { ORTHO: 'ortho', PERSPECTIVE: 'perspective' };

  /**
   * @returns {string} - текущий режим проекции камеры
   */
  static get projectionMode() {
    return this.#_projectionModeStore.projectionMode
  }

   /**
   * Устанавливает режим проекции камеры
   * @param mode - режим проекции 'ortho' или 'perspective'
   * (XeokitMediator.ProjectionMode.Modes.ORTHO или PERSPECTIVE)
   */
  static setProjectionMode(mode = this.Modes.PERSPECTIVE) {
    if (this.projectionMode != mode) {
      XeokitMediator.CameraFlight.cameraFlyToDefaultView({projection: mode})
      //XeokitMediator.CameraFlight.cameraFlyTo({ projection: mode })
      this.#_projectionModeStore.setProjectionMode(mode)
    }
    else {
      XeokitMediator.CameraFlight.cameraFlyToDefaultView()
    }
  }
}
