export default ({ Factory}) => ({

  /**
   * Получить административный список всех проектов 
   */
  searchProjects: (projectFilter) => {
    return Factory.projects.search.post(projectFilter)
  },
  /**
   * Удаление старых и лишних (>5) ревизий
   */
  clearRevisions: () => {
    return Factory.revisions.clear.delete()
  },
  /**
   * Удалить кэш
  */
  clearCache: () => { 
    return Factory.projects.bClass.clearCache.patch()
  },
  /**
   * Получить общую статитику (Проекты и пользователи)
   */
  getFullStatistic: () => {
    return Factory.statistic.full.get()
  },

})  