<template lang="pug">
  app-dialog( v-model="$_show" :header="$t('profile.action.items.notify')" width="350" :confirm-text="$t('button.save')" @confirm="saveNotify" )
    app-section.notification-profile-details.base-scroll-body(style="overflow-x: hidden;")
      v-row(dense)
        v-col( cols = 4 )
          v-switch( v-model="notifyEmail.switcher" label="E-mail" color="accent" dense light )
        v-col
          app-text-field(v-model="notifyEmail.value" label="E-mail" light hideDetails)
          
      v-row.mt-2(no-gutters dense)
        v-col( cols = 4 )
          v-switch( v-model="notifyTelegram.switcher" label="Telegram" color="accent" dense light )
        v-col(align-self="center" cols = 5)
          app-text-field(v-model="notifyTelegram.value" label="Telegram"  light hideDetails)
        v-col.ml-2.mb-2(align-self="end")
          a(href="https://t.me/BimitBot" target="_blank" style="font-size:11px") Получить id

      v-row
        v-col.py-0
          v-switch( v-model="notifyNight.switcher" :label="$t('profile.action.items.notifyPanel.doNotDisturb', {time: '21:00 - 7:00'})" color="accent" dense light )
      
      v-row
        v-col.py-0
          v-switch( v-model="notifyWeekend.switcher" :label="$t('profile.action.items.notifyPanel.doNotDisturbWeekend')" color="accent" dense light )

      v-row
        v-col
          app-select( v-model="interval" :items="intervalList" :item-text="option => $t(option.title)" :label="$t('profile.action.items.notifyPanel.notificationPeriod')" )
          
        v-col
          app-select( v-model="tZone" :items="timeZoneList" item-text="title" :label="$t('profile.action.items.notifyPanel.notificationTimezone')" )

      v-row
        v-col.py-0
          v-switch( v-model="notifyRevision.switcher" :label="$t('module.notification.revision')" color="accent" dense light )
      
      v-row
        v-col.py-0
          v-switch( v-model="notifyNewTask.switcher" :label="$t('profile.action.items.notifyPanel.newTasks')" color="accent" dense light )

      v-row
        v-col.py-0
          v-switch( v-model="notifyEditTask.switcher" :label="$t('profile.action.items.notifyPanel.taskChanges')" color="accent" dense light )

      v-row
        v-col.py-0
          v-switch( v-model="notifyCollision.switcher" :label="$t('profile.action.items.notifyPanel.collisions')" @click="notifyCollisionOnlyMyModels.switcher = notifyCollision.switcher" color="accent" dense light )

      v-row
        v-col.py-0
          v-switch( v-model="notifyCollisionOnlyMyModels.switcher" :label="$t('profile.action.items.notifyPanel.collisionsOnlyMyModels')" color="accent" dense light :disabled="!notifyCollision.switcher")
      
      v-row
        v-col.py-0.pb-4
          v-switch( v-model="notifyPlugin.switcher" :label="$t('profile.action.items.notifyPanel.plugins')" color="accent" dense light )
      //- Пока не актуально
      //- v-row
        v-col.py-0.pb-4
          v-switch( v-model="notifyDrawing.switcher" :label="$t('module.drawing.drawings')" color="accent" dense light )
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { mapActions, mapGetters, mapState } from 'vuex'
import { type } from '@/store/authUser.module'
export default {
  name: "access-notify-profile",

  mixins: [DialogToggable],

  data(){
    return{
      interval: null,
      tZone: null,
    }
  },

  computed: {
    ...mapState('authUser', ['user']),
    ...mapGetters('authUser', ['notifyEmail', 'notifyTelegram', 'timeZoneList', 'intervalList', 'notifyInterval', 'timeZone', 'notifyRevision']),
    ...mapGetters('authUser', ['notifyNewTask', 'notifyEditTask', 'notifyCollision', 'notifyWeekend', 'notifyNight', 'notifyDrawing', 'notifyPlugin']),
    ...mapGetters('authUser', ['notifyCollisionOnlyMyModels']),
  },

  mounted(){
    this.interval = this.notifyInterval
    this.tZone = this.timeZone
  },

  watch:{
    notifyInterval(){
      this.interval = this.notifyInterval
    },
    timeZone(){
      this.tZone = this.timeZone
    }
  },

  methods: {
    ...mapActions('authUser', ['setProfileSettingEmail', 'setProfileSettingTelegram', 'setProfileSettingTimeZone', 'setProfileSettingInterval']),
    ...mapActions('authUser', ['setProfileSettingRevision', 'setProfileSettingNewTask', 'setProfileSettingEditTask', 'setProfileSettingCollision']),
    ...mapActions('authUser', ['setProfileSettingNight', 'setProfileSettingWeekend', 'setProfileSettingDrawing', 'setProfileSettingPlugins']),
    ...mapActions('authUser', ['setProfileSettingCollisionOnlyMyModels']),

    ...mapActions('authUser', ['setProfileSettingList']),

    saveNotify () {
      const makeSetting = (type, value, switcher) => ({
        type: {
          value: type
        },
        value,
        switcher
      })
      
      const settingList = [
        makeSetting(type.NOTIFY_EMAIL, this.notifyEmail.value, this.notifyEmail.switcher),
        makeSetting(type.NOTIFY_TELEGRAM, this.notifyTelegram.value, this.notifyTelegram.switcher),

        makeSetting(type.NOTIFY_NIGHT, '', this.notifyNight.switcher),
        makeSetting(type.NOTIFY_WEEKEND, '', this.notifyWeekend.switcher),
        
        makeSetting(type.TIME_ZONE, this.tZone.value, false),
        makeSetting(type.NOTIFY_INTERVAL, this.interval.value, false),
        makeSetting(type.NOTIFY_REVISION, '', this.notifyRevision.switcher),
        makeSetting(type.NOTIFY_NEW_TASK, '', this.notifyNewTask.switcher),
        makeSetting(type.NOTIFY_EDIT_TASK, '', this.notifyEditTask.switcher),
        makeSetting(type.NOTIFY_COLLISION, '', this.notifyCollision.switcher),
        makeSetting(type.NOTIFY_COLLISION_ONLY_MY_MODELS, '', this.notifyCollisionOnlyMyModels.switcher),
        makeSetting(type.NOTIFY_DRAWING, '', this.notifyDrawing.switcher),
        makeSetting(type.NOTIFY_PLUGIN_ACTION, '', this.notifyPlugin.switcher),
      ]

      this.setProfileSettingList(settingList)

      // this.setProfileSettingEmail(this.notifyEmail)
      // this.setProfileSettingTelegram(this.notifyTelegram)

      // this.setProfileSettingNight(this.notifyNight.switcher)
      // this.setProfileSettingWeekend(this.notifyWeekend.switcher)
      
      // this.setProfileSettingTimeZone(this.tZone.value)
      // this.setProfileSettingInterval(this.interval.value)
      // this.setProfileSettingRevision(this.notifyRevision.switcher)
      // this.setProfileSettingNewTask(this.notifyNewTask.switcher)
      // this.setProfileSettingEditTask(this.notifyEditTask.switcher)
      // this.setProfileSettingCollision(this.notifyCollision.switcher)
      // this.setProfileSettingCollisionOnlyMyModels(this.notifyCollisionOnlyMyModels.switcher)
      // this.setProfileSettingDrawing(this.notifyDrawing.switcher)
      // this.setProfileSettingPlugins(this.notifyPlugin.switcher)
    }
  },

}
</script>

<style>
.timezone-select.theme--dark.v-icon{
  color: black;
}

.notification-profile-details{
  padding-left: 8px;
  max-height: 500px;
}
</style>