import { projectService } from '@/_services'
import { api } from '@/api'
import { NotificationService } from '@/assets/model/notifications/NotificationService'
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"
import { createFile } from '@/utils'
import router from '../router'
// import _ from 'lodash'

import { ProjectShortcut } from '@models/Project'
import { AlertService } from '@/assets/app/AlertService'
import { useViewerIfcTypesStore, useViewerColorsStore, useLightsStore } from '@/pinia'

let flatlist = function (tree, level = 0) {
  let array = []
  tree.forEach(element => {
    element.level = level
    array.push(element, ...flatlist(element.model, level + 1))
    if(element.pointCloud) XeokitMediator.lasLoader.fire('counterCloudModel', element.uuid)
  })
  return array
}


// let flatlistForFloors = function (tree, modelUuid) {
//   tree.forEach(model => {
//     if(model.uuid == modelUuid) return model
//     else return flatlistForFloors(model.model, modelUuid)
//   })
// }

// let replaceInTree = function (tree, unit) {
//   tree.map(el => {
//     replaceInTree(el.model, unit)
//     return (el.uuid === unit.uuid) ? unit : el
//   })
// }

function ModelMapper(model, stack = []) {
  let ownStack = [...stack, model.uuid]

  if (model.title.indexOf('model.name') != -1) 
    model.title = NotificationService.NotificationReplace(model.title)

  model.stack = ownStack
  model.model?.map(i => ModelMapper(i, ownStack))
  return model
} 

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

export const taskBimAnnotationsVisibleMode = {
  ALL: { value: 0, title: "ALL", name: "ALL" },
  NONE: { value: 1, title: "NONE", name: "NONE" },
  FIRST_ONLY: { value: 2, title: "FIRST_ONLY", name: "FIRST_ONLY" },
}

// export const taskBimAnnotationsVisibleMode = {
//   ALL: 'ALL',
//   NONE: 'NONE',
//   FIRST_ONLY: 'FIRST_ONLY',
// }

const getDefaultState = () => {
  return {
    allOpenNames: new Set(),

    project: {
      uuid: '',
      model: [],
      status: status.INITIAL,
      offsetX:0,
      offsetY:0,
      offsetZ:0,
      myRules: []
    },

    // RevInfo in Dashboard
    projectForRevInfo: null,
    modelsForRevInfo: [],
    buildings: [],
    floor: [],
    // RevInfo in Dashboard

    patchRevList: [],
    patchExcludeEl: [],

    dict: {
      stage: [],
      quality: [],
      accessible: []
    },

    colleagues: [],

    selectedModel: null,
    selectedRevision: null,
    compareRevisions: null,
    compareRevisionsLoading: false,
    revisions: null,
    selectedModelRevisionPassport: null,

    projectSettings:{
      workModel: null,
      groupAxis: null,
      projectClassificator: null,
      projectWorm: null,
      openedRoles: [],
      structureSetting: [],
      taskColumns: [],
      taskSections: [],
      lightSettings: [],
    },

    selectedElementFilter: null,

    topUnit: 'DEFAULT', // task
    activeGlobalTab: 'empty', // collision
    requestsPanelStatus: false,
    attributeChecking: false,
    pickedTask: null,
    globalTabFromMail: null,
    activeIotAdmin: false,
    modelsList: [],
    copyProcess: null,

    axisEnum: {
      list: [],
      status: status.INITIAL
    },

    myAttr: {
      list: [],
      status: status.INITIAL
    },

    conditionOperator: {
      list: [],
      status: status.INITIAL
    },

    logicOperator: {
      list: [],
      status: status.INITIAL
    },

    calcOperator: {
      list: [],
      status: status.INITIAL
    },

    projectAxis: {
      project: null,
      list: [],
      status: status.INITIAL
    },

    hashProject: null,

    sectionCubeSelectedFloor: null,

    suspectElements: null, // Элементы, находящиеся за рамками aabb моделей
    taskBimAnnotationsVisibleMode: taskBimAnnotationsVisibleMode.ALL,

    atModelPositioning: false, // true, если динамически позиционируется модель

    maintenanceTaskSettings: {
      schedulerStartDate: null,
      numDuty: 4,
    }
  }
}

const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    camera : (state, getters, rootState) => () => {
      let sectionPlanesList = []

      rootState.viewerSectionPlane.sectionList?.forEach(item => {
        let section = XeokitMediator.viewer?.scene.sectionPlanes[item.id]
        let obj = {
          sectionPlain: {
            pos: section.pos.join(","),
            dir: section.dir.join(","),
            active: section.active
          },
          sectionListItem: item
        }
        sectionPlanesList.push(obj)
      })

      let measurementsList = []
      XeokitMediator.DistanceMeasurement.distanceMeasurements.forEach(measurement => {
        measurementsList.push({
          origin: {
            worldPos: measurement.originWorldPos.join(","),
          },
          target: {
            worldPos: measurement.targetWorldPos.join(","),
          }
        })
      });

      return {
        camera: {
          eye: XeokitMediator.viewer?.camera.eye.join(","),
          look: XeokitMediator.viewer?.camera.look.join(","),
          up: XeokitMediator.viewer?.camera.up.join(",")
        },
        sectionPlanes: sectionPlanesList,
        selectedElements: XeokitMediator.ElementsSelection.selectedElements,
        activeHideSelectedObjects: XeokitMediator.ElementsSelection.isHideSelectedElements,
        activeShowOnlySelectedObjects: XeokitMediator.ElementsSelection.isShowOnlySelectedElements,
        measurements: measurementsList
      }

    },

    membersOfSelectedModel: ({ project, selectedModel }) => {
      let stack = [...selectedModel.stack]
      let list = project.membership || []
      list = list.map(oneMember => {
        let roles = []
        let orgRoles = []
        oneMember.memberReference.forEach(oneRef => {
          if (stack.includes(oneRef.model?.uuid)) {
            let mappedRoles = oneRef.roles.map(({ title, uuid, rolefunction}) => {
              let isControlModel = rolefunction.find(({functionType}) => functionType?.name === "MODEL_LOAD_MODEL") ? true : false
              // let controlFn = rolefunction.filter(({roleUuids}) => roleUuids)
              let controlFn = rolefunction
              return { title, uuid, model: oneRef.model, isControlModel, controlFn }
            })
            let mappedOrgRoles = oneRef.orgRoles.map(({ title, uuid, rolefunction}) => {
              let isControlModel = rolefunction.find(({functionType}) => functionType?.name === "MODEL_LOAD_MODEL") ? true : false
              // let controlFn = rolefunction.filter(({ roleUuids }) => roleUuids)
              let controlFn = rolefunction
              return { title, uuid, model: oneRef.model, isControlModel, controlFn }
            })
            roles.push(...mappedRoles)
            orgRoles.push(...mappedOrgRoles)
          }
        })

        // roles = _.uniqBy(roles, 'uuid')
        return { uuid: oneMember.uuid, profile: { ...oneMember.profile }, orgGroup: { ...oneMember.orgGroup }, roles, orgRoles }
      })
      .filter(obj => obj.roles.length || obj.orgRoles.length)
      return list
    },

    meOfSelectedModel(state, getters, rootState, rootGetters) {
      if (rootGetters['projectPermissions/meOwner']) return true
      return getters.membersOfSelectedModel?.find(member => member.profile.uuid == rootGetters['authUser/myUUID'] || member?.orgGroup?.employees?.find(empl => empl.profile.uuid == rootGetters['authUser/myUUID']))
    },

    iCanManageRolesOnModelAdd: (state, getters) => () =>  {
      return getters.iCanManageRolesOnModel('TEAM_ADD')
    },

    iCanManageRolesOnModelEdit: (state, getters) => () =>  {
      return getters.iCanManageRolesOnModel('TEAM_EDIT')
    },

    iCanManageRolesOnModelDelete: (state, getters) => () =>  {
      return getters.iCanManageRolesOnModel('TEAM_DELETE')
    },

    iCanManageRolesOnModel: (state, getters, rootGetters) => (fnType) =>  {
      let roleUuids = [], manageRoles = []
      let myUUID = rootGetters.authUser.user.uuid
      let refs = getters.membersOfSelectedModel.filter(member => member.profile.uuid === myUUID || member?.orgGroup?.employees?.find(empl => empl.profile.uuid === myUUID))
      
      refs.forEach(ref => manageRoles.push(...ref.roles, ...ref.orgRoles))
      
      manageRoles.forEach(role => {
        let rUuids = role.controlFn.find(item => item?.functionType?.name === fnType)?.roleUuids || []
        if (rUuids) roleUuids.push(...rUuids)
      })

      return roleUuids;
    },

    axisEnum: ({ axisEnum }) => axisEnum.list,
    isAxisEnumLoaded: ({ axisEnum }) => axisEnum.status == status.LOADED,

    myAttr: ({ myAttr }) => myAttr.list,
    ismyAttrLoaded: ({ myAttr }) => myAttr.status == status.LOADED,

    conditionOperator: ({ conditionOperator }) => conditionOperator.list,
    isConditionOperatorLoaded: ({ conditionOperator }) => conditionOperator.status == status.LOADED,

    calcOperator: ({ calcOperator }) => calcOperator.list,
    isCalcOperatorLoaded: ({ calcOperator }) => calcOperator.status == status.LOADED,

    logicOperator: ({ logicOperator }) => logicOperator.list,
    isLogicOperatorLoaded: ({ logicOperator }) => logicOperator.status == status.LOADED,

    projectAxis: ({ projectAxis }) => projectAxis.list,
    // deprecated
    // TODO: проверить и удалить, если не используется
    isProjectAxisLoaded: ({ projectAxis }) => projectAxis.status == status.LOADED,

    projectUuid: ({ project }) => project.uuid,
    projectModel: ({ project }) => project?.model?.map(i => ModelMapper(i, [], false)) || [],
    projectOrgId: ({ project }) => project?.organization?.uuid || null,

    // deprecated
    // TODO: проверить и удалить, если не используется
    isProjectLoaded: ({ project }) => project.status == status.LOADED,
    
    revisions: ({ revisions }) => revisions?.sort((a, b) => b.version - a.version) || [],
    revisionsLoading: ({ revisions }) => revisions === null,
    //recalculatedRevision: ({ project, selectedRevision }) => findRevision(selectedRevision.uuid, project), 

    flatlist: ({ project }) => flatlist(project.model),
    projectOffset: ({project}) => {return {"x":project.offsetX, "y":project.offsetY,"z":project.offsetZ}},

    membership: ({ project }) => {
      return project && project.membership ? project.membership : []
    },
    allActiveTeammates: ({ project }) => {
      return project && project.membership ? project.membership.filter(teammate => teammate.profile && !teammate.profile.archive) : []
    },

    // FIXME: Временное решение - надо заменить везде object Project на сущность Project/ProjectShortcut
    projectShortcut: (state) => {
      return new ProjectShortcut(state.project)
    },

    floorModel: ({ project }) =>  (modelUuid) => {
      //TODO: требуется ускорить
      let arr = flatlist(project.model, modelUuid)
      return arr.find(element => element.uuid == modelUuid)
    },

    hashProject: (state) => {
      return state.hashProject
    },

    openedRoles: (state) =>{
      return state.projectSettings.openedRoles
    },

    lightSettings: (state) =>{
      return state.projectSettings.lightSettings ?? []
    },

    activeGlobalTab: ({activeGlobalTab}) => activeGlobalTab,

    topUnit: ({topUnit}) => topUnit,

    requestsPanelStatus: ({ requestsPanelStatus }) => requestsPanelStatus,

    pickedTask: ({pickedTask}) => pickedTask,
    
    globalTabFromMail: ({globalTabFromMail}) => globalTabFromMail,

    copyProcess: ({copyProcess}) => copyProcess,

    projectSettings: (state) => {
      return state.projectSettings
    },

    taskBimAnnotationsVisibleMode: (state) => state.taskBimAnnotationsVisibleMode,

    atModelPositioning: (state) => state.atModelPositioning,
  },

  mutations: {

    resetState (state) { 
      const dict = state.dict
      const calcOperator = state.calcOperator
      const axisEnum = state.axisEnum
      const conditionOperator = state.conditionOperator
      const logicOperator = state.logicOperator
      Object.assign(state, getDefaultState())
      state.dict = dict
      state.calcOperator = calcOperator
      state.axisEnum = axisEnum
      state.conditionOperator = conditionOperator
      state.logicOperator = logicOperator
    },

    toggleIotAdmin: (state) => { state.activeIotAdmin = !state.activeIotAdmin },

    clearOpenNames: (state) => {
      state.allOpenNames = new Set()
    },

    setOpenNames: (state, arr) => {
      arr.open.forEach(el => {state.allOpenNames.add(el)})
      arr.close.forEach(el => {state.allOpenNames.delete(el)})
    },

    setActiveIotAdmin: (state, payload) => {
      state.activeIotAdmin = payload
    },
    setActiveGlobalTab: (state, payload) => {
      state.activeGlobalTab = payload
    },
    setCopyProcess: (state, payload) => {
      state.copyProcess = payload
    },
    setAttributeChecking: (state, payload) =>{
      state.attributeChecking = payload
    },
    setSendingTopUnit: (state, payload) => {
      state.sendingTopUnit = payload
    },
    setPickedTask: (state, payload) => {
      state.pickedTask = payload
    },
    setGlobalTabFromMail: (state, payload) => {
      state.globalTabFromMail = payload
    },
    setAxisEnum: ({ axisEnum }, payload) => {
      axisEnum.list = payload
    },
    setAxisEnumStatus: ({ axisEnum }, status) => {
      axisEnum.status = status
    },

    setPatchRevList: (state, payload) => {
      state.patchRevList = payload
    },

    setPatchExcludeEl: (state, payload) => {
      state.patchExcludeEl = payload
    },

    setMyAttr: ({ myAttr }, payload) => {//
      myAttr.list = payload
    },
    setMyAttrStatus: ({ myAttr }, status) => {
      myAttr.status = status
    },
    addMyAttr: ({ myAttr }, attr) => {
      let ex = myAttr.list.find(item => item.uuid === attr.uuid)
      if (ex === undefined) myAttr.list.push(attr)
    },


    setConditionOperator: ({ conditionOperator }, payload) => {
      conditionOperator.list = payload
    },
    setConditionOperatorStatus: ({ conditionOperator }, status) => {
      conditionOperator.status = status
    },

    setCalcOperator: ({ calcOperator }, payload) => {
      calcOperator.list = payload
    },
    setCalcOperatorStatus: ({ calcOperator }, status) => {
      calcOperator.status = status
    },

    setLogicOperator: ({ logicOperator }, payload) => {
      logicOperator.list = payload
    },
    setLogicOperatorStatus: ({ logicOperator }, status) => {
      logicOperator.status = status
    },

    setProjectAxis: ({ projectAxis }, payload) => {
      projectAxis.list = payload.data
      projectAxis.project = payload.project
    },
    setProjectAxisStatus: ({ projectAxis }, status) => {
      projectAxis.status = status
    },
    saveProjectAxis: ({ projectAxis }, axis) => {
      let exist = false
      if(projectAxis.list.length > 0) { 
        let curr = projectAxis.list.find(item => item.uuid === axis.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, axis)
        }
      } 
      if(!exist) projectAxis.list.push(axis)
    },

    setProject: (state, project) => {
      state.project = project
    },

    setProjectStatus: ({ project }, status) => {
      project.status = status
    },

    setProjectUUID: ({ project }, uuid) => {
      project.uuid = uuid
    },

    setColleagues: (state, list) => {
      state.colleagues = list || []
    },

    setDictForStage: (state, dict) => {
      state.dict.stage = dict || []
    },
    setDictForQuality: (state, dict) => {
      state.dict.quality = dict || []
    },
    setDictForAccessible: (state, dict) => {
      state.dict.accessible = dict || []
    },
    
    setSelectedModel: (state, model) => {
      state.selectedModel = model
      state.revisions = null

      if (!model) {
        state.selectedRevision = null
        state.selectedModelRevisionPassport = null
      } 
      else { 
        state.selectedRevision = model?.revision
        state.selectedModelRevisionPassport = model?.revision?.modelRevisionPassport || null
        // let m = state.projectSettings.workModel.find(el => el.modelUuid === model.uuid) || {}
        // state.selectedRevision = model.revision.find(r => r.uuid === m.revisionUuid) || _.head(model.revision)
      }
    },

    setSelectedRevision: (state, rev) => {
      state.selectedRevision = rev
    },

    setSelectedElementFilter: (state, filter) => {
      state.selectedElementFilter = filter
    },

    setTopUnit: (state, unit) => { state.topUnit = unit },

    setProjectSettings: (state, projectSettings) => {
      state.projectSettings = projectSettings

      let lightSettings = projectSettings.lightSettings || []
      useLightsStore().setLightSettings(lightSettings)

      // Превентивная настройка показа/скрытия моделей
      let hiddenTypes = projectSettings.hiddenIfcType || []
      useViewerIfcTypesStore().presetHidden(hiddenTypes)

      // Превентивная настройка раскраски элементов
      let activeColorCodings = projectSettings.colorCodings || []
      useViewerColorsStore().presetActiveColorCodings(activeColorCodings)

      // TODO Внимательно отнестись (кажется лишним)
      XeokitMediator.loadWorkModel()
      XeokitMediator.BimLights.loadLights()
    },

    setProjectSettingsShort: (state, projectSettings) => {
      state.projectSettings = projectSettings
    },

    setProjectSettingsProjectClassificator: (state, value) => {
      state.projectSettings.projectClassificator = value
    },

    setProjectSettingsProjectWorm: (state, value) => {
      state.projectSettings.projectWorm = value
    },

    setProjectSettingsOpenedRoles: (state,value) => {
      state.projectSettings.openedRoles = value
    },

    setProjectSettingsTaskSections: (state, value) => {
      state.projectSettings.taskSections = value
    },

    setProjectSettingsTaskColumns: (state, value) => {
      state.projectSettings.taskColumns = value
    },

    setProjectSettingsLightSettings: (state, value) => {
      state.projectSettings.lightSettings = value
    },

    setRevisions: (state, revs) => { state.revisions = revs || [] },

    setProjectTitle: (state, title) => { state.project.title = title },

    setProjectOrg: (state, org) => { state.project.org = org },
    setProjectOrgObj: (state, org) => { state.project.organization = org },

    setProjectCloudPath: (state, path) => { state.project.cloudRoot = path },

    setProjectDescription: (state, description) => { state.project.description = description },

    setProjectColor: (state, color) => { state.project.color = color },

    changeProjectPreview: (state, preview) => { state.project.preview = preview },

    setModelsList: (state, list) => { state.modelsList = list },

    setHashProject: (state, hash) => { state.hashProject = hash },

    setCompareRevisions: (state, compareRevisions) => { 
      state.compareRevisions = compareRevisions 
    },
    setCompareRevisionsLoading: (state, loading) => { 
      state.compareRevisionsLoading = loading 
    },

    setSectionCubeSelectedFloor: (state, floorUuid) => {
      state.sectionCubeSelectedFloor = floorUuid
    },


    setProjectForRevInfo: (state, project) => { 
      state.projectForRevInfo = project 
      state.modelsForRevInfo = flatlist(project.model.map(i => ModelMapper(i, [], false)))
    },
    setBuildings: (state, buildings) => { state.buildings = buildings },
    setFloor: (state, floors) => { state.floor = floors },

    setTaskBimAnnotationsVisibleMode(state, mode) {
      state.taskBimAnnotationsVisibleMode = mode
    },

    setAtModelPositioning(state, val) {
      state.atModelPositioning = val
    },

    setMembership(state, membership) {
      state.project.membership = membership
    },
  },

  actions: {
    initUUID: ({ commit }, projectUuid) => {
      commit('setProjectUUID', projectUuid)
    },
    init: ({ dispatch }, projectUuid) => {
      dispatch('loadProject', projectUuid)
      dispatch('loadStageDict')
      dispatch('loadQualityDict')
      dispatch('loadAccessibleDict')
      dispatch('loadCalcOperator')
    },

    setProjectOrg({ commit, dispatch }, orgUuid) {
      commit('setProjectOrg', orgUuid)
      dispatch("loadOrgRolesAndGroups", orgUuid)
    },

    loadOrgRolesAndGroups ({ dispatch }, orgUuid) {
      dispatch("corp/LOAD_ORG_ROLES", orgUuid, { root: true })
      dispatch("corp/LOAD_ORG_GROUPS", orgUuid, { root: true })
    },

    getPanelsStatus({ commit, dispatch, state, rootGetters }) {
      let projectUuid = rootGetters['project/projectUuid']
      let selectedTask = rootGetters['project/pickedTask']
      let openGlobalTabFromMail = rootGetters['project/globalTabFromMail']
      
      api.sidePanels.getStatus(projectUuid).then(data => {
        if( data?.leftPanel?.toLowerCase() == 'drawings' ) {
          commit( 'drawings/setDrawingsIsLoading', true, { root: true } ) 
          setTimeout( () => commit( 'setActiveGlobalTab', data?.leftPanel?.toLowerCase()), 500 )
        } else if ( data?.leftPanel?.toLowerCase() !== 'drawings' ) {
          commit( 'setActiveGlobalTab', data?.leftPanel?.toLowerCase() )
        }

        commit( 'setTopUnit', data?.rightPanel?.toLowerCase())

        if(selectedTask) {
          commit('setTopUnit', 'task') 
        }

        if(openGlobalTabFromMail) {
          commit('setActiveGlobalTab', 'collision')
        }

        state.requestsPanelStatus = true
      }).catch( 
        dispatch( 'setDefaultPanelStatus' )
      )
    },

    setDefaultPanelStatus({ state, commit }) {
      setTimeout( () => {
        if( !state.requestsPanelStatus ) {
          commit( 'setActiveGlobalTab', 'setup' ), 
          commit( 'setTopUnit', 'DEFAULT' ) 
        }
      }, 1000 )
    },

    postPanelsStatus({ rootGetters } ) {
      let projectUuid = rootGetters['project/projectUuid']
      let topUnit = rootGetters['project/topUnit']
      let activeGlobalTab = rootGetters['project/activeGlobalTab']
      
      if(!topUnit) {
        topUnit = 'DEFAULT'
      } 
      
      if(!activeGlobalTab) {
        activeGlobalTab = 'DEFAULT'
      } 

      let panelStatus = {
        leftPanel: activeGlobalTab.toUpperCase(),
        rightPanel: topUnit,
      }
      setTimeout(() =>
        api.sidePanels.postStatus(projectUuid, panelStatus).then(() => {
        }), 
      1000)
    },

    patchCopyTeam( { dispatch, rootGetters, commit }, { currentModelUuid, targetModelUuid, deleteTeamFlag } ) {
      commit('setCopyProcess', true)
      api.members.copyTeamFromModel(currentModelUuid, targetModelUuid, deleteTeamFlag).then(() => {
        let projectUuid = rootGetters['project/projectUuid']
        dispatch('loadProject', projectUuid)
      }).catch(e => {AlertService.error(e.response)})
    },

    async loadProject ({ commit, state, getters, dispatch, rootGetters }, projectUuid) {
      clearCache()

      commit('setProjectStatus', status.LOADING)
      await dispatch('loadPatchRevList')

      return projectService.loadProject(projectUuid).then(data => { 
        let orgUuid = data.organization?.uuid || null
        dispatch("loadOrgRolesAndGroups", orgUuid)
        dispatch("getMaintenanceTaskSettings")

        commit('setProject', data)
        commit('setProjectStatus', status.LOADED)
        commit('setProjectSettings', data.projectSettings || { project: projectUuid, workModel: [] })
        state.taskBimAnnotationsVisibleMode = data.projectSettings.bimAnnotationsVisibleMod || taskBimAnnotationsVisibleMode.ALL

        dispatch('projectPermissions/loadUserRoleTypesByProject', projectUuid, { root: true })

        let myUUID = rootGetters['authUser/myUUID']
        let owner = data.membership.some(member => member.profile && member.profile.uuid === myUUID && member.owner) ? true : false 
        dispatch('projectPermissions/addMeOwner', owner, { root: true })

        if (Object.values(data.projectSettings.workModel).filter(item => item && item.revisionUuid).length == 0) {
          // commit("setActiveGlobalTab", "setup") 
          let model = getters.flatlist.find(m => m.name === "{model.name.AR}")
          if (model) {
            commit('setSelectedModel', model)
          }
        }

        if (state.selectedModel) {
          let model = getters.flatlist.find(m => m.uuid === state.selectedModel.uuid)
          if (model) {
            commit('setSelectedModel', model)
          }
        }
        dispatch('findModelsNodes')
        
        commit('setCopyProcess', false)
      }).catch(error => {
        if(error.response?.data.error ==  "access_denied"){
          router.push('/dashboard')
        }
      })
    },

    loadProjectForRevInfo ( { commit }, projectUuid ) {
      return projectService.loadProject(projectUuid).then(data => {
        commit('setProjectForRevInfo', data)
      })
    },
    getBuildingsForRevInfo({ commit }, obj) {
      return projectService.getBuildingForRevision(obj.projectId, obj.revUuids).then(data => {
        commit('setBuildings', data)
      })
    },
    getFloorForRevInfo({ commit }, obj) {
      return projectService.getFloorForRevision(obj.projectId, obj.revUuids).then(data => {
        commit('setFloor', data)
      })
    },

    updateProject( { commit, state, getters, dispatch }, project ) {
      commit('setProject', project)
      // commit('setProjectStatus', status.LOADED)
      commit('setProjectSettings', project.projectSettings || { project: project.uuid, workModel: [] })

      if (Object(project.projectSettings.workModel).values().filter((value) => value && value.revisionUuid).length == 0) {
        // commit("setActiveGlobalTab", "setup") 
        let model = getters.flatlist.find(m => m.name === "{model.name.AR}")
        if (model) {
          commit('setSelectedModel', model)
          commit('setSelectedRevision', model.revision)
        }
      }

      if (state.selectedModel) {
        let model = getters.flatlist.find(m => m.uuid === state.selectedModel.uuid)
        if (model) {
          commit('setSelectedModel', model)
          commit('setSelectedRevision', model.revision)
        }
      }
      dispatch('findModelsNodes')
    },

    loadProjectCloud ({ commit, state, getters }, {uuid, hash}) {
      commit('setProjectStatus', status.LOADING)
      commit('setHashProject', hash)
      projectService.loadProjectCloud(uuid, hash).then(data => { 
        commit('setProject', data)
        commit('setProjectStatus', status.LOADED)
        commit('setProjectSettings', data.projectSettings || { project: uuid, workModel: [] })

        if (state.selectedModel) {
          let model = getters.flatlist.find(m => m.uuid === state.selectedModel.uuid)
          if (model) {
            commit('setSelectedModel', model)
          }
        }
      })
    },

    loadPatchRevList({ getters, commit }) {
      return api.projects.getPatches(getters.projectUuid).then(data => {
        let patchList = data.patches.filter(patch => patch.file != "" && patch.state.value == 1)

        commit('setPatchRevList', patchList)
        commit('setPatchExcludeEl', patchList.map(patch => patch.elements).flat())
      })
    },
    
    loadStageDict ({state, commit }) {
      if (state.dict.stage.length === 0){
        projectService.stageDict().then(data => { commit('setDictForStage', data) })
      }
    },
    loadQualityDict ({state, commit }) {
      if (state.dict.quality.length === 0) {
        projectService.qualityDict().then(data => { commit('setDictForQuality', data) })
      }
    },
    loadAccessibleDict({ state, commit }) {
      if(state.dict.accessible.length ===0){
        projectService.accessibleDict().then(data => { commit('setDictForAccessible', data) })
      }
    },

    setStage ({ dispatch, getters }, { modelUuid, item }) {
      projectService.setStage(modelUuid, item).then(() => { dispatch('loadProject', getters.projectUuid) })
    },
    setQualtiy ({ dispatch, getters }, { modelUuid, item }) {
      projectService.setQualtiy(modelUuid, item).then(() => { dispatch('loadProject', getters.projectUuid) })
    },
    setAccessible ({ dispatch, getters }, { modelUuid, item }) {
      projectService.setAccessible(modelUuid, item).then(() => { dispatch('loadProject', getters.projectUuid) })
    },

    setRoot ({ dispatch, getters }, { modelUuid, root }) {
      projectService.setRoot(modelUuid, root).then(() => { dispatch('loadProject', getters.projectUuid) })
    },

    saveModel ({ dispatch, rootGetters }, obj) {
      const projectUuid = rootGetters['project/projectUuid']
      const data = { ...obj.data, projectUuid }
      if (obj.uuid) {
        projectService.updateModel(obj.uuid, data).then(() => {
          dispatch('loadProject', projectUuid)
        })
      }
      else {
        projectService.addModel(data).then(() => {
          dispatch('loadProject', projectUuid)
        })
      }
    },

    deleteModel ({ dispatch, commit, getters }, modelUuid) {
      projectService.deleteModel(modelUuid).then(() => {
        commit("setSelectedModel", null)
        dispatch('loadProject', getters.projectUuid)
      })
    },

    archiveModel ({ dispatch, commit, getters }, modelUuid) {
      projectService.archiveModel(modelUuid).then(() => {
        commit("setSelectedModel", null)
        dispatch('loadProject', getters.projectUuid)
      })
    },

    fromArchiveModel ({ dispatch, commit, getters }, modelUuid) {
      projectService.fromArchiveModel(modelUuid).then(() => {
        commit("setSelectedModel", null)
        dispatch('loadProject', getters.projectUuid)
      })
    },

    changeModelOrder({dispatch, getters}, param){
      projectService.reorderModels(param).then(() => {
        // commit("setSelectedModel", null)
        dispatch('loadProject', getters.projectUuid)
      })
    },

    loadRevisions ({ commit }, modelUuid) {
      projectService.revisionsByModel(modelUuid).then(data => {
        commit('setRevisions', data)
      })
    },
    
    deleteRevision ({state, dispatch, commit, getters}, revisionUuid) {
      projectService.deleteRevision(revisionUuid).then(() => {
        projectService.loadProject(state.project.uuid).then(data => {
          dispatch('axis/deleteRevisionElements', revisionUuid, {root: true})
          data.model.forEach(model => ModelMapper(model, []))
          commit('setProject', data)
          dispatch('updateSettings', state.projectSettings)
          if (state.selectedModel) {
            let model = getters.flatlist.find(m => m.uuid === state.selectedModel.uuid)
            if (model) {
              commit('setSelectedModel', model)
              // commit('setSelectedRevision')
              //dispatch('loadRevisions', model.uuid)
              projectService.revisionsByModel(model.uuid).then(data => {
                commit('setRevisions', data)
              })
            }
          }
        })
      })
    },

    fixRevision ({state}, revisionUuid) {
      projectService.fixRevision(revisionUuid).then(() => {
        state.revisions.forEach(revision => {
          if(revision.uuid == revisionUuid)
            revision.fixed = true
        })
      }).catch(e => {
        AlertService.error(e.response)
      })
    },

    unfixRevision ({state}, revisionUuid) {
      projectService.unfixRevision(revisionUuid).then(() => {
        state.revisions.forEach(revision => {
        if(revision.uuid == revisionUuid)
          revision.fixed = false
        })
      }).catch(e => {
        AlertService.error(e.response)
      })
    },
    
    makeRevisionActive ({ state, getters, commit, dispatch }, { modelUuid, rev }) {
      let newProjectSettings = { ...state.projectSettings }
      let workModel = newProjectSettings.workModel
      let existModelUuid = Object.keys(workModel).find(uuid => uuid === modelUuid)
      if (existModelUuid) {
        let wModel = workModel[existModelUuid]
        wModel.revisionUuid = rev.uuid
      }
      else {
        let wModel = { revisionUuid: rev.uuid, switchon: false, convexHull: false }
        workModel[modelUuid] = wModel
      }

      if (state.selectedModel) {
        if (state.selectedModel.uuid === modelUuid) {
          let pModel = getters.flatlist.find(model => model.uuid === modelUuid)
          pModel.revision = rev
          commit('setSelectedModel', pModel)
        }
      }

      dispatch('updateSettings', newProjectSettings)
    },
    
    findRevision({commit, dispatch, state}, data) {
      data.model.forEach(model =>{
       
        if( model != null && model.uuid == state.selectedModel.uuid){
          commit('setSelectedModel', model)
        } else {
          dispatch('findRevision', model)
        }
    
      }) 
    },

    findModelsNodes ({commit, state, getters}){
      commit('setModelsList', [])
      let nodesList = []
      getters.projectModel.map(model => {
        deepModelSearch(model, nodesList)
      })
      commit('setModelsList', nodesList)
      if(state.projectSettings.structureSetting) {
        state.projectSettings.structureSetting.map(setting => {
          let model = state.modelsList.find(model => model.uuid == setting.modelUuid)
          if(model) {
            model.treeIsVisible = setting.opened
          }
        })
      }
    },

    loadColleagues ({ commit }) {
      api.members.getMyTeam().then(list => {
        commit('setColleagues', list)
      })
    },

    addMember ({ dispatch, getters }, { modelUuid, profileUuid, isGroup, roles }) {
      return api.members.create(modelUuid, profileUuid, isGroup, roles).then(data => {
        dispatch('loadProject', getters.projectUuid)
        return data
      })
    },

    generateLinkInvite ({ state }, roles ) {
      let inviteObj = {
        model: state.selectedModel.uuid,
        roles: roles.roles,
        orgRoles: roles.orgRoles
      }
      return api.members.generateLinkInvite(inviteObj).then(data => data)
    },

    addMemberInvite (state, invite) {
      api.members.inviteModelAndRoles(invite)
    },

    inviteMember ({ dispatch, getters }, members) {
      return api.members.invite(members).then(data => {
        dispatch('loadProject', getters.projectUuid)
        return data
      })
    },

    changeTeammateRoles ({ state, dispatch, getters }, { teammateUuid, roles }) {
      api.members.setRoles(state.selectedModel.uuid, teammateUuid, roles).then(() => {
        dispatch('loadProject', getters.projectUuid)
      })
    },

    // Удаляем конкретную роль у участника проекта
    removeTeammateRole ({ state, commit }, { teammate, modelUuid }) {
      if (!teammate || !teammate.currentRole ) return null

      api.members.delRole(teammate.uuid, teammate.currentRole, modelUuid).then(member => {
        let membership = state.project.membership
        if (member) {
          membership = membership.map(m => {
            if (teammate.uuid === m.uuid) {
              m.memberReference = m.memberReference.map( ref => {
                ref.roles = ref.roles?.filter(role => role.uuid !== teammate.currentRole || ref.model.uuid !== modelUuid) || []
                ref.orgRoles = ref.orgRoles?.filter(orgRole => orgRole.uuid !== teammate.currentRole || ref.model.uuid !== modelUuid) || []
                return ref
              })
            }
            return m
          })
        } 
        else membership = membership.filter(m => m.uuid !== teammate.uuid)

        commit("setMembership", membership)
      })
    },

    // TODO:: Ждем уточнения по удаления участника с модели. 
    removeTeammate ({ dispatch, getters }, {memberUuid, modelUuid}) {
      return api.members.deleteFromModel(memberUuid, modelUuid).then(data => {
        dispatch('loadProject', getters.projectUuid)
        return data
      })
    },

    updateWorkModels ({ dispatch, getters, state }, values) {
      const workModel = state.projectSettings.workModel  
      Object.keys(workModel).forEach(modelUUID => {
        workModel[modelUUID].switchon = values.includes(modelUUID)
      })

      values.forEach(e => {
        let wm = workModel[e]
        if (!wm) {
          let model = getters.flatlist.find(m => m.uuid === e)
          workModel[model.uuid] = { revisionUuid: model.revision.uuid, switchon: true }
        }
      })

      dispatch('updateModelSettings', Object.assign(state.projectSettings, {workModel}))
    },

    updateSettingsProjectClassificator ({ commit }, projectSettings) {
      projectService.updateSettings(projectSettings).then(data => {
        commit('setProjectSettingsProjectClassificator', data.projectClassificator)
      })
    },

    updateSettingsProjectWorm ({ commit }, projectSettings) {
      projectService.updateSettings(projectSettings).then(data => {
        commit('setProjectSettingsProjectWorm', data.projectWorm)
      })
    },

    updateSettingsProjectLights ({ getters, commit }, lightSettings) {
      const { projectUuid } = getters
      if (projectUuid && projectUuid.length > 0) {
        //state.projectSettings.lightSettings = lightSettings
        projectService.updateLightSettings(projectUuid, lightSettings).then(() => {
          commit('setProjectSettingsLightSettings', lightSettings)
        })
      }
    },

    updateTaskColumns({ state, getters }, taskColumns ){
      const { projectUuid } = getters
      projectService.updateTaskColumns(projectUuid, taskColumns).then(() => {
        state.projectSettings.taskColumns = taskColumns
      })
    },

    updateProjectStructure({ state, getters }, structureSetting ) {
      const { projectUuid } = getters
      projectService.updateProjectStructure(projectUuid, structureSetting).then(() => {
        state.projectSettings.structureSetting = structureSetting
      })
    }, 

    updateArchivedProjectStructure({ state, getters }, archivedStructureSetting ) {
      const { projectUuid } = getters
      projectService.updateArchivedProjectStructure(projectUuid, archivedStructureSetting).then(() => {
        state.projectSettings.archivedStructureSetting = archivedStructureSetting
      })
    }, 

    updateMaintenanceTaskSettings({ state, getters }, maintenanceTaskSettings) {
      const { projectUuid } = getters
      projectService.updateMaintenanceTaskSettings(projectUuid, maintenanceTaskSettings).then(() => {
        state.maintenanceTaskSettings = maintenanceTaskSettings
      })
    },

    getMaintenanceTaskSettings({ state, getters }) {
      const { projectUuid } = getters
      projectService.getMaintenanceTaskSettings(projectUuid).then(data => {
        state.maintenanceTaskSettings = data
      })
    },

    updateSettings ({ commit }, projectSettings) {
      return projectService.updateSettings(projectSettings).then(data => {
        clearCache()
        commit('setProjectSettings', data)
      })
    },

    updateModelSettings ({ commit }, projectSettings) {
      commit('setProjectSettings', projectSettings)
      return projectService.updateSettings(projectSettings).then(() => {
        clearCache()
      })
    },

    updateSettingsShort ({ commit }, projectSettings) {
      return projectService.updateSettings(projectSettings).then(data => {
        commit('setProjectSettingsShort', data)
      })
    },
    
    updateWorkSpace ({ getters, commit }, workSpace) {
      const { projectUuid } = getters
      return projectService.updateWorkspace(projectUuid, workSpace).then(data => {
        commit('setProjectSettings', data)
      })
    },
    
//deprecated
    makeModelVisible ({ dispatch, getters }, { modelUuid, on }) {
      const { projectUuid } = getters
      projectService.updateModelSwitch({
        projectUuid, modelUuid, switchon: on
      }).then(() => {
        dispatch('loadProject', projectUuid)
      })
    },

//deprecated
    makeRevisionCurrent ({ dispatch, getters }, { modelUuid, revisionUuid }) {
      const { projectUuid } = getters
      projectService.updateModelRevision({
        projectUuid, modelUuid, revisionUuid
      }).then(() => {
        dispatch('loadProject', projectUuid)
      })
    },

    loadConditionEnum: ({ dispatch }) => {//
      dispatch('loadMyAttr')
      dispatch('loadAxisEnum')
      dispatch('loadConditionOperator')
      dispatch('loadLogicOperator')
    },

    addMyAttr ({ commit }, attr) {
      commit('addMyAttr', attr) 
    },

    loadMyAttr ({ state, commit }) {
      if(state.myAttr.status == status.INITIAL ){
          commit('setMyAttrStatus', status.LOADING)
          projectService.myAttr().then(data => { 
            commit('setMyAttr', data) 
            commit('setMyAttrStatus', status.LOADED)
          })
      }
    },

    loadAxisEnum ({ state, commit }) {
      if (state.axisEnum.list.length === 0){
        if(state.axisEnum.status == status.INITIAL ){
          commit('setAxisEnumStatus', status.LOADING)
          projectService.loadGroupAxis().then(data => { 
            commit('setAxisEnum', data) 
            commit('setAxisEnumStatus', status.LOADED)
          })
        }
      }
    },

    loadConditionOperator ({ state, commit }) {
      if (state.conditionOperator.list.length === 0){
        if(state.conditionOperator.status == status.INITIAL ){
          commit('setConditionOperatorStatus', status.LOADING)
          projectService.loadConditionOperator().then(data => { 
            commit('setConditionOperator', data) 
            commit('setConditionOperatorStatus', status.LOADED)
          })
        }
      }
    },

    loadLogicOperator ({ state, commit }) {//
      if (state.logicOperator.list.length === 0){
        if(state.logicOperator.status == status.INITIAL ){
          commit('setLogicOperatorStatus', status.LOADING)
          projectService.loadLogicOperator().then(data => { 
            commit('setLogicOperator', data) 
            commit('setLogicOperatorStatus', status.LOADED)
          })
        }
      }
    },

    loadCalcOperator ({ state,commit }) {
      if (state.calcOperator.list.length === 0){
        if(state.calcOperator.status == status.INITIAL ){
          commit('setCalcOperatorStatus', status.LOADING)
          projectService.loadCalcOperator().then(data => { 
            commit('setCalcOperator', data) 
            commit('setCalcOperatorStatus', status.LOADED)
          })
        }
      }
    },

    // ! Delete
    
    loadProjectAxis ({ state, commit }) {
      if(state.projectAxis.status == status.INITIAL || state.projectAxis.project!=state.project.uuid ){
          commit('setProjectAxisStatus', status.LOADING)
          projectService.loadProjectAxis(state.project.uuid).then(data => { 
            commit('setProjectAxis', {"data":data, "project":state.project.uuid}) 
            commit('setProjectAxisStatus', status.LOADED)
          })
      }
    },

    // TODO: Удалить этот метод и использовать вместо него axis/saveAxisData
    saveAxis ({ state, commit }, projectAxis) {
      projectAxis.project=state.project.uuid
      return projectService.saveAxis(projectAxis).then(data => {
        commit('saveProjectAxis', data)
        return data
      })
    },

    setProjectCloudPath: ({ commit }, { uuid, path }) => {

      return api.projects.setCloudPath(uuid, path).then(data => {
        commit('setProjectCloudPath', data.cloudRoot)
        return data
      })
    },
    

    setProjectPreview: (context, { uuid, file }) => {
      return api.projects.setPreview(uuid, file).then(data => {
        return data
      })
    },

    makeDemo({dispatch}) {
      return api.projects.makeDemo().then(project => {
        createFile('/img/common/fap_demo.png', 'fap_demo.png', 'image/png')
        .then((file) => {
          dispatch("setProjectPreview",{uuid: project.uuid, file: file})
          
        });
        return project
      }).catch(() => {
        return
      })
    },

  // 
  // for model offset methods
  //
    
    editModelOffset({state}, form) {
      return projectService.editModelOffset(state.selectedModel.uuid, form)
    },

    resetModelOffset({state, commit, dispatch}) {
      return projectService.resetModelOffset(state.selectedModel.uuid).then(data => {
        commit('setProject', data)
        commit('setProjectSettings', data.projectSettings)
        dispatch('findRevision', data)
      })
    },

    lockOffsetModel({state}) {
      return projectService.lockOffsetModel(state.selectedModel.uuid).then(()=> {
        state.selectedModel.lockOffset = true
      })
    },

    unlockOffsetModel({state}) {
      return projectService.unlockOffsetModel(state.selectedModel.uuid).then(() => {
        state.selectedModel.lockOffset = false
      })
    },

    setOpenedRoles({state},roles){
      return projectService.setOpenedRoles(state.project.uuid,roles)
    },

    getCompareRevisions({ commit }, compareRevisions) {
      commit("setCompareRevisions", null)
      commit("setCompareRevisionsLoading", true)

      return api.projects.compareRevisions(compareRevisions.first.uuid, compareRevisions.second.uuid)
        .then(data => {
          compareRevisions.details = data
          commit("setCompareRevisions", compareRevisions)
          return
        })
        .catch(() => {
          commit("setCompareRevisions", compareRevisions)
          return
        })
    },

    loadSuspectElements({ state, getters }, revisionUuids) {
      const { projectUuid } = getters
      return projectService.getSuspectElements(projectUuid, revisionUuids).then(data => {
        state.suspectElements = data 
        return
      })
    },

    updateBimAnnotationsVisibleMode({ state, getters }, bimAnnotationsVisibleMod) {
      state.taskBimAnnotationsVisibleMode = bimAnnotationsVisibleMod
      projectService.updateBimAnnotationsVisibleMode(getters.projectUuid, bimAnnotationsVisibleMod)
    },
  //
  // end offset model methods
  //
  }
}

function clearCache () {
  // TODO: Удалить после проверки на ворке и релизе
  // if (typeof caches !== 'undefined') caches.delete('offline_attributes')

  // caches.keys().then(function(cacheNames) {
  //   return Promise.all(
  //     cacheNames.map(function(cacheName) {
  //       if (cacheName == 'offline_attributes') {
  //         console.log('Deleting out of date cache:', cacheName);
  //         return caches.delete(cacheName);
  //       }
  //     })
  //   );
  // })
}

function deepModelSearch(item, nodesList) {
  if(item.model.length > 0) {
    nodesList.push({
      uuid: item.uuid,
      treeIsVisible: item.treeIsVisible
    })
    item.model.map(child => {
      deepModelSearch(child, nodesList)
    })
  }
}