import { DistanceMeasurement } from '@xeokit/xeokit-sdk'
import {math} from "@xeokit/xeokit-sdk";

var distVec3 = math.vec3()

const lengthWire = (x1, y1, x2, y2) => {
  var a = x1 - x2
  var b = y1 - y2
  return Math.sqrt(a * a + b * b)
}

export class Measurement extends DistanceMeasurement {
  constructor(plugin, cfg = {}) {
    super(plugin, cfg)

    this.isAutoGenerated = cfg.isAutoGenerated
    this.mode = cfg.mode
    this.createDate = cfg.createDate
    
    this._metrics = this.plugin.viewer.scene.metrics;
    this._scale = this._metrics.scale;
    this._units = this._metrics.units;
    this._unitInfo = this._metrics.unitsInfo[this._units];
    this._unitAbbrev = this._unitInfo.abbrev;

    let defaultNumberSettings = {
      units: {
        title: this._unitAbbrev,
        value: this._scale
      },
      decimalPlaces: 2
    }
    this._numberSettings = defaultNumberSettings

    if (cfg.numberSettings) {
      this._numberSettings = cfg.numberSettings
         
      this._scale = this._numberSettings.units.value;
      this._unitAbbrev = this._numberSettings.units.title;
      this._decimalPlaces = this._numberSettings.decimalPlaces;
    }
 
    this._xAxisWire._wire.style["pointer-events"] = "none";
    this._yAxisWire._wire.style["pointer-events"] = "none";
    this._zAxisWire._wire.style["pointer-events"] = "none";
    this._lengthWire._wire.style["pointer-events"] = "none";

    // this._lengthWire.setColor("red");
    // this._lengthLabel.setFillColor("red");
  }

  _update() {
    this._length = Math.abs(math.lenVec3(math.subVec3(this._targetWorld, this._originWorld, distVec3)))
    
    if (!this._visible) {
      return;
    }

    const scene = this.plugin.viewer.scene;

    if (this._wpDirty) {

      this._wp[0] = this._originWorld[0];
      this._wp[1] = this._originWorld[1];
      this._wp[2] = this._originWorld[2];
      this._wp[3] = 1.0;

      this._wp[4] = this._targetWorld[0];
      this._wp[5] = this._originWorld[1];
      this._wp[6] = this._originWorld[2];
      this._wp[7] = 1.0;

      this._wp[8] = this._targetWorld[0];
      this._wp[9] = this._targetWorld[1];
      this._wp[10] = this._originWorld[2];
      this._wp[11] = 1.0;

      this._wp[12] = this._targetWorld[0];
      this._wp[13] = this._targetWorld[1];
      this._wp[14] = this._targetWorld[2];
      this._wp[15] = 1.0;

      this._wpDirty = false;
      this._vpDirty = true;
    }

    if (this._vpDirty) {

      math.transformPositions4(scene.camera.viewMatrix, this._wp, this._vp);

      this._vp[3] = 1.0;
      this._vp[7] = 1.0;
      this._vp[11] = 1.0;
      this._vp[15] = 1.0;

      this._vpDirty = false;
      this._cpDirty = true;
    }

    const near = 0.0;
    const vpz1 = this._originMarker.viewPos[2];
    const vpz2 = this._targetMarker.viewPos[2];

    if (vpz1 > near || vpz2 > near) {

      this._xAxisLabel.setVisible(false);
      this._yAxisLabel.setVisible(false);
      this._zAxisLabel.setVisible(false);
      this._lengthLabel.setVisible(false);

      this._xAxisWire.setVisible(false);
      this._yAxisWire.setVisible(false);
      this._zAxisWire.setVisible(false);
      this._lengthWire.setVisible(false);

      this._originDot.setVisible(false);
      this._targetDot.setVisible(false);

      return;
    }

    if (this._cpDirty) {

      math.transformPositions4(scene.camera.project.matrix, this._vp, this._pp);

      var pp = this._pp;
      var cp = this._cp;

      //var canvas = scene.canvas.canvas;
      //var offsets = canvas.getBoundingClientRect();
      //var top = offsets.top;
      //var left = offsets.left;

      var aabb = scene.canvas.boundary;
      var canvasWidth = aabb[2];
      var canvasHeight = aabb[3];
      var j = 0;

      for (var i = 0, len = pp.length; i < len; i += 4) {
        cp[j] = Math.floor((1 + pp[i + 0] / pp[i + 3]) * canvasWidth / 2);
        cp[j + 1] = Math.floor((1 - pp[i + 1] / pp[i + 3]) * canvasHeight / 2);
        j += 2;
      }

      this._originDot.setPos(cp[0], cp[1]);
      this._targetDot.setPos(cp[6], cp[7]);

      this._lengthWire.setStartAndEnd(cp[0], cp[1], cp[6], cp[7]);

      this._xAxisWire.setStartAndEnd(cp[0], cp[1], cp[2], cp[3]);
      this._yAxisWire.setStartAndEnd(cp[2], cp[3], cp[4], cp[5]);
      this._zAxisWire.setStartAndEnd(cp[4], cp[5], cp[6], cp[7]);

      this._lengthLabel.setPosOnWire(cp[0], cp[1], cp[6], cp[7]);
      this._xAxisLabel.setPosOnWire(cp[0], cp[1], cp[2], cp[3]);
      this._yAxisLabel.setPosOnWire(cp[2], cp[3], cp[4], cp[5]);
      this._zAxisLabel.setPosOnWire(cp[4], cp[5], cp[6], cp[7]);

      this._lengthLabel.setText("~" + (this._length * this._scale).toFixed(this._decimalPlaces) + this._unitAbbrev);

      const xAxisCanvasLength = Math.abs(lengthWire(cp[0], cp[1], cp[2], cp[3]));
      const yAxisCanvasLength = Math.abs(lengthWire(cp[2], cp[3], cp[4], cp[5]));
      const zAxisCanvasLength = Math.abs(lengthWire(cp[4], cp[5], cp[6], cp[7]));

      const labelMinAxisLength = this.plugin.labelMinAxisLength;

      this._xAxisLabelCulled = (xAxisCanvasLength < labelMinAxisLength);
      this._yAxisLabelCulled = (yAxisCanvasLength < labelMinAxisLength);
      this._zAxisLabelCulled = (zAxisCanvasLength < labelMinAxisLength);

      if (!this._xAxisLabelCulled) {
        this._xAxisLabel.setText("~" + Math.abs((this._targetWorld[0] - this._originWorld[0]) * this._scale).toFixed(this._decimalPlaces) + this._unitAbbrev);
        this._xAxisLabel.setVisible(false);
      } else {
        this._xAxisLabel.setVisible(false);
      }

      if (!this._yAxisLabelCulled) {
        this._yAxisLabel.setText("~" + Math.abs((this._targetWorld[1] - this._originWorld[1]) * this._scale).toFixed(this._decimalPlaces) + this._unitAbbrev);
        this._yAxisLabel.setVisible(false);
      } else {
        this._yAxisLabel.setVisible(false);
      }

      if (!this._zAxisLabelCulled) {
        this._zAxisLabel.setText("~" + Math.abs((this._targetWorld[2] - this._originWorld[2]) * this._scale).toFixed(this._decimalPlaces) + this._unitAbbrev);
        this._zAxisLabel.setVisible(false);
      } else {
        this._zAxisLabel.setVisible(false);
      }

      this._originDot.setVisible(this._visible && this._originVisible);
      this._targetDot.setVisible(this._visible && this._targetVisible);
      this._xAxisWire.setVisible(false);
      this._yAxisWire.setVisible(false);
      this._zAxisWire.setVisible(false);

      this._lengthWire.setVisible(true);
      this._lengthLabel.setVisible(true);

      this._cpDirty = false;
    }
  }

  get xLength() {
    this._update();
    return Math.abs((this._targetWorld[0] - this._originWorld[0]) * this._scale);
  }

  get yLength() {
    this._update();
    return Math.abs((this._targetWorld[1] - this._originWorld[1]) * this._scale);
  }

  get zLength() {
    this._update();
    return Math.abs((this._targetWorld[2] - this._originWorld[2]) * this._scale);
  }

  /** Костыль от зеокитовских приколистов, что не проверяю на содержимое объектов
     * @private
     */
  destroy() {
    if (!document.getElementById("measurementsScreen")) {
      this._originDot = {destroy() {}}
      this._targetDot = {destroy() {}}
      this._xAxisWire = {destroy() {}}
      this._yAxisWire = {destroy() {}}
      this._zAxisWire = {destroy() {}}
      this._lengthLabel = {destroy() {}}
      this._xAxisLabel = {destroy() {}}
      this._yAxisLabel = {destroy() {}}
      this._zAxisLabel = {destroy() {}}
      this._lengthWire = {destroy() {}}
    }

    try {
      super.destroy();
    } catch (error) { error; console.log("|| BAD MEASUREMENT DESTROY ||") }
  }
}