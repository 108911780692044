import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { MeasurementTool } from '../measurement/measurementTool'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { Dot, math } from '@xeokit/xeokit-sdk'

/*eslint-disable no-dupe-class-members*/
export class PointToPointMeasurement {
  static #_firstPointWorldPos = null
  static #_firstPointCornerSphere = null

  static #_drawedCornerSphere = null

  static #_onMouseHoverSurface = null
  static #_onMouseHoverOut = null
  static #_onSceneTick = null
  static #_onInputMouseUp = null
  static #_onInputMouseDown = null
  static #_onInputKeyDown = null

  static #_mouseCanvasClickTolerance = 5
  static #_mouseDownCanvasX
  static #_mouseDownCanvasY
  static #_mouseDownLeft = false
  static #_mouseDownRight = false

  static pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedWorldPos = pickResult._worldPos
    const isPointMesh = pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')

    let edges = []
    let nearestCorner = pickedWorldPos

    if (pickResult.isSectionControl) {
      return
    }

    if (!isPointMesh) {
      pickedEntity.meshes.forEach((mesh) => {
        if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
      })
      nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(pickedWorldPos, edges)
    }

    if (!this.#_firstPointWorldPos) {
      this.#_firstPointWorldPos = nearestCorner 
      this.#_firstPointCornerSphere = this.#_drawCornerSphere(nearestCorner)
    } 
    else {
      MeasurementTool.createDistanceMeasurement(this.#_firstPointWorldPos, nearestCorner)
    }
  }

  static #_drawCornerSphere(worldPos) {
    const canvasPos = XeokitMediator.viewer.camera.projectWorldPos(worldPos)
    return new Dot(document.getElementById('measurementsScreen'), { x: canvasPos[0], y: canvasPos[1] })
  }

  static #_destroyCornerSphere() {
    this.#_drawedCornerSphere?.destroy()
    this.#_drawedCornerSphere = null
  }

  static #_destroyFirstPointCornerSphere() {
    this.#_firstPointCornerSphere?.destroy()
    this.#_firstPointCornerSphere = null
  }

  static clearAll() {
    this.#_firstPointWorldPos = null

    this.#_destroyFirstPointCornerSphere()
  }

  static activate() {
    this.#_activateInputListeners()
    this.#_activateHoverSurfaceListener()
  }

  static #_activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#_onInputMouseDown)
    input.off(this.#_onInputMouseUp)

    this.#_onInputMouseDown = input.on('mousedown', (coords) => {
      this.#_mouseDownCanvasX = coords[0]
      this.#_mouseDownCanvasY = coords[1]
      this.#_mouseDownLeft = input.mouseDownLeft
      this.#_mouseDownRight = input.mouseDownRight
    })

    this.#_onInputMouseUp = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#_mouseDownCanvasX + this.#_mouseCanvasClickTolerance ||
        coords[0] < this.#_mouseDownCanvasX - this.#_mouseCanvasClickTolerance ||
        coords[1] > this.#_mouseDownCanvasY + this.#_mouseCanvasClickTolerance ||
        coords[1] < this.#_mouseDownCanvasY - this.#_mouseCanvasClickTolerance
      ) {
        this.#_mouseDownLeft = false
        this.#_mouseDownRight = false
        return
      }

      if (this.#_mouseDownLeft) {
        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
        })

        if (pickResult) {
          this.pick(pickResult)
        }
      }

      this.#_mouseDownLeft = false
      this.#_mouseDownRight = false
    })

    this.#_onInputKeyDown = input.on('keydown', (keyCode) => {
      if (keyCode == input.KEY_N) {
        this.clearAll()
      }
    })
  }

  static #_activateHoverSurfaceListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    const camera = XeokitMediator.viewer.camera
    const scene = XeokitMediator.viewer.scene

    let lastNearestCorner = null
    let nearestCorner = null

    let lastDist = -1
    let tempVec3a = math.vec3()

    this.#_onSceneTick = scene.on('tick', () => {
      if (this.#_firstPointCornerSphere) {
        this.#_destroyFirstPointCornerSphere()
        this.#_firstPointCornerSphere = this.#_drawCornerSphere(this.#_firstPointWorldPos)
      }

      if (this.#_mouseDownLeft || this.#_mouseDownRight) {
        this.#_destroyCornerSphere()
        return
      }
      if (nearestCorner) {
        math.subVec3(camera.eye, nearestCorner, tempVec3a)
        const dist = Math.abs(math.lenVec3(tempVec3a))
        if (lastDist != dist) {
          this.#_destroyCornerSphere()
          this.#_drawedCornerSphere = this.#_drawCornerSphere(nearestCorner)
          lastDist = dist
        }
      }
    })

    this.#_onMouseHoverSurface = cameraControl.on('hoverSurface', (event) => {
      if (this.#_mouseDownLeft || this.#_mouseDownRight) {
        this.#_destroyCornerSphere()
        return
      }

      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
      })

      if (!pickResult) {
        return
      }

      if (pickResult.isSectionControl) {
        return
      }

      if (!pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) {
        let edges = []
        pickResult.entity.meshes.forEach((mesh) => {
          if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
        })
        nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(pickResult.worldPos, edges)

        if (!lastNearestCorner) {
          lastNearestCorner = nearestCorner
        }

        if (geometry.utils.arePointsClose(lastNearestCorner, nearestCorner)) {
          return
        }
        this.#_destroyCornerSphere()
        this.#_drawedCornerSphere = this.#_drawCornerSphere(nearestCorner)
      }
    })

    this.#_onMouseHoverOut = cameraControl.on('hoverOut', () => {
      this.#_destroyCornerSphere()
    })
  }

  static deactivate() {
    const input = XeokitMediator.viewer.scene.input
    const cameraControl = XeokitMediator.viewer.cameraControl
    const scene = XeokitMediator.viewer.scene

    input.off(this.#_onInputMouseDown)
    input.off(this.#_onInputMouseUp)
    input.off(this.#_onInputKeyDown)
    cameraControl.off(this.#_onMouseHoverSurface)
    cameraControl.off(this.#_onMouseHoverOut)
    scene.off(this.#_onSceneTick)

    this.#_destroyCornerSphere()
    
    this.#_firstPointWorldPos = null
    this.#_destroyFirstPointCornerSphere()
  }
}
