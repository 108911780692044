import { defineStore } from 'pinia'

export const useDistanceMeasurementStore = defineStore('distanceMeasurement', {
  state: () => {
    return {
      distanceMeasurementActive: false,
      collisionDetectActive: false,

      distanceMeasurements: []
    }
  },

  actions: {
    setDistanceMeasurementActive(distanceMeasurementActive) {
      this.distanceMeasurementActive = distanceMeasurementActive
    },

    setCollisionDetectActive(collisionDetectActive) {
      this.collisionDetectActive = collisionDetectActive
    },

    setDistanceMeasurements(distanceMeasurements) {
      this.distanceMeasurements = distanceMeasurements
    }
  }
})