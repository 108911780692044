import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { MeasurementsPlugin } from "./measurementsPlugin"
import { math } from "@xeokit/xeokit-sdk";

/*eslint-disable no-dupe-class-members*/
export class MeasurementTool {

  /**
   * @type { MeasurementsPlugin }
   */
  static #_distanceMeasurementsPlugin = null
  
  static get measurementsDirty() {
    return this.#_distanceMeasurementsPlugin?._measurements || {}
  }

  static createDistanceMeasurement(originWorldPos, targetWorldPos) {
    return this.#_distanceMeasurementsPlugin.createMeasurement({
      id: math.createUUID(),
      origin: {
        worldPos: originWorldPos,
      },
      target: {
        worldPos: targetWorldPos,
      },
      visible: true,
      wireVisible: true,
    });
  }

  static createFullDistanceMeasurement(params) {
    this.#_distanceMeasurementsPlugin.createMeasurement(params)
  }

  static clearMeasurements() {
    this.#_distanceMeasurementsPlugin?.clear();
  }

  static init() {
    this.#_distanceMeasurementsPlugin = new MeasurementsPlugin(
      XeokitMediator.viewer,
      { container: document.getElementById("measurementsScreen") }
    );
  }

  static activate() {
    XeokitMediator.viewer.scene.camera.ortho.far = 5000
    XeokitMediator.viewer.scene.camera.ortho.near = 0
  }

  static deactivate(clearMeasurements) {
    XeokitMediator.viewer.scene.camera.ortho.far = 20000
    XeokitMediator.viewer.scene.camera.ortho.near = -2000

    if (clearMeasurements) this.#_distanceMeasurementsPlugin?.clear();
  }

}