<template lang="pug">
  .themes-root
    v-btn(:class="{ 'selected': theme.name === 'light' }" icon small @click="setSelectedTheme('light')")
      v-icon(color='#757575') mdi-white-balance-sunny
    v-btn(:class="{ 'selected': theme.name === 'grey' }" icon small @click="setSelectedTheme('grey')")
      v-icon(color='#757575') mdi-theme-light-dark
    v-btn(:class="{ 'selected': theme.name === 'dark' }" icon small @click="setSelectedTheme('dark')")
      v-icon(color='#757575') mdi-weather-night
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
// import { themes } from '@/store/theme.module'

export default {
  name: "app-themes",
  
  computed: {
    ...mapState('theme', ['theme']),
  },

  methods: {
    ...mapMutations('theme', ['setTheme']),
    ...mapActions('authUser', ['setProfileSettingTheme']),

    setSelectedTheme(name) {

      this._setTheme(name)
      // const selected = themes[name.toUpperCase()]

      // this.$vuetify.theme.themes.dark.surface = selected.style
      // this.$vuetify.theme.themes.dark.toolbar = selected.style.toolbar
      // // this.$vuetify.theme.themes.dark.surface = selected.style.surface
      // // this.$vuetify.theme.themes.dark.ternary = selected.style.ternary
      // this.$vuetify.theme.themes.dark.scroll = selected.style.scroll
      // // this.$vuetify.theme.themes.dark.themeColor = selected.style.themeColor
      // // this.$vuetify.theme.themes.dark.surfaceColor = selected.style.surfaceColor
      // // this.$vuetify.theme.themes.dark.surfaceColor = selected.style.surfaceColor

      // this.$vuetify.theme.themes.light.surface = selected.style
      // this.$vuetify.theme.themes.light.toolbar = selected.style.toolbar
      // // this.$vuetify.theme.themes.light.surface = selected.style.surface
      // // this.$vuetify.theme.themes.light.ternary = selected.style.ternary
      // this.$vuetify.theme.themes.light.scroll = selected.style.scroll
      // // this.$vuetify.theme.themes.light.themeColor = selected.style.themeColor
      // // this.$vuetify.theme.themes.light.surfaceColor = selected.style.surfaceColor
      // // this.$vuetify.theme.themes.light.surfaceColor = selected.style.surfaceColor
      
      // this.$vuetify.theme.dark = ['dark'].includes(name)

      // this.setTheme(selected)
      // this.setProfileSettingTheme(selected)
    }
  }
}
</script>

<style scoped>
  .themes-root {
    display: flex;
    gap: 16px;
  }
  
  .selected {
    background: #DEDEDE;
  }
</style>