import { math, Component, Marker, getCanvasPosFromEvent } from '@xeokit/xeokit-sdk'
import { Dot } from '@xeokit/xeokit-sdk'
import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

const MAGNET_DISTANCE = 0.02

class XeokitDistanceMeasurementsControl extends Component {
  /**
   * @private
   */
  //eslint-disable-next-line
  constructor(plugin, cfg = {}) {
    super(plugin.viewer.scene)

    /**
     * The {@link DistanceMeasurementsPlugin} that owns this DistanceMeasurementsControl.
     * @type {DistanceMeasurementsPlugin}
     */
    this.plugin = plugin

    this._active = false

    // Mouse input uses a combo of events that requires us to track
    // the current DistanceMeasurement under construction. This is not used for touch input, which
    // just uses touch-move-release to make a measurement.
    this._currentDistanceMeasurementByMouse = null

    this._currentDistanceMeasurementByMouseInittouchState = {
      wireVisible: null,
      axisVisible: null,
      xAxisVisible: null,
      yaxisVisible: null,
      zAxisVisible: null,
      targetVisible: null,
    }

    // Shows 2D canvas pos of touch start
    this._touchStartDot = new Dot(plugin._container, {
      fillColor: plugin.defaultColor,
      zIndex: plugin.zIndex + 1,
      visible: false,
    })

    // Tracks 3D world pos of touch start, dynamically calculates 2D canvas pos
    this._touchStartMarker = new Marker(this, {
      id: 'distanceMeasurementMarker' + math.createUUID(),
    })

    // Routes 2D canvas pos from Marker to Dot
    this._touchStartMarker.on('canvasPos', (canvasPos) => {
      this._touchStartDot.setPos(canvasPos[0], canvasPos[1])
    })

    // Event handles from CameraControl
    this._onMouseHoverSurface = null
    this._onMouseHoverOff = null
    this._onPickedNothing = null

    // Event handles from Scene.input
    this._onInputMouseDown = null
    this._onInputMouseUp = null

    // Event handles from Canvas element
    this._onCanvasTouchStart = null
    this._onCanvasTouchEnd = null
  }

  /** Gets if this DistanceMeasurementsControl is currently active, where it is responding to input.
   *
   * @returns {Boolean}
   */
  get active() {
    return this._active
  }

  /**
   * Activates this DistanceMeasurementsControl, ready to respond to input.
   *
   */
  activate() {
    this._oldPickedMeshId = null
    this._isShiftKeyPressed = false
    this._drawedEdges = []
    this._shiftDistanceMeasurements = []
    this._shiftLastDrawedEdges = null
    this._mouseDownLeft = false

    this.timeoutDuration = 200
    this.timer = this.timeoutDuration
    this.lockDistanceMeasurement = false
    // this.debugRuler()

    //const bimCanvas = document.querySelector('#myCanvas')
    //const canvasBound = bimCanvas.getBoundingClientRect.bind(bimCanvas) // По какой-то причине они не нужны

    if (this._active) {
      return
    }

    this._magnetDot = new Dot(document.getElementById('measurementsScreen'), {})
    this._shiftKeyDown = () => {}
    document.addEventListener(
      'keydown',
      (this._shiftKeyDown = (event) => {
        if (event.shiftKey) {
          this._isShiftKeyPressed = true
          if (this._drawedEdges.length > 0 && JSON.stringify(this._shiftLastDrawedEdges) !== JSON.stringify(this._drawedEdges)) {
            this._shiftDistanceMeasurements.forEach((distanceMeasurements) => {
              distanceMeasurements.destroy()
            })
            this._shiftDistanceMeasurements = []
            this._shiftLastDrawedEdges = this._drawedEdges
            this._drawedEdges.forEach((drawedEdge) => {
              let dm = this.plugin.createMeasurement({
                id: math.createUUID(),
                origin: {
                  worldPos: drawedEdge[0],
                },
                target: {
                  worldPos: drawedEdge[1],
                },
                isAutoGenerated: true,
              })
              this._shiftDistanceMeasurements.push(dm)
            })
          }
        }
      })
    )

    this._shiftKeyUp = () => {}
    document.addEventListener(
      'keyup',
      (this._shiftKeyUp = () => {
        if (this._isShiftKeyPressed) {
          this._isShiftKeyPressed = false
          this._shiftDistanceMeasurements.forEach((distanceMeasurements) => {
            distanceMeasurements.destroy()
          })
          this._shiftDistanceMeasurements = []
          if (this._drawedEdgeMeshes.length > 0) {
            this._drawedEdges.forEach((drawedEdge) => {
              this.plugin.createMeasurement({
                id: math.createUUID(),
                origin: {
                  worldPos: drawedEdge[0],
                },
                target: {
                  worldPos: drawedEdge[1],
                },
                isAutoGenerated: true,
              })
            })
          }
        }
      })
    )

    this._escapeKeyUp = () => {}
    document.addEventListener(
      'keyup',
      (this._escapeKeyUp = (event) => {
        if (event.key === 'Escape') {
          if (this._currentDistanceMeasurementByMouse) {
            this._currentDistanceMeasurementByMouse.destroy()
            this._currentDistanceMeasurementByMouse = null
          }
        }
      })
    )

    const plugin = this.plugin
    const scene = this.scene
    const cameraControl = plugin.viewer.cameraControl
    const canvas = scene.canvas.canvas
    const input = scene.input
    const startDot = this._touchStartDot

    const pickSurfacePrecisionEnabled = scene.pickSurfacePrecisionEnabled

    let mouseHovering = false
    const mouseWorldPos = math.vec3()
    const mouseCanvasPos = math.vec2()

    let mouseDownCanvasX
    let mouseDownCanvasY

    const mouseCanvasClickTolerance = 5

    const FIRST_TOUCH_EXPECTED = 0
    const SECOND_TOUCH_EXPECTED = 1
    let touchState = FIRST_TOUCH_EXPECTED
    const touchCanvasClickTolerance = 5

    const touchStartCanvasPos = math.vec2()
    const touchEndCanvasPos = math.vec2()
    const touchStartWorldPos = math.vec3()

    this._onMouseHoverSurface = cameraControl.on('hoverSurface', (event) => {
      if (this._mouseDownLeft || this._mouseDownRight) {
        this._magnetDot.setVisible(false)
        return
      }

      mouseHovering = true

      this._magnetDot.setVisible(true)
      this._magnetDot.setFillColor('lightgreen')

      let magnetPoint = null
      this._meshesEdge = []
      this.clearDrawedMeshed()

      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
      })
      
      if (!pickResult) return
      if (pickResult.isSectionControl) return
      
      if (!pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) {
        let nearestCoordAndEdges = geometry.nearestCoordFinder.getNearestCoordAndEdgesOnEdgeOrVertexByWorldPos({
          worldPos: pickResult._worldPos,
          nearestDistance: MAGNET_DISTANCE,
          collisionDetect: XeokitMediator.DistanceMeasurement.isCollisionDetectActive,
        })

        magnetPoint = nearestCoordAndEdges.nearestCoord

        this._drawedEdges = nearestCoordAndEdges.edges
        this.drawEdges(nearestCoordAndEdges.edges)
      }

      //const { left, top } = canvasBound()

      if (magnetPoint) {
        let canvasMagnetPos = geometry.transform.worldPosToCanvasPos(magnetPoint, XeokitMediator.viewer)
        mouseWorldPos.set([magnetPoint[0], magnetPoint[1], magnetPoint[2]])
        mouseCanvasPos.set([canvasMagnetPos[0], canvasMagnetPos[1]])
        this._magnetDot.setPos(canvasMagnetPos[0], canvasMagnetPos[1])
        this._magnetDot.setFillColor('red')
      } 
      else {
        mouseWorldPos.set(pickResult._worldPos)
        mouseCanvasPos.set([pickResult.canvasPos[0], pickResult.canvasPos[1]])
        this._magnetDot.setPos(pickResult.canvasPos[0], pickResult.canvasPos[1])
      }

      canvas.style.cursor = 'pointer'
      if (this._currentDistanceMeasurementByMouse) {
        if (this._currentDistanceMeasurementByMouse.destroyed) {
          this._currentDistanceMeasurementByMouse = null
          return
        }

        this._magnetDot.setVisible(false)
        if (magnetPoint) this._currentDistanceMeasurementByMouse._targetDot.setFillColor('red')
        else this._currentDistanceMeasurementByMouse._targetDot.setFillColor('lightgreen')

        this._currentDistanceMeasurementByMouse.wireVisible = this._currentDistanceMeasurementByMouseInittouchState.wireVisible
        this._currentDistanceMeasurementByMouse.axisVisible =
          this._currentDistanceMeasurementByMouseInittouchState.axisVisible && this.plugin.defaultAxisVisible
        this._currentDistanceMeasurementByMouse.xAxisVisible =
          this._currentDistanceMeasurementByMouseInittouchState.xAxisVisible && this.plugin.defaultXAxisVisible
        this._currentDistanceMeasurementByMouse.yAxisVisible =
          this._currentDistanceMeasurementByMouseInittouchState.yAxisVisible && this.plugin.defaultYAxisVisible
        this._currentDistanceMeasurementByMouse.zAxisVisible =
          this._currentDistanceMeasurementByMouseInittouchState.zAxisVisible && this.plugin.defaultZAxisVisible
        this._currentDistanceMeasurementByMouse.targetVisible = this._currentDistanceMeasurementByMouseInittouchState.targetVisible
        this._currentDistanceMeasurementByMouse.target.worldPos = mouseWorldPos

        this.fire('measurementUpdate', this._currentDistanceMeasurementByMouse)
      }
    })

    this._onInputMouseDown = input.on('mousedown', (coords) => {
      mouseDownCanvasX = coords[0]
      mouseDownCanvasY = coords[1]
      this._mouseDownLeft = input.mouseDownLeft
      this._mouseDownRight = input.mouseDownRight
    })

    this._onInputMouseUp = input.on('mouseup', (coords) => {
      if (!this._mouseDownLeft) {
        this._mouseDownLeft = false
        this._mouseDownRight = false
        return
      }

      if (
        coords[0] > mouseDownCanvasX + mouseCanvasClickTolerance ||
        coords[0] < mouseDownCanvasX - mouseCanvasClickTolerance ||
        coords[1] > mouseDownCanvasY + mouseCanvasClickTolerance ||
        coords[1] < mouseDownCanvasY - mouseCanvasClickTolerance
      ) {
        this._mouseDownLeft = false
        this._mouseDownRight = false
        return
      }
      if (this._currentDistanceMeasurementByMouse) {
        if (mouseHovering) {
          this._currentDistanceMeasurementByMouse._targetDot.setFillColor('#00BBFF')
          this.fire('measurementEnd', this._currentDistanceMeasurementByMouse)
          this._currentDistanceMeasurementByMouse = null
        } 
        else {
          this._currentDistanceMeasurementByMouse.destroy()
          this.fire('measurementCancel', this._currentDistanceMeasurementByMouse)
          this._currentDistanceMeasurementByMouse = null
        }
      } 
      else {
        if (mouseHovering) {
          this._currentDistanceMeasurementByMouse = plugin.createMeasurement({
            id: math.createUUID(),
            origin: {
              worldPos: mouseWorldPos.slice(),
            },
            target: {
              worldPos: mouseWorldPos.slice(),
            },
            approximate: true,
          })
          this._currentDistanceMeasurementByMouseInittouchState.axisVisible =
            this._currentDistanceMeasurementByMouse.axisVisible && this.plugin.defaultAxisVisible
          this._currentDistanceMeasurementByMouseInittouchState.xAxisVisible =
            this._currentDistanceMeasurementByMouse.xAxisVisible && this.plugin.defaultXAxisVisible
          this._currentDistanceMeasurementByMouseInittouchState.yAxisVisible =
            this._currentDistanceMeasurementByMouse.yAxisVisible && this.plugin.defaultYAxisVisible
          this._currentDistanceMeasurementByMouseInittouchState.zAxisVisible =
            this._currentDistanceMeasurementByMouse.zAxisVisible && this.plugin.defaultZAxisVisible
          this._currentDistanceMeasurementByMouseInittouchState.wireVisible = this._currentDistanceMeasurementByMouse.wireVisible
          this._currentDistanceMeasurementByMouseInittouchState.targetVisible = this._currentDistanceMeasurementByMouse.targetVisible
          this.fire('measurementStart', this._currentDistanceMeasurementByMouse)
        }
      }

      this._mouseDownLeft = false
      this._mouseDownRight = false
    })

    this._onMouseHoverOff = cameraControl.on('hoverOff', () => {
      mouseHovering = false

      this.clearDrawedMeshed()
      this._magnetDot.setVisible(false)

      if (this._currentDistanceMeasurementByMouse) {
        this._currentDistanceMeasurementByMouse.wireVisible = false
        this._currentDistanceMeasurementByMouse.targetVisible = false
        this._currentDistanceMeasurementByMouse.axisVisible = false
      }
      canvas.style.cursor = 'default'
    })

    canvas.addEventListener(
      'touchstart',
      (this._onCanvasTouchStart = (event) => {
        const touches = event.touches
        const changedTouches = event.changedTouches
        if (touches.length === 1 && changedTouches.length === 1) {
          getCanvasPosFromEvent(touches[0], touchStartCanvasPos)
        }
      }),
      { passive: true }
    )

    canvas.addEventListener(
      'touchend',
      (this._onCanvasTouchEnd = (event) => {
        const touches = event.touches
        const changedTouches = event.changedTouches
        if (touches.length === 0 && changedTouches.length === 1) {
          getCanvasPosFromEvent(changedTouches[0], touchEndCanvasPos)
          if (
            touchEndCanvasPos[0] > touchStartCanvasPos[0] + touchCanvasClickTolerance ||
            touchEndCanvasPos[0] < touchStartCanvasPos[0] - touchCanvasClickTolerance ||
            touchEndCanvasPos[1] > touchStartCanvasPos[1] + touchCanvasClickTolerance ||
            touchEndCanvasPos[1] < touchStartCanvasPos[1] - touchCanvasClickTolerance
          ) {
            return // User is repositioning the camera or model
          }
          const pickResult = XeokitMediator.ScenePick.pickResult({
            canvasPos: touchEndCanvasPos,
            pickSurface: true,
          })
          if (pickResult && pickResult.worldPos) {
            switch (touchState) {
              case FIRST_TOUCH_EXPECTED: {
                startDot.setVisible(true)
                this._touchStartMarker.worldPos = pickResult.worldPos
                touchStartWorldPos.set(pickResult.worldPos)
                touchState = SECOND_TOUCH_EXPECTED
                break
              }
              case SECOND_TOUCH_EXPECTED: {
                startDot.setVisible(false)
                this._touchStartMarker.worldPos = pickResult.worldPos
                const measurement = plugin.createMeasurement({
                  id: math.createUUID(),
                  origin: {
                    worldPos: touchStartWorldPos,
                  },
                  target: {
                    worldPos: pickResult.worldPos,
                  },
                  approximate: !pickSurfacePrecisionEnabled,
                })
                measurement.clickable = true
                touchState = FIRST_TOUCH_EXPECTED
                this.fire('measurementEnd', measurement)
                break
              }
            }
          } 
          else {
            startDot.setVisible(false)
            touchState = FIRST_TOUCH_EXPECTED
          }
        }
        //  event.stopPropagation();
      }),
      { passive: true }
    )

    this._active = true
  }

  /**
   * Deactivates this DistanceMeasurementsControl, making it unresponsive to input.
   *
   * Destroys any {@link DistanceMeasurement} under construction.
   */
  deactivate() {
    if (!this._active) {
      return
    }

    this._magnetDot.destroy()

    this._touchStartDot.setVisible(false)

    this.reset()

    const input = this.plugin.viewer.scene.input
    input.off(this._onInputMouseDown)
    input.off(this._onInputMouseUp)

    const cameraControl = this.plugin.viewer.cameraControl
    cameraControl.off(this._onMouseHoverSurface)
    cameraControl.off(this._onMouseHoverOff)
    cameraControl.off(this._onPickedNothing)

    document.removeEventListener('keydown', this._shiftKeyDown)
    document.removeEventListener('keyup', this._shiftKeyUp)
    document.removeEventListener('keyup', this._escapeKeyUp)

    this._shiftDistanceMeasurements.forEach((distanceMeasurements) => {
      distanceMeasurements.destroy()
    })
    this._shiftDistanceMeasurements = []
    this._shiftLastDrawedEdges = []

    this.clearDrawedMeshed()

    const canvas = this.plugin.viewer.scene.canvas.canvas
    canvas.removeEventListener('touchstart', this._onCanvasTouchStart)
    canvas.removeEventListener('touchend', this._onCanvasTouchEnd)

    if (this._currentDistanceMeasurementByMouse) {
      this.fire('measurementCancel', this._currentDistanceMeasurementByMouse)
      this._currentDistanceMeasurementByMouse.destroy()
      this._currentDistanceMeasurementByMouse = null
    }

    this._active = false
  }

  /**
   * Resets this DistanceMeasurementsControl.
   *
   * Destroys any {@link DistanceMeasurement} under construction.
   *
   * Does nothing if the DistanceMeasurementsControl is not active.
   */
  reset() {
    if (!this._active) {
      return
    }
    if (this._currentDistanceMeasurementByMouse) {
      this.fire('measurementCancel', this._currentDistanceMeasurementByMouse)
      this._currentDistanceMeasurementByMouse.destroy()
      this._currentDistanceMeasurementByMouse = null
    }
  }

  /**
   * @private
   */
  destroy() {
    this._touchStartDot.destroy()
    this.deactivate()
    super.destroy()
  }

  clearDrawedMeshed() {
    if (this._drawedEdgeMeshes) {
      this._drawedEdgeMeshes.forEach((edgeMesh) => {
        edgeMesh?.destroy()
      })
    }

    this._drawedEdgeMeshes = []
    this._drawedEdges = []
  }

  drawEdges(edges) {
    edges.forEach((edge) => {
      let edgeMesh = XeokitMediator.SceneObjects.createSegmentMesh({
        segmentPositions: edge,
      })
      this._drawedEdgeMeshes.push(edgeMesh)
    })
  }
}

export { XeokitDistanceMeasurementsControl }
