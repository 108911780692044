<template lang="pug">
  div.floating-panel-header-container.flex( :style="style" @click="openHeader()" )
    div.d-flex.mx-1(v-if="menu")
      app-menu( :menu="menu" basic color="var(--v-primary-darken2)" )
    v-tooltip(bottom v-if="showTooltip")
      template(v-slot:activator="{ on, attrs }")
        div.floating-panel-header-title(v-bind="attrs" v-on="on" :style="menu ? '' : 'margin-left: 10px;'") {{ title }}
      span {{ title }}

    div.floating-panel-header-title(v-else :style="menu ? '' : 'margin-left: 10px;'") {{ title }}

    .d-flex.floating-panel-header-dropdown(:class="{'mr-1' : !closeable}")
      slot(name="headerButton")
      button.floating-panel-header-dropdown-btn( v-if="closeable" :class="{ 'scale-floating-panel-header-dropdown-btn': openedPanel }" )
        v-icon.floating-panel-icon( color="var(--v-primary-darken2)"  ) mdi-menu-up
</template>

<script>
export default {
  name: "FloatingPanelHeader",

  props:{
    menu : Array,

    closeable: {
      type: Boolean,
      default: true
    },

    showTooltip: {
      type: Boolean,
      default: false
    },

    openedPanel: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      default: ""
    },

    height: {
      type: [String, Number],
      default: 36
    },

    backgroundColor: {
      type: String,
      default: "#FF0000"
    },

    titleColor: {
      type: String,
      default: "#757575"
    },

    titleFont: {
      type: String,
      default: "14px 'Roboto' bold"
    },
    
    borderSize: {
      type: [String, Number],
      default: 2
    },

    borderColor: {
      type: [String, Number],
      default: "#C4C4C4" 
    },

    borderRadius: {
      type: [String, Number],
      default: "3"
    },

    dropDownIcon: {
      type: [String, Number],
      default: ""
    }
  },
  
  computed: {
    style() {
      return {
        height: this.height + "px",
        'background-color': this.backgroundColor,
        'border-bottom': this.borderSize + "px" + 'solid' + this.borderColor,
        'box-shadow': this.openedPanel ?  "0px 2px 0px" + this.borderColor : "0 0 0",
        'border-radius': this.openedPanel ? this.borderRadius + "px " + this.borderRadius + "px 0px 0px" : this.borderRadius + "px",
        font: this.titleFont,
        color: this.titleColor
      }  
    }
  },

  methods: {
    openHeader() {
      if(this.closeable) this.$emit('openBody')
    },

    add() {
      this.$emit('add')
    }
  }
}
</script>

<style>
.floating-panel-header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.floating-panel-header-dropdown-btn{
  width: 30px;
  background-color: inherit;
  border: none;
  padding: 0px; 
  cursor: pointer;
  transition: transform .15s ease-in-out;
}

.floating-panel-header-title{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
}

.scale-floating-panel-header-dropdown-btn{
  transform: scaleY(-1);
}

.floating-panel-icon{
  display: flex !important;
}
</style>