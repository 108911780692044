import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router'

Vue.use(Vuex)

import viewer from './viewer/viewer.store'
import axis from './axis/axis.module'

import error from './error/error.module'
import alert from './error/alert.module'
import menu from './menu.module'
import tokens from './token/tokens.module'
import roles from './roles.module'
import users from './users.module'
import classificator from './classificator.module'
import authUser from './authUser.module'
import languages from './languages.module'
import forms from './forms.module'
import corp from './corp.module'
import workspace from './workspace.module'
import worm from './worm.module'

import process from './process.module'
import project from './project.module'
import adminProjects from './adminProjects.module'
import adminProcess from './adminProcess.module'
import task from './task.module'
import theme from './theme.module'
import drawing from './drawing/drawings.module'

import maintenance from './maintenance/equipment.module'
import equipmentWarning from './maintenance/equipmentWarning.module'

import floatingPanels from './floatingPanels/floatingPanels.module'
import distanceMeasurement from './distanceMeasurement/distanceMeasurement.module'

import dashboard from './project/dashboard.module'
import folders from './project/folders.module'

import collision from './collision/collision.module'
import rule from './rule/rule.module'

import iot from './iot.module'

import position from './position.module'

import selected from './selected.module'

import cloud from './cloud.module'

import viewerSectionPlane from './viewerSectionPlane.module'

import smeta from './smeta/smeta.module'
import smetadictionary from './smeta/smetadictionary.module'
import smetaMerge from './smeta/smetaMerge.module'
import projectDict from './projectDict.module'
import plugins from './plugins.module'

import copyutil from './copyutil.module'

import layoutControler from './sections/layoutControler'

import taskDetails from './tasks/taskDetails.module'
import taskTreeSettings from './tasks/taskTreeSettings.module'

import template from './template.module'

import condition from './condition.module'

import mnemoschema from './mnemoschema/mnemoschema.module'
import receiver from './mnemoschema/receiver.module'
import document from './document.module'

import patches from './patches.module'

import channel from './mnemoschema/channel.module'

import notification from './notification.module'

import IFCGrids from './IFCGrids.module'
import IFCAnnotations from './IFCAnnotations.module'
import elements from './elements.module'

import { $_app_server } from '@/_helpers'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import VuexORM from '@vuex-orm/core'
import WormNodeType from '@/assets/model/worm/WormNodeType'
import ProjectWorm from '@/assets/model/worm/ProjectWorm'
import WormNode from '@/assets/model/worm/WormNode'
import WormNodeModule from '@/assets/model/worm/WormNodeModule'
import WormVersion from '@/assets/model/worm/WormVersion'
import WormVersionNode from '@/assets/model/worm/WormVersionNode'
import RuleConditionValid from '@/assets/model/condition/RuleConditionValid'
import WormConditionValid from '@/assets/model/condition/WormConditionValid'
import ElementRuleConditionValid from '@/assets/model/condition/ElementRuleConditionValid'
import ElementWormConditionValid from '@/assets/model/condition/ElementWormConditionValid'
import locationControl from "./locationControl/locationControl.module"

import projectPermissions from "./projectPermissions.module"



VuexORM.use(VuexORMAxios, { axios: $_app_server })

const database = new VuexORM.Database()
database.register(WormNodeType)
database.register(ProjectWorm)
database.register(WormNode, WormNodeModule)
database.register(WormVersion)
database.register(WormVersionNode)
database.register(RuleConditionValid)
database.register(WormConditionValid)
database.register(ElementRuleConditionValid)
database.register(ElementWormConditionValid)

export const store = new Vuex.Store({


  plugins: [VuexORM.install(database)],

  modules: {
    authUser,
    error,
    alert,
    languages,
    roles,
    users,
    theme,
    copyutil
  },
})

if (window.Cypress) {
  window.store = store
}

let isHome = true
router.afterEach((to) => {
  if (isHome && to.path !== "/" && to.path !== "/home" && to.path !== "/index") {
    if (!store.state.viewer) store.registerModule('viewer', viewer);
    if (!store.state.axis) store.registerModule('axis', axis);
    if (!store.state.menu) store.registerModule('menu', menu);
    if (!store.state.classificator) store.registerModule('classificator', classificator);
    if (!store.state.process) store.registerModule('process', process);
    if (!store.state.tokens) store.registerModule('tokens', tokens);
    if (!store.state.project) store.registerModule('project', project);
    if (!store.state.adminProjects) store.registerModule('adminProjects', adminProjects);
    if (!store.state.adminProcess) store.registerModule('adminProcess', adminProcess);
    if (!store.state.dashboard) store.registerModule('dashboard', dashboard);
    if (!store.state.folders) store.registerModule('folders', folders);

    if (!store.state.task) store.registerModule('task', task);
    if (!store.state.drawing) store.registerModule('drawings', drawing);
    if (!store.state.collision) store.registerModule('collision', collision);
    if (!store.state.rule) store.registerModule('rule', rule);
    if (!store.state.iot) store.registerModule('iot', iot);
    if (!store.state.position) store.registerModule('position', position);
    if (!store.state.selected) store.registerModule('selected', selected);
    if (!store.state.cloud) store.registerModule('cloud', cloud);
    if (!store.state.viewerSectionPlane) store.registerModule('viewerSectionPlane', viewerSectionPlane);
    if (!store.state.smeta) store.registerModule('smeta', smeta);
    if (!store.state.smetaMerge) store.registerModule('smetaMerge', smetaMerge)
    if (!store.state.smetadictionary) store.registerModule('smetadictionary', smetadictionary);
    if (!store.state.projectDict) store.registerModule('projectDict', projectDict);
    if (!store.state.worm) store.registerModule('worm', worm);
    if (!store.state.plugins) store.registerModule('plugins', plugins);
    if (!store.state.layoutControler) store.registerModule('layoutControler', layoutControler);
    if (!store.state.forms) store.registerModule('forms', forms);
    if (!store.state.corp) store.registerModule('corp', corp);
    if (!store.state.maintenance) store.registerModule('maintenance', maintenance);
    if (!store.state.equipmentWarning) store.registerModule('equipmentWarning', equipmentWarning);
    if (!store.state.workspace) store.registerModule('workspace', workspace);
    if (!store.state.taskDetails) store.registerModule('taskDetails', taskDetails);
    if (!store.state.taskTreeSettings) store.registerModule('taskTreeSettings', taskTreeSettings);
    if (!store.state.template) store.registerModule('template', template);
    if (!store.state.condition) store.registerModule('condition', condition);
    if (!store.state.floatingPanels) store.registerModule('floatingPanels', floatingPanels);
    if (!store.state.distanceMeasurement) store.registerModule('distanceMeasurement', distanceMeasurement)
    if (!store.state.mnemoschema) store.registerModule('mnemoschema', mnemoschema);
    if (!store.state.receiver) store.registerModule('receiver', receiver);
    if (!store.state.document) store.registerModule('document', document);
    if (!store.state.patches) store.registerModule('patches', patches);
    if (!store.state.channel) store.registerModule('channel', channel);
    if (!store.state.notification) store.registerModule('notification', notification);
    if (!store.state.IFCGrids) store.registerModule('IFCGrids', IFCGrids);
    if (!store.state.IFCAnnotations) store.registerModule('IFCAnnotations', IFCAnnotations);
    if (!store.state.locationControl) store.registerModule('locationControl', locationControl);
    if (!store.state.elements) store.registerModule('elements', elements);
    if (!store.state.projectPermissions) store.registerModule('projectPermissions', projectPermissions);
    isHome = false
  }
})


export default store

// export default new Vuex.Store({
//   modules: {
//     viewer,
//     axis,

//     error,
//     alert,
//     menu,
//     roles,
//     users,
//     classificator,
//     authUser,
//     process,
//     tokens,
//     project,
//     adminProjects,
//     dashboard,
//     task,
//     collision,
//     collisions,
//     theme,
//     rule,
//     iot,
//     position,
//     selected,
//     cloud,
//     viewerAnnotations,
//     viewerSectionPlane,
//     smeta,
//     plugins,
//     languages
//   },
// })
