export class SmetaMergedElement {
  static rootElements = 0
  static totalVisible = 0
  static allElements = 0
  constructor(json) {
    this.element = json
    this.visible = json.level == 0 ? true : false
    this.accepted = false
    this.disabled = false // Блокировка кнопки применения
    this.isNode = json.type == 'TOPIC' || json.type == 'NODE'
    this.uuid = json?.uuid
    this.parentUuid = json?.parentUuid
    this.children = []
    if (json.level == 0) SmetaMergedElement.rootElements += 1
    this.expanded = false
  }


  toggleVisibility(flag) {
    if (this.visible == flag) {
      return
    }
    this.visible = flag
    if (this.visible) SmetaMergedElement.totalVisible++
    else SmetaMergedElement.totalVisible--
  }

  toggleAcceptance(flag) {
    this.accepted = flag
  }

  toggleDisable(flag) {
    this.disabled = flag
  }

  toggleExpanded(flag) {
    this.expanded = flag
  }

  setChildren(list = []) {
    this.children = list
  }

  getTitle() {
    let text = ''
    if (this.element.hasRules) text += 'Содержит правило - '
    if (this.element.hasTask) text += 'Содержит задачу - '
    if (this.element.hasWorm) text += 'Содержит ВОРМ'
    return text
  }
}