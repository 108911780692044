import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { adminRoutes } from "./routes/admin"
import { projectRoutes } from "./routes/project"
import { accessRoutes } from "./routes/access"
import { taskRoutes } from "./routes/task"

let router =  new VueRouter({
  routes: [
    projectRoutes,
    adminRoutes,
    accessRoutes,
    taskRoutes,

    {
      path: '/mnemo/project/:projectUuid/schema/:mnemoschemaUuid',
      props: true,
      component: () => import('@/components/project/panel/left/mnemoschemas/components/Editor.vue')
    }
  ],
  linkActiveClass: 'active',
  mode: 'history',
})

// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title
//   next()
// })

export default router