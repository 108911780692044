import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { EdgeMeasurement } from "./regimes/edgeMeasurement";
import { PointToPointMeasurement } from "./regimes/pointToPointMeasurement";
import { MeasurementTool } from "./measurement/measurementTool";
import { PointToEdgeMeasurement } from "./regimes/pointToEdgeMeasurement";
import { EdgeToEdgeMeasurement } from "./regimes/edgeToEdgeMeasurement";
import { PointToFaceMeasurement } from "./regimes/pointToFaceMeasurement";
import { FaceToFaceMeasurement } from "./regimes/faceToFaceMeasurement";
import { DistanceMeasurement } from "@/plugins/xeokit/distanceMeasurement/DistanceMeasurement";
import { VTF } from "../../VTF";
import { math } from "@xeokit/xeokit-sdk";

/*eslint-disable no-dupe-class-members*/
export class RegimeMeasurement {

  static modeClasses = { 
    PointToPointMeasurement: PointToPointMeasurement, 
    EdgeMeasurement: EdgeMeasurement, 
    PointToEdgeMeasurement: PointToEdgeMeasurement, 
    EdgeToEdgeMeasurement: EdgeToEdgeMeasurement,
    PointToFaceMeasurement: PointToFaceMeasurement,
    FaceToFaceMeasurement: FaceToFaceMeasurement,
    Ruler: DistanceMeasurement
  };

  static get #_active()  {
    return XeokitMediator.RegimeMeasurement.isRegimeMeasurementActive
  }

  static get RegimeMeasurementModeClass() {
    return XeokitMediator.RegimeMeasurement.RegimeMeasurementModeClass
  }

  static get measurementsDirty() {
    return MeasurementTool.measurementsDirty
  }

  static createFullDistanceMeasurement(params) {
    MeasurementTool.createFullDistanceMeasurement(params)
  }

  static clearAllMeasurement() {
    Object.keys(this.measurementsDirty).forEach(modeClassName => {
      this.measurementsDirty[modeClassName].destroy()
    })
    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
  }

  static init() {
    MeasurementTool.init()
    XeokitMediator.DistanceMeasurement.init()
  }

  /**
   * Установить видимость указанных измерений.
   * @param {Object[]} distanceMeasurements=[] Измерения.
   * @param {Boolean} visible True/False
   */
  static setDistanceMeasurementsVisible(distanceMeasurements = [], visible) {
    const rulerMeasurements = XeokitMediator.DistanceMeasurement.measurementsDirty
    const regimeMeasurements = XeokitMediator.RegimeMeasurement.measurementsDirty

    distanceMeasurements.forEach(distanceMeasurement => {
      if (distanceMeasurement.id in rulerMeasurements) rulerMeasurements[distanceMeasurement.id].visible = visible
      if (distanceMeasurement.id in regimeMeasurements) regimeMeasurements[distanceMeasurement.id].visible = visible
    })

    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
  }

  /**
   * Удалить указанные измерения.
   * @param {Object[]} distanceMeasurements=[] Измерения.
   */
  static removeDistanceMeasurements(distanceMeasurements = []) {
    const rulerMeasurements = XeokitMediator.DistanceMeasurement.measurementsDirty
    const regimeMeasurements = XeokitMediator.RegimeMeasurement.measurementsDirty

    distanceMeasurements.forEach(distanceMeasurement => {
      if (distanceMeasurement.id in rulerMeasurements) rulerMeasurements[distanceMeasurement.id].destroy()
      if (distanceMeasurement.id in regimeMeasurements) regimeMeasurements[distanceMeasurement.id].destroy()
    })
    
    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
  }

  static updateAllMeasurement() {
    const rulerMeasurements = XeokitMediator.DistanceMeasurement.measurementsDirty
    const regimeMeasurements = XeokitMediator.RegimeMeasurement.measurementsDirty

    let measurements = []
    
    Object.values(rulerMeasurements).forEach(measurement => {
      console.log(measurement)
      measurements.push({
        origin: measurement._originWorld,
        target: measurement._targetWorld,
        visible: measurement._visible,
        wireVisible: measurement._wireVisible,
        mode: measurement.mode,
        createDate: measurement.createDate
      })
      measurement.destroy()
    });

    measurements.forEach((measurement) => {
      VTF.DistanceMeasurement.createFullDistanceMeasurement({
        id: math.createUUID(),
        origin: {
          worldPos: measurement.origin,
        },
        target: {
          worldPos: measurement.target,
        },
        visible: measurement.visible,
        wireVisible: measurement.wireVisible,
        mode: measurement.mode,
        createDate: measurement.createDate
      });
    })
    
    measurements = []

    Object.values(regimeMeasurements).forEach(measurement => {
      console.log(measurement)
      measurements.push({
        origin: measurement._originWorld,
        target: measurement._targetWorld,
        visible: measurement._visible,
        wireVisible: measurement._wireVisible,
        mode: measurement.mode,
        createDate: measurement.createDate
      })
      measurement.destroy()
    });

    measurements.forEach((measurement) => {
      MeasurementTool.createFullDistanceMeasurement({
        id: math.createUUID(),
        origin: {
          worldPos: measurement.origin,
        },
        target: {
          worldPos: measurement.target,
        },
        visible: measurement.visible,
        wireVisible: measurement.wireVisible,
        mode: measurement.mode,
        createDate: measurement.createDate
      });
    })

    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
  }

  /** Активация режимной линейка
  */
  static activate() {
    // this.#_clearAllMesurement()
    this.RegimeMeasurementModeClass.activate()
    if (this.RegimeMeasurementModeClass != this.modeClasses.Ruler) MeasurementTool.activate()
  }

  /**
   * Выключение режимной линейки
   */
  static deactivate(clearMeasurements) {
    if (clearMeasurements) this.clearAllMesurement()

    this.RegimeMeasurementModeClass.deactivate()
    if (this.RegimeMeasurementModeClass != this.modeClasses.Ruler) MeasurementTool.deactivate(clearMeasurements)
  }
}