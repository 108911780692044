import { usePickCoordinateViewerStore } from '@/pinia'
import { VTF } from "@/plugins/viewerTools/VTF";

/*eslint-disable no-dupe-class-members*/
export class PickCoordinateViewer {

  static get #_pickCoordinateViewerStore() {
    return usePickCoordinateViewerStore()
  }

  /** Текущая активность координат точки */
  static get isPickCoordinateViewerActive() {
    return this.#_pickCoordinateViewerStore.isPickCoordinateViewerActive
  }

  /** Координаты точки */
  static get pickCoordinates() {
    return this.#_pickCoordinateViewerStore.pickCoordinates
  }

  /** Устанавливает состояние координат точки */
  static pickCoordinateViewerActivity(activityStatus) {
    this.#_pickCoordinateViewerStore.setPickCoordinateViewerActive(activityStatus)
  }

  /** Установить координаты точки */
  static setPickCoordinates(coords, xktVersion) {
    this.#_pickCoordinateViewerStore.setPickCoordinates(coords, xktVersion)
  }

  /** Включить координаты точки */
  static activate() {
    VTF.PickCoordinateViewer.activate()
  }

  /** Выключить координаты точки */
  static deactivate() {
    VTF.PickCoordinateViewer.deactivate()
  }

  /**Создать координатную точку на сцене. Предыдущая будет уничтожена.
   * 
   * @param {Array<Number>} coords Позиция координатной точки 
   */
  static createCoordinatePoint(coords) {
    this.destroyCoordinatePoint()
    VTF.PickCoordinateViewer.createCoordinatePoint(coords)
  }

  /** Унчитожить координатную точку на сцене */
  static destroyCoordinatePoint() {
    VTF.PickCoordinateViewer.destroyCoordinatePoint()
  }
}