import { ClassificatorNodeRule } from "."
export class ClassificatorNode {

  constructor (node, level = 0, parent = null) {
    this.uuid = node.uuid
    this.pressmark = node.pressmark
    this.title = node.title
    this.nodeType = node.nodeType
    this.unitValue = node.unitValue
    this.unitType = node.unitType
    this.projectClassificator = node.projectClassificator
    this.abstractNode = node.abstractNode
    
    this.level = level
    this.parent = parent

    this.projectClassificatorUuid = node.projectClassificatorUuid

    this.children = node.children ? node.children.map(item => new ClassificatorNode(item, level + 1, this)) : []
    if (node.rules && node.rules.length>0) this.children = this.children.concat(node.rules.map(rule => {
      //rule.nodeType = {name:'RULE'}
      return new ClassificatorNodeRule(rule, level + 1, this)
    }))

    this.hasChilds = (this.children && this.children.length>0) ? true : false
    this.hasRules = (node.rules && node.rules.length>0) ? true : false

    this.isPenult = this.children.every(child => child.children.length == 0)

    this.isEdit = false

    this.taskUuid = node.taskUuid

    this.hasGrandSmeta = false
    this.grandSmetaPosition = null

    this.hasValidRule = false
  }

  get isElement () {
    return this.nodeType.name == 'NODE'
  }

  get isTopic () {
    return this.nodeType.name == 'TOPIC'
  }

  get isWork () {
    return this.nodeType.name == 'WORK'
  }

  get isMaterial () {
    return this.nodeType.name == 'MATERIAL'
  }

  get isMachine () {
    return this.nodeType.name == 'MACHINE'
  }

  get isRule () {
    return this.nodeType.name == 'RULE'
  }



  get isNode () {
    return this.children.length > 0
  }

  get isEndpoint () {
    return !this.isNode
  }
}