import { api } from '@/api'
import store from '@/store'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

import _ from 'lodash'
import { PanelController } from '@/pages/panelController'
import { AlertService } from '@app/AlertService'

import { CollisionBimAnnotations } from '@/components/project/panel/left/components/collision/collisionBimAnnotations'
import { math } from '@xeokit/xeokit-sdk'

import i18n from '@/plugins/i18n'

import { SceneModel } from '@xeokit/xeokit-sdk'
// import { Mesh } from "@xeokit/xeokit-sdk";
// import { ReadableGeometry } from "@xeokit/xeokit-sdk";
// import { PhongMaterial } from "@xeokit/xeokit-sdk";
import { EdgeMaterial } from '@xeokit/xeokit-sdk'
import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
// import { EmphasisMaterial } from "@xeokit/xeokit-sdk";

/*eslint-disable no-dupe-class-members*/
export default class CollisionHighlightService {
  static get #_globalIdA() {
    return store.state.collision.search.collisionSelectedGlobalIds.globalIdA
  }

  static get #_globalIdB() {
    return store.state.collision.search.collisionSelectedGlobalIds.globalIdB
  }

  static get #_scene() {
    return XeokitMediator.viewer.scene
  }

  static get #_isCollisionSelected() {
    return this.#_globalIdA || this.#_globalIdB
  }

  static get #_selectedCollisionsElementAB() {
    return store.getters.collision.table.selectedCollisionsElementAB
  }

  static get #_collisionSelectedElements() {
    return store.state.collision.search.collisionSelectedElements
  }

  static get #_collisionSelectedInfo() {
    return store.state.collision.search.collisionSelectedInfo
  }

  static get #_collisionSelectedGlobalIds() {
    return store.state.collision.search.collisionSelectedGlobalIds
  }

  static get #_intersectionModel() {
    return store.state.collision.search.intersectionModel
  }

  static get #_activeGlobalTab() {
    return store.state.project.activeGlobalTab
  }

  static get #_showIntersection() {
    return window.settings.showIntrsection || false
  }

  static #_loadCollisionSelectedView(data) {
    return store.dispatch(this.collisionSearch + 'loadCollisionSelectedView', data)
  }

  static #_loadGeometryCollisionIntersect(data) {
    return store.dispatch(this.collisionSearch + 'loadGeometryCollisionIntersect', data)
  }

  static #_setCollisionSelectedElements(data) {
    store.commit(this.collisionSearch + 'setCollisionSelectedElements', data)
  }

  static #_setIntersectionModel(data) {
    store.commit(this.collisionSearch + 'setIntersectionModel', data)
  }

  static onMouseClicked = () => {}
  static onMouseClickedListener = () => {}
  static onMouseClickListener = () => {}
  static mouseClick = null

  static collisionSearch = 'collision/search/'
  static collisionTable = 'collision/table/'

  static listXrayed = []
  static uniqModels = []
  static selectedList = []
  static highlightedList = []
  static colorsModel = [
    // [ 255, 255, 102 ], //
    [255, 153, 0],
    [255, 102, 51], // персиковый
    [102, 51, 0], //коричневый
    [255, 0, 0], // красный
    [204, 255, 0], // лайм
    [255, 0, 204],
    [102, 255, 102],
    [51, 0, 255],
    [0, 153, 255],
    [255, 204, 255], // почти белый
    [0, 102, 51], // dark green
  ]

  static changeSelectedElement = _.debounce(this.#changeSelectedElement, 200)

  static goToGroupCamera(groupUuid) {
    api.collisionGroups.getGroupCollisionView(groupUuid).then((geom) => {
      if (geom && geom.view) {
        let eye = [geom.view.x, geom.view.y, geom.view.z]
        let look = [geom.point.x, geom.point.y, geom.point.z]
        let up = [geom.up.x, geom.up.y, geom.up.z]
        XeokitMediator.CameraFlight.cameraFlyTo({ eye: eye, look: look, up: up, duration: 0.5 })
      }
    })
  }

  static setCollisionSelected(collision) {
    if (store.state.collision.search.loadElement.isNeedWait && store.state.collision.search.loadElement.status) return

    if (store.state.collision.search.collisionSelectedGlobalIds?.collisionUuid != collision.uuid) {
      let { globalIdA, globalIdB, camera, info, uuid } = collision

      store.dispatch(this.collisionSearch + 'setCollisionUuid', uuid)

      if (camera && camera !== undefined) {
        XeokitMediator.CameraFlight.cameraFlyTo({
          eye: camera.eye,
          look: camera.look,
          up: camera.up,
        })
      }
      store.commit(this.collisionSearch + 'setCollisionSelectedElementsModel', [info.modelA, info.modelB])
      store.commit(this.collisionSearch + 'setCollisionSelectedElements', [globalIdA, globalIdB])
      store.commit(this.collisionSearch + 'setCollisionSelectedInfo', info)

      store.commit(this.collisionSearch + 'setCollisionSelectedGlobalIds', { globalIdA, globalIdB })
      store.dispatch(this.collisionSearch + 'setChoosenGlobalId', globalIdA)
    }
  }

  static handleSelectModel(collision, model) {
    if (store.state.collision.search.loadElement.isNeedWait && store.state.collision.search.loadElement.status) return

    if (store.state.collision.search.collisionSelectedGlobalIds?.collisionUuid === collision.uuid) {
      this.changeSelectedElement(collision, model)
    } 
    else {
      let { globalIdA, globalIdB, uuid } = collision

      store.dispatch(this.collisionSearch + 'setCollisionUuid', uuid)

      let { modelA, modelB } = collision.info

      if (model === 'a') {
        if (store.getters[this.collisionSearch + 'choosenId'] === collision.globalIdA) {
          store.state.collision.search.collisionSelectedGlobalIds.element.globalId = null
        }
        store.dispatch(this.collisionSearch + 'setChoosenGlobalId', collision.globalIdA)
      } 
      else if (model === 'b') {
        if (store.getters[this.collisionSearch + 'choosenId'] === collision.globalIdB) {
          store.state.collision.search.collisionSelectedGlobalIds.element.globalId = null
        }
        store.dispatch(this.collisionSearch + 'setChoosenGlobalId', collision.globalIdB)
      }
      store.commit(this.collisionSearch + 'setCollisionSelectedElementsModel', [modelA, modelB])
      store.commit(this.collisionSearch + 'setCollisionSelectedElements', [globalIdA, globalIdB])
      store.commit(this.collisionSearch + 'setCollisionSelectedGlobalIds', { globalIdA, globalIdB })
      store.commit(this.collisionSearch + 'setCollisionSelectedInfo', collision.info)
    }
  }

  static #changeSelectedElement(collision = null, model = null) {
    if (store.state.collision.search.loadElement.isNeedWait && store.state.collision.search.loadElement.status) return

    if (collision) {
      if (store.getters[this.collisionSearch + 'choosenId'] === collision.globalIdA && model != 'a') {
        store.dispatch(this.collisionSearch + 'setChoosenGlobalId', collision.globalIdB)
      } 
      else if (store.state.collision.search.collisionSelectedGlobalIds.choosen === collision.globalIdB && model != 'b') {
        store.dispatch(this.collisionSearch + 'setChoosenGlobalId', collision.globalIdA)
      }
    } 
    else {
      let a = store.state.collision.search.collisionSelectedGlobalIds.globalIdA
      let b = store.state.collision.search.collisionSelectedGlobalIds.globalIdB
      if (store.state.collision.search.collisionSelectedGlobalIds.choosen === a) {
        store.dispatch(this.collisionSearch + 'setChoosenGlobalId', b)
      } 
      else if (store.state.collision.search.collisionSelectedGlobalIds.choosen === b) {
        store.dispatch(this.collisionSearch + 'setChoosenGlobalId', a)
      }
    }

    this.colorizeSelectedCollisionElements()
  }

  /**
   * Изменение вариантов отображения выделенной коллизии
   *  0 — обычный вариант (элемент а и b и их пересечение)
   *  1 — только элемент a
   *  2 — только элемент a
   *  3 — только пересечение а и b
   */
  static settingSelectedObjects() {
    if (store.state.collision.search.collisionSelectedElements.length > 0) {
      let type = store.state.collision.table.selectedType
      let intersection = store.state.collision.search.intersectionModel
      XeokitMediator.ElementsSettings.setElementsVisible(this.#_scene.objectIds, false)
      switch (type) {
        case 0:
          XeokitMediator.ElementsSettings.setElementsVisible(this.#_scene.objectIds, true)
          if (intersection) intersection.visible = true
          break
        case 1:
          XeokitMediator.ElementsSettings.setElementsVisible([store.state.collision.search.collisionSelectedElements[0]], true)
          if (intersection) intersection.visible = false
          break
        case 2:
          XeokitMediator.ElementsSettings.setElementsVisible([store.state.collision.search.collisionSelectedElements[1]], true)
          if (intersection) intersection.visible = false
          break
        case 3:
          if (intersection) intersection.visible = true
          break
      }
    }
  }

  static highlightViewCollision(restoreData, collision) {
    XeokitMediator.ElementsSettings.setElementsXRayed(this.#_scene.objectIds, true)
    XeokitMediator.viewer.scene.setObjectsPickable(XeokitMediator.viewer.scene.objectIds, false)
    XeokitMediator.ElementsSettings.setXrayMaterialEdgeAlpha(0.3)
    XeokitMediator.ElementsSettings.setXrayMaterialFill(false)
    XeokitMediator.ElementsSettings.setXrayMaterialEdgesWidth(5)
    XeokitMediator.ElementsSettings.setXrayMaterialGlowingThrough(false)

    if (restoreData.currentViewType == 0) {
      const elA = collision.globalIdA
      const elB = collision.globalIdB

      const selectedCollisions = restoreData.selectedCollisionList
      const selectedCollisionsModels = restoreData.selectedCollisionModelsList
      let highlightedElements = [elA, elB, ...selectedCollisions]
      let uniqueModels = []

      XeokitMediator.viewer.scene.setObjectsPickable(highlightedElements, true)
      XeokitMediator.ElementsSettings.setElementsXRayed(highlightedElements, false)

      if (selectedCollisions.length > 0) {
        uniqueModels = _.uniq(selectedCollisionsModels)
        let listColor = selectedCollisionsModels.map((model) => {
          let rgb = this.colorsModel[uniqueModels.indexOf(model)]
          let r = rgb[0] / 256
          let g = rgb[1] / 256
          let b = rgb[2] / 256
          return [r, g, b]
        })
        selectedCollisions.forEach((globalID, i) => {
          XeokitMediator.ElementsSettings.setElementsColorized([globalID], listColor[i])
        })
      }

      if (elB != null) {
        XeokitMediator.ElementsSettings.setElementsColorized(elA, elA == restoreData.choosenUuid ? [0, 1, 0] : [1, 1, 0])
        XeokitMediator.ElementsSettings.setElementsColorized(elB, elB == restoreData.choosenUuid ? [0, 1, 0] : [1, 1, 0])
      }
    }

    if (restoreData.currentViewType == 1) {
      const elA = collision.globalIdA
      if (elA != null) {
        XeokitMediator.viewer.scene.setObjectsPickable(elA, true)
        XeokitMediator.ElementsSettings.setElementsXRayed(elA, false)
        if (collision.globalIdB != null) {
          XeokitMediator.ElementsSettings.setElementsColorized(elA, elA == restoreData.choosenUuid ? [0, 1, 0] : [1, 1, 0])
        }
      }
    }

    if (restoreData.currentViewType == 2) {
      const elB = collision.globalIdB
      if (elB != null) {
        XeokitMediator.viewer.scene.setObjectsPickable(elB, true)
        XeokitMediator.ElementsSettings.setElementsXRayed(elB, false)
        XeokitMediator.ElementsSettings.setElementsColorized(elB, elB == restoreData.choosenUuid ? [0, 1, 0] : [1, 1, 0])
      }
    }

    if (restoreData.currentViewType == 3) {
      if (store.state.collision.search.intersectionModel) {
        this.destroyIntersectionModel(store.state.collision.search.intersectionModel)
      }
      this.#_loadGeometryCollisionIntersect(collision.uuid).then((geom) => {
        if (geom) {
          const model = this.loadElement(collision.uuid, geom)
          this.#_setIntersectionModel(model)
        }
      })
    }
  }

  static highlightSelectedCollision() {
    this.listXrayed = [] // Надо обсудить (303-304)

    if (
      store.getters[this.collisionTable + 'selectedCollisionsElementAB'].length > 0 ||
      store.state.collision.search.collisionSelectedElements?.length > 0
    ) {
      this.highlightedList = []
      this.selectedList = []
      let list = [
        ...store.state.collision.search.collisionSelectedElements,
        ...store.getters[this.collisionTable + 'selectedCollisionsElementAB'],
      ]
      let listModel = [
        ...store.state.collision.search.collisionSelectedElementsModel,
        ...store.getters[this.collisionTable + 'selectedCollisionsModelAB'],
      ]

      let xrayed = false
      list.forEach((colId) => {
        if (colId != null && colId != '') {
          if (this.#_scene.objects[colId] != null) {
            xrayed = true
          }
        }
      })

      if (xrayed) {
        // Включаем режим скелетирования всех объектов
        if (this.listXrayed.length > 0) XeokitMediator.ElementsSettings.setElementsXRayed(this.listXrayed, true)
        else XeokitMediator.ElementsSettings.setElementsXRayed(this.#_scene.objectIds, true)
        this.listXrayed = list

        // ----- По просьбе от 21.10.2023 ------

        // Выставляем всем объектам "запрет" на выделение
        XeokitMediator.viewer.scene.setObjectsPickable(XeokitMediator.viewer.scene.objectIds, false)
        // Включаем возможность выделения объектов коллизии
        XeokitMediator.viewer.scene.setObjectsPickable(list, true)

        // ----- По просьбе от 21.10.2023 ------

        // Выключаем коолизиионные объекты
        XeokitMediator.ElementsSettings.setElementsXRayed(list, false)
        // Прозрачность материала и граней
        // XeokitMediator.ElementsSettings.setXrayMaterialFillAlpha(0.1)
        XeokitMediator.ElementsSettings.setXrayMaterialEdgeAlpha(0.3)
        //XeokitMediator.ElementsSettings.setXrayMaterialEdgeColor([1,1,1])
        XeokitMediator.ElementsSettings.setXrayMaterialFill(false)
        XeokitMediator.ElementsSettings.setXrayMaterialEdgesWidth(5)
        XeokitMediator.ElementsSettings.setXrayMaterialGlowingThrough(false)
        // Все желтые
        // XeokitMediator.ElementsSettings.setElementsColorized(list, [ 1, 1, 0 ])

        if (list.length > 0) {
          // Вычисляем уникальные модели
          if (this.uniqModels.length > 0) {
            listModel.forEach((m) => {
              if (!this.uniqModels.includes(m)) this.uniqModels.push(m)
            })
          } 
          else this.uniqModels = _.uniq(listModel)

          // Раздаем цвета по индексу модели
          let listColor = listModel.map((model) => {
            let rgb = this.colorsModel[this.uniqModels.indexOf(model)]
            let r = rgb[0] / 256
            let g = rgb[1] / 256
            let b = rgb[2] / 256
            return [r, g, b]
          })

          // Закрашиваем объекты по цвету модели
          list.forEach((globalID, i) => {
            XeokitMediator.ElementsSettings.setElementsColorized([globalID], listColor[i])
            // scene.setObjectsOpacity([globalID], 0.8) // Создавала полупрозрачность на объектах, либо чистить, либо не использовать
          })
        }
        this.colorizeSelectedCollisionElements()
      }
    } 
    else {
      XeokitMediator.Models.restoreModels()
      CollisionBimAnnotations.clearCollisionBimAnnotation()
    }
  }

  static collisionBalloonAndIntersection() {
    let showBalloon = false
    if (this.#_collisionSelectedElements?.length > 0) {
      if (this.#_activeGlobalTab !== 'collision') {
        this.#_scene.setObjectsHighlighted(this.#_scene.objectIds, false)
        XeokitMediator.ElementsSettings.setElementsSelected(this.#_scene.objectIds, false)

        let [first, second] = this.#_collisionSelectedElements

        this.#_scene.setObjectsHighlighted([first], true)
        XeokitMediator.ElementsSettings.setElementsSelected([second], true)
      }

      // if (this.camera == null) {        // TODO Сделать другую проверку для выбора отображаемых элементов
      let error = ''
      let info = this.#_collisionSelectedInfo
      if (
        this.#_collisionSelectedElements[0] !== null &&
        this.#_collisionSelectedElements[0] !== '' &&
        this.#_collisionSelectedElements[1] !== null &&
        this.#_collisionSelectedElements[1] !== ''
      ) {
        if (
          this.#_scene.objects[this.#_collisionSelectedElements[0]] == null ||
          this.#_scene.objects[this.#_collisionSelectedElements[1]] == null
        ) {
          showBalloon = true
          if (this.#_scene.objects[this.#_collisionSelectedElements[0]] == null) {
            error =
              i18n.t('section.collision.rulesFounder.collisionElementA') +
              ' (' +
              'Модель: ' +
              info.modelA +
              ', Ревизия: ' +
              info.numberRevisionA +
              ')'
          } 
          else if (this.#_scene.objects[this.#_collisionSelectedElements[1]] == null) {
            error =
              i18n.t('section.collision.rulesFounder.collisionElementB') +
              ' (' +
              'Модель: ' +
              info.modelB +
              ', Ревизия: ' +
              info.numberRevisionB +
              ')'
          }
          if (
            this.#_scene.objects[this.#_collisionSelectedElements[0]] == null &&
            this.#_scene.objects[this.#_collisionSelectedElements[1]] == null
          ) {
            error =
              i18n.t('section.collision.rulesFounder.collisionElements') +
              ' (' +
              'Модель: ' +
              info.modelA +
              ', Ревизия: ' +
              info.numberRevisionA +
              ')' +
              ' и ' +
              '(' +
              'Модель: ' +
              info.modelB +
              ', Ревизия: ' +
              info.numberRevisionB +
              ')'
          }
        }
      } 
      else {
        if (this.#_collisionSelectedElements[0] != null && this.#_collisionSelectedElements[0] !== '') {
          if (this.#_scene.objects[this.#_collisionSelectedElements[0]] == null) {
            showBalloon = true
            error =
              i18n.t('section.collision.rulesFounder.collisionElement') +
              ' (' +
              'Модель: ' +
              info.modelA +
              ', Ревизия: ' +
              info.numberRevisionA +
              ')'
          }
        }
      }

      PanelController.setPanelPadding(true)

      if (this.#_collisionSelectedGlobalIds.collisionUuid != null) {
        this.#_loadCollisionSelectedView(this.#_collisionSelectedGlobalIds.collisionUuid).then((view) => {
          if (view && view.view) {
            let eye = [view.view.x, view.view.y, view.view.z]
            let look = [view.point.x, view.point.y, view.point.z]
            let up = [view.up.x, view.up.y, view.up.z]
            if (showBalloon && view.childElements && view.childElements.length > 0) {
              view.childElements.forEach((globalId) => {
                if (globalId != null && globalId !== '') {
                  if (this.#_scene.objects[globalId] != null) {
                    showBalloon = false
                  }
                }
              })
              //Подменяем в коллизии на новый списко элементов которые надо подсветить
              if (!showBalloon) {
                this.#_setCollisionSelectedElements(view.childElements)
              }
            }
            if (!view.hasGeometry && showBalloon) {
              showBalloon = false
              AlertService.info({ info: 'Элемент не имеет геометрии' })
            } 
            else XeokitMediator.CameraFlight.cameraFlyTo({ eye: eye, look: look, up: up, duration: 0.1 })

            if (showBalloon) {
              let pointPos = math.vec3([view.point.x, view.point.y, view.point.z])
              let collision = store.getters[this.collisionTable + 'getCollision'](this.#_collisionSelectedGlobalIds.collisionUuid)
              if (collision && collision != null) {
                CollisionBimAnnotations.showCollisionBimAnnotation(collision, pointPos)
              }
            }
            if (showBalloon && error !== '') {
              AlertService.error({ data: { error_description: error } })
            }
          } 
          else {
            let aabb = this.#_scene.getAABB(this.#_collisionSelectedElements)
            XeokitMediator.CameraFlight.cameraFlyTo(aabb)
            if (showBalloon && error !== '') {
              AlertService.error({ data: { error_description: error } })
            }
          }

          if (this.#_showIntersection) {
            this.#_loadGeometryCollisionIntersect(this.#_collisionSelectedGlobalIds.collisionUuid).then((geom) => {
              if (geom) {
                if (this.#_intersectionModel) {
                  this.destroyIntersectionModel(this.#_intersectionModel)
                }

                let model = this.loadElement(this.#_collisionSelectedGlobalIds.collisionUuid, geom)
                this.#_setIntersectionModel(model)

                // TODO: Элемент пересечения плохо видно, ниже один из вариантов (но хреновый)
                // this.#_scene.setObjectsOpacity([this.collisionSelectedGlobalIds.globalIdA, this.collisionSelectedGlobalIds.globalIdB], 0.5)
              } 
              else {
                this.#_setIntersectionModel(null)
              }
            })
          }
        })
      } 
      else {
        if (showBalloon && error !== '') {
          AlertService.error({ data: { error_description: error } })
        }
      }
    } // }
  }

  static colorizeSelectedCollisionElements() {
    if (this.#_globalIdA && this.#_globalIdB) {
      let { globalIdA, globalIdB, choosen: choosenId } = this.#_collisionSelectedGlobalIds
      const not_choosen = choosenId === globalIdA ? globalIdB : globalIdA

      if (not_choosen && choosenId) {
        XeokitMediator.ElementsSettings.setElementsColorized(choosenId, [0, 1, 0])
        XeokitMediator.ElementsSettings.setElementsColorized(not_choosen, [1, 1, 0])

        XeokitMediator.ElementsSettings.setElementsVisible([choosenId, not_choosen], true)

        const pickedElement = XeokitMediator.ElementsSelection.pickedElement

        let selectElements = [...XeokitMediator.ElementsSelection.selectedElements]
        if (pickedElement) {
          selectElements = [pickedElement, ...selectElements]
        }

        XeokitMediator.ElementsSettings.setElementsSelected(selectElements, false)
        XeokitMediator.ElementsSettings.setElementsHighlighted(selectElements, false)
      }
    }
    let flag = 0
    if ((this.#_globalIdA != null && !this.#_collisionSelectedElements?.includes(this.#_globalIdA)) || this.#_globalIdA == null) {
      flag++
    }
    if ((this.#_globalIdB != null && !this.#_collisionSelectedElements?.includes(this.#_globalIdB)) || this.#_globalIdB == null) {
      flag++
    }
    if (flag === 2) {
      XeokitMediator.ElementsSettings.setElementsColorized(this.#_collisionSelectedElements, [0, 1, 0])
      XeokitMediator.ElementsSettings.setElementsVisible(this.#_collisionSelectedElements, true)
    }
  }

  static collisionPanelDestroy() {
    XeokitMediator.ElementsSettings.setCollisionElementsSettings(this.#_scene.objectIds, [], false, [1, 1, 0], 0.4, 0.4)

    store.commit(this.collisionTable + 'SET_SELECTED_COLLISIONS', [])

    store.commit(this.collisionSearch + 'setCollisionSelectedElements', [])
    store.commit(this.collisionSearch + 'setCollisionSelectedElementsModel', [])
    store.commit(this.collisionSearch + 'setCollisionSelectedInfo', [])

    if (store.state.collision.search.intersectionModel) {
      this.destroyIntersectionModel(store.state.collision.search.intersectionModel)
    }
    store.commit(this.collisionSearch + 'setIntersectionModel', null)

    store.commit(this.collisionSearch + 'setCollisionSelectedGlobalIds', { globalIdA: null, globalIdB: null })

    store.dispatch(this.collisionSearch + 'setChoosenGlobalId', null)
    store.dispatch(this.collisionSearch + 'setCollisionUuid', null)

    store.commit(this.collisionSearch + 'setCollisionSelectedGeometry', null)
    store.commit(this.collisionSearch + 'setCollisionSelectedView', null)

    this.#_scene.setObjectsPickable(this.#_scene.objectIds, true)

    store.dispatch(this.collisionTable + 'selectCollisions', [])
    store.dispatch(this.collisionSearch + 'resetInDestroy')
    this.listXrayed = []
    CollisionBimAnnotations.clearCollisionBimAnnotation()

    XeokitMediator.clearColorizeModel()
  }

  static loadElement(collisionUuid, meshGeometry) {
    const positions = meshGeometry.positions
    const normals = meshGeometry.normals
    const indices = meshGeometry.indices

    const edgeMaterial = new EdgeMaterial(XeokitMediator.viewer.scene, {
      edgeColor: [0, 0, 0],
      edgeAlpha: 1.0,
      edgeWidth: 1,
    })

    const sceneModel = new SceneModel(this.#_scene, {
      id: collisionUuid,
      edges: true,
    })

    const meshId = this.generateUuid()
    sceneModel.createMesh({
      id: meshId,
      primitive: 'surface',
      positions: positions,
      normals: normals,
      indices: indices,
      color: [1, 0, 0],
      edgeMaterial: edgeMaterial,
      opacity: 1,
    })

    const entityId = this.generateUuid()
    sceneModel.createEntity({
      id: entityId,
      isObject: true,
      meshIds: [meshId],
      xrayed: true,
      selected: true,
      highlighted: true,
      edges: true,
    })

    const mesh = sceneModel._meshes[meshId]
    mesh.positions = positions
    mesh.normals = normals
    mesh.indices = indices
    mesh.edgeIndices = geometry.extraction.buildEdgeIndices(positions, indices, null)

    sceneModel.isCollisionIntersection = true
    this.#_scene._registerModel(sceneModel)
    sceneModel.finalize()

    return sceneModel
  }

  static destroyIntersectionModel(model) {
    this.#_scene._deregisterModel(model)
    model.destroy()
  }

  static generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  static onMouseclickedCollision() {
    XeokitMediator.ScenePick.deactivateDefaultMouseClickedListener()

    this.onMouseClickListener = XeokitMediator.on('mouseClick', (mouseClick) => {
      this.mouseClick = mouseClick
    })

    this.onMouseClickedListener = XeokitMediator.viewer.scene.input.on('mouseclicked', (canvasCoords) => {
      if (
        XeokitMediator.DistanceMeasurement.isDistanceMeasurementActive ||
        XeokitMediator.SectionPlanes.active ||
        XeokitMediator.RegimeMeasurement.isRegimeMeasurementActive ||
        XeokitMediator.PickCoordinateViewer.isPickCoordinateViewerActive
      ) {
        return false
      }

      let pickResult = XeokitMediator.ScenePick.pickResult({
        canvasPos: canvasCoords,
        pickSurface: true,
      })

      if (this.#_isCollisionSelected) {
        XeokitMediator.viewer.cameraControl.scene.canvas.canvas.style.cursor = 'default'

        if (pickResult) {
          const entity = pickResult.entity
          if (this.mouseClick && this.mouseClick.buttons == 1) {
            if (entity.id === this.#_globalIdA || entity.id === this.#_globalIdB) {
              store.dispatch(this.collisionSearch + 'setChoosenGlobalId', entity.id)
              XeokitMediator.ElementsSettings.setElementsColorized([this.#_globalIdA, this.#_globalIdB], [1, 1, 0])
              XeokitMediator.ElementsSettings.setElementsColorized(entity.id, [0, 1, 0])
            }
          }
        }
      } 
      else {
        if (pickResult) {
          const entity = pickResult.entity

          if (this.mouseClick && this.mouseClick.buttons == 1) {
            if (this.mouseClick.shiftKey || this.mouseClick.ctrlKey) {
              store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', entity.id)
              XeokitMediator.ElementsSelection.serialSelectElements(entity.id)
              XeokitMediator.ElementsSelection.pickElement(entity.id)
            } 
            else {
              if (XeokitMediator.ElementsSelection.selectedElements.length <= 1) {
                if (XeokitMediator.ElementsSelection.pickedElement) {
                  // Случай, когда selected не совпадал с picked во время выбора элемента - новые элементы становятся только picked,
                  // пока пользователь не выберет selected элемент
                  if (
                    entity.id != XeokitMediator.ElementsSelection.selectedElements[0] &&
                    XeokitMediator.ElementsSelection.selectedElements[0] != XeokitMediator.ElementsSelection.pickedElement
                  ) {
                    XeokitMediator.ElementsSelection.selectElements([XeokitMediator.ElementsSelection.selectedElements[0]])
                  }

                  // Случай, когда пользователь выбрал picked элемент
                  else if (XeokitMediator.ElementsSelection.pickedElement == entity.id) {
                    XeokitMediator.ElementsSelection.selectElements([])
                    store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', XeokitMediator.ElementsSelection.pickedElement)
                  } 
                  else {
                    // Случай, когда selected и picked не совпадают, а пользователь выбирает selected элемент
                    if (XeokitMediator.ElementsSelection.selectedElements[0] == entity.id) {
                      void 0
                    }

                    // Обычный случай, когда и selected и picked совпадали до выбора нового элемента
                    else {
                      store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', entity.id)
                      store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', XeokitMediator.ElementsSelection.pickedElement)
                    }

                    XeokitMediator.ElementsSelection.selectElements([entity.id])
                  }
                } 
                else {
                  XeokitMediator.ElementsSelection.selectElements([entity.id])
                  store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', entity.id)
                }
              }

              XeokitMediator.ElementsSelection.pickElement(entity.id)
            }

            if (XeokitMediator.ElementsSelection.pickedElement) {
              store.dispatch('axis/tree/fetchElementByGlobalId', XeokitMediator.ElementsSelection.pickedElement)
            } 
            else {
              store.dispatch('axis/tree/fetchElementByGlobalId', null)
            }
          }
        } 
        else {
          store.dispatch('axis/tree/fetchElementByGlobalId', null)
          if (XeokitMediator.ElementsSelection.pickedElement) {
            XeokitMediator.ElementsSelection.pickElement(null)
          }
        }
      }
    })
  }

  static offMouseclickedCollision() {
    XeokitMediator.viewer.scene.input.off(this.onMouseClickedListener)
    XeokitMediator.off(this.onMouseClickListener)
    XeokitMediator.ScenePick.activateDefaultMouseClickedListener()
  }
}
