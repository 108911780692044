<template lang="pug">
v-dialog( v-model="$_show" content-class="app-sud-dialog"
          scrollable :persistent="persistent"
          :hide-overlay="hideOverlay" :width="width"
          :internal-activator="true" )

  template( v-slot:activator="{ on }" )
    slot( v-if="$slots.activator" name="activator" v-on="on" )

  .app-dialog.surface
    .app-dialog_header
      v-layout( align-center )
        v-flex
          app-section-header( :title="header" )
        v-flex( v-if="closeButtonHidden == false" shrink )
          app-icon-button( icon="mdi-close" @click="handleClose" test-id="close-app-panel" )

    slot( v-if="$slots.prepend" name="prepend" )

    .app-dialog__body( test-id="dialog-window-for-select-item"
                       :class="{ 'app-dialog_body__complex' : complex }"
                      )
      slot
        app-list.base-scroll-body(v-if="items" :items="items" @select="handleItem" style="border-radius: 5px; max-height: 500px;" )
      slot( name="footer" )
        .app-dialog__footer( v-if="confirmText" :class="{ 'app-dialog__footer_complex' : complex }" )
          .d-inline-block.text-none.app-dialog_confirm-action.mr-4.pointer( v-if="cancelText" color="accent" large depressed @click="handleClose" ) {{ cancelText }}
          app-button( cancel @click="handleClose" ) {{ $t('module.process.cancel') }}
          app-button.ml-2( action :disabled="confirmDisabled" @click="handleConfirm" test-id="dialog-add-button" ) {{ $t(confirmText) }}
</template>

<script>
import DialogToggable from './DialogToggable'
import AppList from '@/components/project/common/List.vue'

export default {
  mixins: [DialogToggable],
  name: 'app-dialog',

  components: {
    AppList
  },

  props: {
    header: { type: String, default: '' },
    closeButtonHidden: Boolean,
    width: { type: [String, Number], default: 378 },
    withScrollPanel: { type : Boolean, default: false },
    confirmText: String,
    cancelText: String,
    confirmDisabled: Boolean,
    items: Array,
    persistent: { type: Boolean, default: true },
    hideOverlay: { type: Boolean, default: false },
    closeOnConfirm: { type: Boolean, default: true },
    scroll: Boolean,
    complex: { type: Boolean, default: false }
  },

  methods: {
    handleConfirm () {
      if (this.closeOnConfirm) this.$_show = false
      this.$emit('confirm')
    },

    handleItem (item, index) {
      this.$_show = false
      this.$emit('select', item, index)
    },
    
    handleClose () {
      this.$_show = false
      this.$emit('close')
    }
  }
}
</script>

<style>
.v-dialog {
  overflow: hidden !important;
}
</style>

<style lang="scss" scoped>
.app-dialog_header {
  padding: 8px 4px 8px 8px;
  font: normal 14px/12px $slab;
}
.app-dialog__header_close {
  outline: none !important;
}
.app-dialog__body {
  background-color: white !important;
  padding: 11px 8px !important;
}

.app-dialog_body__complex {
  background-color: var(--v-surface-lighten1) !important;
  padding: 0px 4px 4px 4px !important;
}
.app-dialog__footer {
  text-align: right;
  padding: 10px;
}
.app-dialog_confirm-action {
  margin: 0px;
  font: normal 14px/18px $roboto;
  color: #FFFFFF;
}

.app-dialog__footer_complex {
  background-color: white;
}
</style>
