import { api } from "@/api"
import { App } from '@app/App'

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

export default {
  namespaced: true,

  state: {
    workspaces: {
      list: [],
      status: status.INITIAL
    },

    currentProjectUuid: null
  },

  getters: {
    workspaces: ({ workspaces }) => workspaces.list.filter(el => !el.archived),
    workspacesAll: ({ workspaces }) => [...workspaces.list.filter(el => !el.archived), ...workspaces.list.filter(el => el.archived)],
    isWorkspacesLoaded: ({ workspaces }) => workspaces.status == status.LOADED,
  },

  mutations: {

    setCurrentProjectUuid: (state, id) => { state.currentProjectUuid = id },

    setWorkspaces: (state, list) => { state.workspaces.list = list },
    setWorkspacesStatus: (state, status) => { state.workspaces.status = status },

    updateWorkspace: ({ workspaces }, ws) => {
      let exist = false
      if(workspaces.list.length > 0) { 
        let curr = workspaces.list.find(item => item.uuid === ws.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, ws)
        }
      } 
      if(!exist) workspaces.list.push(ws)
    },

    deleteWorkspace: ({ workspaces }, id) => {
      let index = workspaces.list.findIndex(item => item.uuid === id)
      workspaces.list.splice(index, 1)
    },


  },

  actions: {
    INIT: ({ dispatch }) => {
      dispatch("LOAD_WORKSPACES")
    },

    LOAD_WORKSPACES: ({ commit, rootGetters }) => {
      if (!App.version.isCloud) {
        commit('setWorkspacesStatus', status.LOADING)
        return api.workspace.workspaces(rootGetters['project/projectUuid']).then(data => {
          commit('setWorkspaces', data)
          commit('setWorkspacesStatus', status.LOADED)
          return data
        })
      } 
    },

    saveWorkspace: ({ commit }, obj) => {
      return api.workspace.saveWorkspace(obj).then(data => {
        commit('updateWorkspace', data)
        return data
      })
    },

    deleteWorkspace: ({ dispatch }, id) => {
      console.log(id);
      api.workspace.deleteWorkspace(id).then(() => {
        dispatch("LOAD_WORKSPACES")
      })
    },

    restoreWorkspace: ({ dispatch }, id) => {
      api.workspace.restoreWorkspace(id).then(() => {
        dispatch("LOAD_WORKSPACES")
      })
    },

    changeWorkSpace: ({ dispatch, rootGetters, getters }, id) => {
      if (id == null || getters.workspaces.find(el => el.uuid == id)) {
        let ps = rootGetters['project/projectSettings']
        if (ps.workSpace == id) return
        ps.workSpace = id
        return dispatch('project/updateSettings', ps, {root: true})
      } 
    },
    
  }
}
