import { SmetaMergedElementsCache } from "@/assets/model/smeta/merge/SmetaMergedElementsCache"
import { api } from "@/api"
import { SmetaMergedElement } from "@/assets/model/smeta/merge/SmetaMergedElement"

export default {
  namespaced: true,
  state: {
    mergedItemsLength: 0,
    isClassificatorUpdating: false,
    filters: [],
    currentMerge: null,
    smetaMergeSearchText: null,
  },

  getters: {
    // MERGED ELEMENTS CACHE
    getMergedElement: () => uuid => smetaMergedCache.getElement(uuid),
    getMergedElements: (state) => (from, to) => {
      return smetaMergedCache.fillCache(from, to, state.filters, state.smetaMergeSearchText)
    },
    getMergedStates: () => smetaMergedCache.statesCount,
  },

  mutations: {
    setClassificatorUpdatingFlag: (state, flag) => {
      state.isClassificatorUpdating = flag
    },

    setFilters: (state, filters) => {
      if (!filters.length) state.mergedItemsLength = smetaMergedCache.getMapSize()
      else state.mergedItemsLength = filters.reduce((acc, curr) => (acc + smetaMergedCache.statesCount[curr]), 0)
      state.filters = filters
    },

    setMergeItemsLength: (state, len) => {
      state.mergedItemsLength = len
    },

    setSmetaMergeSearchText: (state, value) => {
      state.smetaMergeSearchText = value
    }
  },

  actions: {
    // TODO: Переделать, так как фильтровать не совсем верно
    fetchMergedElements({ state }, { from, to }) {
      smetaMergedCache.fillCache(from, to, state.filters)
    },

    toggleNode({ state }, itemKey) {
      state
      smetaMergedCache.toggleNode(itemKey)
    },

    expandAll() {
      smetaMergedCache.expandAll()
    },

    collapseAll() {
      smetaMergedCache.collapseAll()
    },

    applyAcceptance({ state }, itemKey) {
      state
      smetaMergedCache.applyAcceptance(itemKey)
    },

    expandItem({ state }, itemKey) {
      state
      smetaMergedCache.expandItem(itemKey)
    },

    mergeVersions({ commit, state }, newVersion) {
      commit('setClassificatorUpdatingFlag', true)
      state.currentMerge = newVersion
      return api.smeta.mergeClassificator(newVersion).then(data => {
        commit('setClassificatorUpdatingFlag', false)
        smetaMergedCache.setList(data)
        state.mergedItemsLength = SmetaMergedElement.rootElements
      }).catch(error => {
        console.log(error)
        commit('setClassificatorUpdatingFlag', false)
      })
    },

    applyMergeVersions({ commit, state }) {
      commit('setClassificatorUpdatingFlag', true)
      let items = Array.from(smetaMergedCache.map.values()).filter(item => item.accepted).map(item => item.element)
      let applyMerge = { ...state.currentMerge, items: items }
      return api.smeta.applyMergeClassificator(applyMerge).then(() => {
        commit('setClassificatorUpdatingFlag', false)
        smetaMergedCache = new SmetaMergedElementsCache
        this.mergedItemsLength = 0
        this.isClassificatorUpdating = false
        this.filters = []
        this.currentMerge = null
        this.smetaMergeSearchText = null
      }).catch(error => {
        console.log(error)
        commit('setClassificatorUpdatingFlag', false)
      })
    }
  }
}

let smetaMergedCache = new SmetaMergedElementsCache

