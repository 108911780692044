import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { MeasurementTool } from '../measurement/measurementTool'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

/*eslint-disable no-dupe-class-members*/
export class EdgeToEdgeMeasurement {
  static #_originPickedEdge = null
  static #_targetPickedEdge = null

  static #_hoveringEdge = null

  static #_onMouseHoverSurface = null
  static #_onMouseHoverOut = null
  static #_onSceneTick = null
  static #_onInputMouseUp = null
  static #_onInputMouseDown = null
  static #_onInputKeyDown = null

  static #_mouseCanvasClickTolerance = 5
  static #_mouseDownCanvasX
  static #_mouseDownCanvasY
  static #_mouseDownLeft = false
  static #_mouseDownRight = false

  static pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedWorldPos = pickResult.worldPos

    if (pickResult.isSectionControl) {
      return
    }
    if (pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')) {
      return
    }

    let edges = []
    pickedEntity.meshes.forEach((mesh) => {
      if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
    })
    const nearestEdgeData = geometry.nearestCoordFinder.findNearestPointOnEdgeToPoint(edges, pickedWorldPos)

    if (nearestEdgeData) {
      if (!this.#_originPickedEdge) {
        this.#_originPickedEdge = XeokitMediator.SceneObjects.createSegmentMesh({
          segmentPositions: nearestEdgeData.edge,
        })
      } 
      else {
        this.#_destroyTargetPickedEdge()
        this.#_targetPickedEdge = XeokitMediator.SceneObjects.createSegmentMesh({
          segmentPositions: nearestEdgeData.edge,
        })

        const originEdge = [this.#_originPickedEdge.originWorldPos, this.#_originPickedEdge.targetWorldPos]

        const measurement = geometry.edgeToEdgeMeasurement.findShortestSegmentBetweenSegments(originEdge, nearestEdgeData.edge)

        this.destroyMeasurement()
        MeasurementTool.createDistanceMeasurement(measurement[0], measurement[1])
      }
    }
  }

  static #_drawHoveringEdge(edge) {
    this.#_hoveringEdge = XeokitMediator.SceneObjects.createSegmentMesh({
      segmentPositions: edge,
    })
  }

  static #_destroyHoveringEdge() {
    this.#_hoveringEdge?.destroy()
    this.#_hoveringEdge = null
  }

  static destroyMeasurement() {
  }

  static clearAll() {

    this.#_destroyOriginPickedEdge()
    this.#_destroyTargetPickedEdge()
  }

  static #_destroyOriginPickedEdge() {
    this.#_originPickedEdge?.destroy()
    this.#_originPickedEdge = null
  }

  static #_destroyTargetPickedEdge() {
    this.#_targetPickedEdge?.destroy()
    this.#_targetPickedEdge = null
  }

  static activate() {
    this.#_activateInputListeners()
    this.#_activateHoverSurfaceListener()
  }

  static #_activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#_onInputMouseDown)
    input.off(this.#_onInputMouseUp)

    this.#_onInputMouseDown = input.on('mousedown', (coords) => {
      this.#_mouseDownCanvasX = coords[0]
      this.#_mouseDownCanvasY = coords[1]
      this.#_mouseDownLeft = input.mouseDownLeft
      this.#_mouseDownRight = input.mouseDownRight
    })

    this.#_onInputMouseUp = input.on('mouseup', (coords) => {

      if (
        coords[0] > this.#_mouseDownCanvasX + this.#_mouseCanvasClickTolerance ||
        coords[0] < this.#_mouseDownCanvasX - this.#_mouseCanvasClickTolerance ||
        coords[1] > this.#_mouseDownCanvasY + this.#_mouseCanvasClickTolerance ||
        coords[1] < this.#_mouseDownCanvasY - this.#_mouseCanvasClickTolerance
      ) {
        this.#_mouseDownLeft = false
        this.#_mouseDownRight = false
        return
      }

      if (this.#_mouseDownLeft) {
        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
        })

        if (pickResult) {
          this.pick(pickResult)
        }
      }
      
      this.#_mouseDownLeft = false
      this.#_mouseDownRight = false
    })

    this.#_onInputKeyDown = input.on('keydown', (keyCode) => {
      if (keyCode == input.KEY_N) {
        this.clearAll()
      }
    })
  }

  static #_activateHoverSurfaceListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl

    this.#_onMouseHoverSurface = cameraControl.on('hoverSurface', (event) => {

      if (this.#_mouseDownLeft || this.#_mouseDownRight) {
        this.#_destroyHoveringEdge()
        return
      }

      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
      })

      if (!pickResult) {
        return
      }

      if (pickResult.isSectionControl) {
        return
      }

      if (!pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) {
        let edges = []
        pickResult.entity.meshes.forEach((mesh) => {
          if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
        })

        const nearestEdgeData = geometry.nearestCoordFinder.findNearestPointOnEdgeToPoint(edges, pickResult.worldPos)
        this.#_destroyHoveringEdge()
        this.#_drawHoveringEdge(nearestEdgeData.edge)
      }
    })

    this.#_onMouseHoverOut = cameraControl.on('hoverOff', () => {
      this.#_destroyHoveringEdge()
    })
  }

  static deactivate() {
    const input = XeokitMediator.viewer.scene.input
    const cameraControl = XeokitMediator.viewer.cameraControl
    const scene = XeokitMediator.viewer.scene

    input.off(this.#_onInputMouseDown)
    input.off(this.#_onInputMouseUp)
    input.off(this.#_onInputKeyDown)
    cameraControl.off(this.#_onMouseHoverSurface)
    cameraControl.off(this.#_onMouseHoverOut)
    scene.off(this.#_onSceneTick)

    this.#_destroyHoveringEdge()
    this.#_destroyOriginPickedEdge()
    this.#_destroyTargetPickedEdge()
  }
}
