import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { XeokitDistanceMeasurementsPlugin } from "@/plugins/viewerTools/tools/distanceMeasurement/XeokitDistanceMeasurementsPlugin";
import { math } from "@xeokit/xeokit-sdk";
import "./distanceMeasurementStyle.scss"

/*eslint-disable no-dupe-class-members*/
export class DistanceMeasurement {

  /**
   * @type { XeokitDistanceMeasurementsPlugin }
   */
  static #_distanceMeasurementsPlugin = null

  static get measurementsDirty() {
    return this.#_distanceMeasurementsPlugin?._measurements || {}
  }

  static createDistanceMeasurement(originWorldPos, targetWorldPos, visible = true) {
    this.#_distanceMeasurementsPlugin.createMeasurement({
      id: math.createUUID(),
      origin: {
        worldPos: originWorldPos,
      },
      target: {
        worldPos: targetWorldPos,
      },
      visible: visible,
      wireVisible: true,
    });
  }

  static createFullDistanceMeasurement(params) {
    this.#_distanceMeasurementsPlugin.createMeasurement(params)
  }

  static updateAllDistanceMeasurement() {
    this.#_distanceMeasurementsPlugin.updateAllDistanceMeasurement()
  }

  static removeDistanceMeasurements(distanceMeasurements = []) {
    this.#_distanceMeasurementsPlugin.removeDistanceMeasurements(distanceMeasurements)
  }

  static clearAllMeasurement() {
    this.#_distanceMeasurementsPlugin.clearAllMeasurement()
  }

  static setDistanceMeasurementsVisible(distanceMeasurements = [], visible) {
    this.#_distanceMeasurementsPlugin.setDistanceMeasurementsVisible(distanceMeasurements, visible)
  }

  static init() {
    this.#_distanceMeasurementsPlugin = new XeokitDistanceMeasurementsPlugin(
      XeokitMediator.viewer,
      { container: document.getElementById("measurementsScreen") }
    );
  }

  static activate() {
    XeokitMediator.viewer.scene.camera.ortho.far = 5000
    XeokitMediator.viewer.scene.camera.ortho.near = 0

    this.#_distanceMeasurementsPlugin.control.activate();
  }

  static deactivate() {
    XeokitMediator.viewer.scene.camera.ortho.far = 20000
    XeokitMediator.viewer.scene.camera.ortho.near = -2000

    this.#_distanceMeasurementsPlugin?.control?.deactivate();
    // this.#_distanceMeasurementsPlugin?.clear();
    // this.#_distanceMeasurementsPlugin?.destroy();
  }

}