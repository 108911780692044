import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { ThreeMediator } from '@/plugins/threeJs/ThreeMediator';
import { fonts } from '@/plugins/threeJs/plugins/fonts/fonts';
import { math } from "@xeokit/xeokit-sdk";
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";
import store from '@/store'

/*eslint-disable no-dupe-class-members*/
export class PickCoordinateViewer {

  static #_labelMeshes = null
  static #_meshes = null
  static #_font = null
  static #_isShiftKeyPressed = false
  static #_onShiftKeyDown = () => {}
  static #_onShiftKeyUp = () => {}

  static get #_viewer() {
    return XeokitMediator.viewer
  }

  static #_showPickCoordinates(worldPos = [0, 0, 0], worldPosView) {
    if ( worldPosView === undefined ) return

    let coordXView = parseFloat(worldPosView[0]).toFixed(3)
    let coordYView = parseFloat(worldPosView[1]).toFixed(3)
    let coordZView = parseFloat(worldPosView[2]).toFixed(3)
    let textXYZ = ['X(' + coordXView + ')', 'Y(' + coordYView + ')', 'Z(' + coordZView + ')']

    this.#_getPickCoordinates(textXYZ, worldPos)
  }

  static #_destroyMeshes() {
    if (this.#_meshes != null) {
      this.#_meshes.destroy()
      this.#_meshes = null
    }
    if (this.#_labelMeshes != null) {
      this.#_labelMeshes.destroy()
      this.#_labelMeshes = null
    }
  }

  static #_unbindEvents(){
    this.#_viewer.scene.off(this._onSceneTick);
  }

  static #_createPickCoordinateLabelMeshes(textXYZ, scale, worldPos) {
    const font = this.#_font

    const labels = new (function() {
      const labelX = ThreeMediator.SceneObjects.createFontText({
        billboard: 'spherical',
        text: textXYZ[0],
        font: font,
        position: worldPos,
        offset: [0.95 * scale[0], 0, 0],
        scale: scale[0] / 7,
        color: [1.0, 0.0, 0.0]
      })

      const labelY = ThreeMediator.SceneObjects.createFontText({
        billboard: 'spherical',
        text: textXYZ[1],
        font: font,
        position: worldPos,
        offset: [0, 0.95 * scale[0], 0],
        scale: scale[0] / 7,
        color: [0.0, 1.0, 0.0]
      })

      const labelZ = ThreeMediator.SceneObjects.createFontText({
        billboard: 'spherical',
        text: textXYZ[2],
        font: font,
        position: worldPos,
        offset: [0, 0, 0.95 * scale[0]],
        scale: scale[0] / 7,
        color: [0.0, 0.0, 1.0]
      })

      // const labelX = XeokitMediator.SceneObjects.createTextMesh({
      //   text: textXYZ[0],
      //   worldPos: worldPos,
      //   offset: [0.95 * scale[0], 0, 0],
      //   size: scale[0] / 7.5,
      //   scale: scale,
      //   billboard: "spherical",
      //   color: [1.0, 0.0, 0.0]
      // })

      // const labelY = XeokitMediator.SceneObjects.createTextMesh({
      //   text: textXYZ[1],
      //   worldPos: worldPos,
      //   offset: [0, 0.95 * scale[0], 0],
      //   size: scale[0] / 7.5,
      //   scale: scale,
      //   billboard: "spherical",
      //   color: [0.0, 1.0, 0.0]
      // })

      // const labelZ = XeokitMediator.SceneObjects.createTextMesh({
      //   text: textXYZ[2],
      //   worldPos: worldPos,
      //   offset: [0, 0, 0.95 * scale[0]],
      //   size: scale[0] / 7.5,
      //   scale: scale,
      //   billboard: "spherical",
      //   color: [0.0, 0.0, 1.0]
      // })

      this.destroy = function() {
        labelX.destroy()
        labelY.destroy()
        labelZ.destroy()
      }
    })
    
    return labels
  }

  static #_createPickCoordinateMeshes(scale, worldPos){
  
    const meshes = new (function() {
      const center = XeokitMediator.SceneObjects.createSphereMesh({
        radius: .03,
        worldPos: worldPos,
        scale: scale,
        color: [0.5, 0.5, 0.5],
        isPositionScaling: true
      })

      const arrowX = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.01,
        radiusBottom: 0.04,
        height: .2,
        worldPos: worldPos,
        offset: [0.75, 0, 0],
        scale: scale,
        rotation: [0, 0, -90],
        color: [1.0, 0.0, 0.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      })

      const shaftX = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.01,
        radiusBottom: 0.01,
        height: 0.8,
        worldPos: worldPos,
        offset: [0.4, 0, 0],
        scale: scale,
        rotation: [0, 0, -90],
        color: [1.0, 0.0, 0.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      })

      const arrowY = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.01,
        radiusBottom: 0.04,
        height: .2,
        worldPos: worldPos,
        offset: [0, 0.75, 0],
        scale: scale,
        color: [0.0, 1.0, 0.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      })

      const shaftY  = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.01,
        radiusBottom: 0.01,
        height: 0.8,
        worldPos: worldPos,
        offset: [0, 0.4, 0],
        scale: scale,
        color: [0.0, 1.0, 0.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      })

      const arrowZ = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.01,
        radiusBottom: 0.04,
        height: .2,
        worldPos: worldPos,
        offset: [0, 0, 0.75],
        scale: scale,
        rotation: [90, 0, 0],
        color: [0.0, 0.0, 1.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      }) 

      const shaftZ = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.01,
        radiusBottom: 0.01,
        height: 0.8,
        worldPos: worldPos,
        offset: [0, 0, 0.4],
        scale: scale,
        rotation: [90, 0, 0],
        color: [0.0, 0.0, 1.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      })

      this.updateScale = function(scale) {
        center.update({scale: scale})
        arrowX.update({scale: scale, isPositionScaling: true})
        shaftX.update({scale: scale, isPositionScaling: true})
        arrowY.update({scale: scale, isPositionScaling: true})
        shaftY.update({scale: scale, isPositionScaling: true})
        arrowZ.update({scale: scale, isPositionScaling: true})
        shaftZ.update({scale: scale, isPositionScaling: true})
      }

      this.destroy = function() {
        center.destroy()
        arrowX.destroy()
        shaftX.destroy()
        arrowY.destroy()
        shaftY.destroy()
        arrowZ.destroy()
        shaftZ.destroy()
      }
    })

    return meshes
  }

  /** Отображение указанной координаты на сцене
   * 
   * @param {Object} cfg Настройки поиска
   * @param {Array<Number>} [cfg.worldPos = [0, 0, 0]] Отображаемая координата
   * @param {Object} [cfg.entity] Entity выбранного элемента
  */
  static #_showCoordinates (cfg) {
    const worldPos = cfg.worldPos
    const xktVersion = cfg.entity.xktVersion
    const corrected = this.#_correctionByXktVersion(worldPos, xktVersion)
    const worldPosView = corrected[1]
    
    XeokitMediator.PickCoordinateViewer.setPickCoordinates(worldPosView, xktVersion)

    this.#_unbindEvents()
    this.#_showPickCoordinates(worldPos, worldPosView)
  }

  static #_getPickCoordinates(textXYZ, worldPos) {
    let scene = this.#_viewer.scene
    let camera = this.#_viewer.camera
    const tempVec3a = math.vec3();

    math.subVec3(scene.camera.eye, worldPos, tempVec3a)
    const dist = Math.abs(math.lenVec3(tempVec3a));
    const worldSize = (Math.tan(camera.perspective.fov * math.DEGTORAD)) * dist;
    const size = 0.07 * worldSize;
    const scale = [size, size, size]
    
    this.#_meshes = this.#_createPickCoordinateMeshes(scale, worldPos);
    this.#_labelMeshes = this.#_createPickCoordinateLabelMeshes(textXYZ, scale, worldPos);

    let lastDist = -1;
    this._onSceneTick = scene.on("tick", () => {

      math.subVec3(scene.camera.eye, worldPos, tempVec3a)
      const dist = Math.abs(math.lenVec3(tempVec3a));

      if ( dist !== lastDist ) {
        if ( camera.projection === "perspective" ) {
          if ( this.#_labelMeshes != null ) {
            this.#_labelMeshes.destroy()
          }

          const worldSize = (Math.tan(camera.perspective.fov * math.DEGTORAD)) * dist;
          const size = 0.07 * worldSize;
          const scale = [size, size, size]

          if ( this.#_meshes != null ) {
            this.#_meshes.updateScale(scale)
          }
          
          this.#_labelMeshes = this.#_createPickCoordinateLabelMeshes(textXYZ, scale, worldPos);
          lastDist = dist;
        }
      }

      if ( camera.projection === "ortho" ) {
        if ( this.#_labelMeshes != null ) {
          this.#_labelMeshes.destroy()
        }

        const size = camera.ortho.scale / 12;
        const scale = [size, size, size]

        if ( this.#_meshes != null ) {
          this.#_meshes.updateScale(scale)
        }
        this.#_labelMeshes = this.#_createPickCoordinateLabelMeshes(textXYZ, scale, worldPos);
        lastDist = dist;
      }
    });
    
  }

  static activate() {
    XeokitMediator.viewer.scene.camera.ortho.far = 3000
    XeokitMediator.viewer.scene.camera.ortho.near = 0

    this.#_font = ThreeMediator.fontLoader.parse(fonts.RobotoMediumRegular)

    document.addEventListener("keydown", this.#_onShiftKeyDown = (event) => {
      if (event.shiftKey) {
        this.#_isShiftKeyPressed = true
      }
    })

    document.addEventListener("keyup", this.#_onShiftKeyUp = () => {
      if (this.#_isShiftKeyPressed) {
        this.#_isShiftKeyPressed = false
      }
    })

    this.onInputMouseMove = XeokitMediator.viewer.scene.input.on("mousemove", canvasPos => {
      if (this.#_isShiftKeyPressed) {

        XeokitMediator.PickCoordinateViewer.setPickCoordinates([])
        this.#_destroyMeshes()

        let nearestCoordResult = geometry.nearestCoordFinder.getNearestCoordOnEdgeOrVertexByCanvasPos({
          canvasPos: canvasPos,
          nearestDistance: 0.02,
          collisionDetect: true
        })

        if (nearestCoordResult) this.#_showCoordinates(nearestCoordResult)
      }
    });

    XeokitMediator.viewer.scene.canvas.canvas.addEventListener('mousedown', this.canvasMouseDown = (event) => {
      const isLeftMouseClick = event.which === 1
      const step = 10

      if (!isLeftMouseClick) {
        return
      }

      let startX = event.layerX
      let startY = event.layerY

      XeokitMediator.viewer.scene.canvas.canvas.addEventListener('mouseup', (eve) => {
        const canvasCoords = [eve.layerX, eve.layerY]

        if (eve.layerX > startX + step || eve.layerX < startX - step || eve.layerY > startY + step || eve.layerY < startY - step) {
          return
        }
        
        let pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: canvasCoords,
          pickSurface: true
        });
        if (pickResult && pickResult.entity) {
          // geometry.drawPoint(pickResult.worldPos)
          this.#_destroyMeshes()
          this.#_showCoordinates({
            worldPos: pickResult.worldPos,
            entity: pickResult.entity
          })
        }
        else {
          XeokitMediator.PickCoordinateViewer.setPickCoordinates([])
          this.#_destroyMeshes()
        }
      }, { once: true })
    })
  }

  static deactivate() {
    XeokitMediator.viewer.scene.camera.ortho.far = 20000
    XeokitMediator.viewer.scene.camera.ortho.near = -2000

    document.removeEventListener("keydown", this.#_onShiftKeyDown)
    document.removeEventListener("keyup", this.#_onShiftKeyUp)

    XeokitMediator.viewer.scene.input.off(this.onInputMouseMove)
    XeokitMediator.viewer.scene.input.off(this.onInputMouseClick)

    XeokitMediator.viewer.scene.canvas.canvas.removeEventListener('mousedown', this.canvasMouseDown)

    XeokitMediator.PickCoordinateViewer.setPickCoordinates([])
    this.#_destroyMeshes()
    this.#_unbindEvents()
  }

  /**Создать координатную точку на сцене в указанной позиции.
   * 
   * @param {Array<Number>} coords Позиции координатной точки
   * @param {String} xktVersion Версия xkt модели, на которой была установлена точка
   */
  static createCoordinatePoint(coords = [0, 0, 0], xktVersion = '') {
    let corrected = this.#_correctionByXktVersion(coords, xktVersion)
    const worldPos = corrected[0]
    const worldPosView = corrected[1]
    this.#_showPickCoordinates(worldPos, worldPosView)
  }

  /**Уничтожить текущую координатную точку 
   * 
   */
  static destroyCoordinatePoint() {
    this.#_destroyMeshes()
    this.#_unbindEvents()
  }

  static #_correctionByXktVersion(coords, xktVersion) {
    switch (xktVersion) {
      case "10":
        return [coords, coords];

      case "3": {
        const project = store.state.project.project
        let coordXView = coords[0] + project.offsetX;
        let coordYView = coords[1] + project.offsetY;
        let coordZView = coords[2] + project.offsetZ;
        let worldPosView = [coordXView, coordYView, coordZView]
        return [coords, worldPosView]
      }

      default:
        return [coords, coords];
    }
  }
}