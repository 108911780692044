import { Mesh, ReadableGeometry, PhongMaterial, EmphasisMaterial } from "@xeokit/xeokit-sdk";
import { ShapeGeometry } from '@/plugins/threeJs/fork/src/geometries/shapeGeometry';
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";

export class SceneObjects {
  
  /** Создать на сцене текст с указанным шрифтом 
   * @param {Object} cfg Конфиг
   * @param {Object} cfg.font Шрифт, загруженный через FontLoader 
   * @param {Array<Number>} cfg.position Точка, с которой начнется отрисовка текста
   * @param {Array<Number>} cfg.color Цвет текста
   * @param {Number} cfg.scale Масштабирование текста 
   * @param {String} cfg.billboard Положение относительно камеры
   * @param {String} cfg.text Текст
   * @param {Boolean} cfg.selected Просвечивание сквозь другие объекты
   */
  static createFontText(cfg) {
    if (!cfg.font) {
      console.log("Font is undefined")
      return
    }
    const text = cfg.text ?? '???'
    const position = cfg.position ?? [0, 0, 0]
    const origin = position
    const font = cfg.font
    const color = cfg.color ?? [1, 1, 1]
    const scale = cfg.scale ?? 0.01
    const selected = cfg.selected ?? false
    const billboard = cfg.billboard ?? 'none'
    const offset = cfg.offset ?? [0, 0, 0]
    
    const shapes = font.generateShapes( text, scale );

    const shapeGeometry = new ShapeGeometry( shapes, 4 ) 

    const normals = new Array(shapeGeometry.attributes.position.array.length).fill(1.0)

    const xktGeometry = {
      primitive: "triangles",
      positions: Array.from(shapeGeometry.attributes.position.array),
      indices: Array.from(shapeGeometry.index.array),
      normals: Array.from(normals),
      // compressGeometry: true,
    }

    const readableGeometry = new ReadableGeometry(XeokitMediator.viewer.scene, xktGeometry)

    const selectedMaterial = new EmphasisMaterial(XeokitMediator.viewer.scene, {
      edges: false,
      fill: true,
      fillColor: color,
      fillAlpha: 1,
    })

    const material = new PhongMaterial(XeokitMediator.viewer.scene, {
      emissive: color,
      diffuse: color,
      ambient: [0.0, 0.0, 0.0],
      specular: [0.6, 0.6, 0.3],
      shininess: 80,
      lineWidth: 1,
    })
    
    const textMesh = new (function () {
      const text = new Mesh(XeokitMediator.viewer.scene, {
        origin: origin,
        position: offset,
        geometry: readableGeometry,
        material: material,
        pickable: false,
        collidable: false,
        visible: true,
        selectedMaterial: selectedMaterial,
        billboard: billboard,
        selected: selected
      })

      this.destroy = function () {
        text.destroy()
      }
    })()

    return textMesh
  }
}