export class AxisNode {

  constructor (node, level = 0, parent = null) {
    this.uuid = node.uuid
    this.extraId = node.extraId
    this.globalId = node.globalId
    
    this.oname = node.name || node.longName || node.classTitle
    
    this.level = level
    this.parent = parent
    
    this.children = node.children.map(item => new AxisNode(item, level + 1, this))
    this.numberOfEndpoints = this.children.reduce(countNumberOfEndpoints, 0)
    this.isPenult = this.children.every(child => child.children.length == 0)
    
    this.#_selected = node.selected ?? false
    this.countCollisions = node.countCollisions
    this.countFullCollisions = node.countFullCollisions
  }

  // * Private

  #_selected

  // * Computed 

  get id () {
    return this.extraId
  }

  get selected () {
    return this.#_selected
  }

  get name () {
    return this.isOpenable ? `${this.endPointCount} - ${this.oname}` : this.oname
    // return this.oname
  }

  get isNode () {
    return this.children.length > 0
  }

  get isEndpoint () {
    return !this.isNode
  }

  get collisionStats () {
    return `${this.countCollisions} / ${this.countFullCollisions}`
  }

  get nodePath () {
    return this.parent?.nodePath ? [...this.parent.nodePath, this.parent] : []
  }
}

function countNumberOfEndpoints (total, item) {
  let count = item.isNode ? item.numberOfEndpoints : 1
  return total + count
}