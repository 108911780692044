import { Measurement } from './measurement'
import { Plugin } from '@xeokit/xeokit-sdk'
import { math } from '@xeokit/xeokit-sdk'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export class MeasurementsPlugin extends Plugin {

  /**
   * @constructor
   * @param {Viewer} viewer The Viewer.
   * @param {Object} [cfg]  Plugin configuration.
   * @param {String} [cfg.id="DistanceMeasurements"] Optional ID for this plugin, so that we can find it within {@link Viewer#plugins}.
   * @param {Number} [cfg.labelMinAxisLength=25] The minimum length, in pixels, of an axis wire beyond which its label is shown.
   * @param {HTMLElement} [cfg.container] Container DOM element for markers and labels. Defaults to ````document.body````.
   * @param {boolean} [cfg.defaultVisible=true] The default value of the DistanceMeasurements `visible` property.
   * @param {boolean} [cfg.defaultOriginVisible=true] The default value of the DistanceMeasurements `originVisible` property.
   * @param {boolean} [cfg.defaultTargetVisible=true] The default value of the DistanceMeasurements `targetVisible` property.
   * @param {boolean} [cfg.defaultWireVisible=true] The default value of the DistanceMeasurements `wireVisible` property.
   * @param {boolean} [cfg.defaultLabelsVisible=true] The default value of the DistanceMeasurements `labelsVisible` property.
   * @param {boolean} [cfg.defaultAxisVisible=true] The default value of the DistanceMeasurements `axisVisible` property.
   * @param {boolean} [cfg.defaultXAxisVisible=true] The default value of the DistanceMeasurements `xAxisVisible` property.
   * @param {boolean} [cfg.defaultYAxisVisible=true] The default value of the DistanceMeasurements `yAxisVisible` property.
   * @param {boolean} [cfg.defaultZAxisVisible=true] The default value of the DistanceMeasurements `zAxisVisible` property.
   * @param {string} [cfg.defaultColor=#00BBFF] The default color of the length dots, wire and label.
   * @param {number} [cfg.zIndex] If set, the wires, dots and labels will have this zIndex (+1 for dots and +2 for labels).
   *
   */
  constructor(viewer, cfg = {}) {

    super("RegimeMeasurements", viewer);

    this._container = cfg.container || document.body;

    this._measurements = {};

    this.labelMinAxisLength = cfg.labelMinAxisLength;

    this.defaultVisible = cfg.defaultVisible !== false;
    this.defaultOriginVisible = cfg.defaultOriginVisible !== false;
    this.defaultTargetVisible = cfg.defaultTargetVisible !== false;
    this.defaultWireVisible = cfg.defaultWireVisible !== false;
    this.defaultLabelsVisible = cfg.defaultLabelsVisible !== false;
    this.defaultAxisVisible = cfg.defaultAxisVisible !== false;
    this.defaultXAxisVisible = cfg.defaultXAxisVisible !== false;
    this.defaultYAxisVisible = cfg.defaultYAxisVisible !== false;
    this.defaultZAxisVisible = cfg.defaultZAxisVisible !== false;
    this.defaultColor = cfg.defaultColor !== undefined ? cfg.defaultColor : "#00BBFF";
    this.zIndex = cfg.zIndex || 10000;

    this._onMouseOver = (event, measurement) => {
      this.fire("mouseOver", {
        plugin: this,
        distanceMeasurement: measurement,
        measurement,
        event
      });
    };

    this._onMouseLeave = (event, measurement) => {
      this.fire("mouseLeave", {
        plugin: this,
        distanceMeasurement: measurement,
        measurement,
        event
      });
    };

    this._onContextMenu = (event, measurement) => {
      this.fire("contextMenu", {
        plugin: this,
        distanceMeasurement: measurement,
        measurement,
        event
      });
    };
  }

  /**
   * @private
   */
  send(name, value) {
    name, value
  }

  /**
   * Gets the existing {@link DistanceMeasurement}s, each mapped to its {@link DistanceMeasurement#id}.
   *
   * @type {{String:DistanceMeasurement}}
   */
  get measurements() {
    return this._measurements;
  }

  /**
   * Sets the minimum length, in pixels, of an axis wire beyond which its label is shown.
   *
   * The axis wire's label is not shown when its length is less than this value.
   *
   * This is ````25```` pixels by default.
   *
   * Must not be less than ````1````.
   *
   * @type {number}
   */
  set labelMinAxisLength(labelMinAxisLength) {
    if (labelMinAxisLength < 1) {
      this.error("labelMinAxisLength must be >= 1; defaulting to 25");
      labelMinAxisLength = 25;
    }
    this._labelMinAxisLength = labelMinAxisLength || 25;
  }

  /**
   * Gets the minimum length, in pixels, of an axis wire beyond which its label is shown.
   * @returns {number}
   */
  get labelMinAxisLength() {
    return this._labelMinAxisLength;
  }
  
  getContainerElement() {
    return this._container
  }

  /**
   * Creates a {@link DistanceMeasurement}.
   *
   * The DistanceMeasurement is then registered by {@link DistanceMeasurement#id} in {@link XeokitDistanceMeasurementsPlugin#measurements}.
   *
   * @param {Object} params {@link DistanceMeasurement} configuration.
   * @param {String} params.id Unique ID to assign to {@link DistanceMeasurement#id}. The DistanceMeasurement will be registered by this in {@link XeokitDistanceMeasurementsPlugin#measurements} and {@link Scene.components}. Must be unique among all components in the {@link Viewer}.
   * @param {Number[]} params.origin.worldPos Origin World-space 3D position.
   * @param {Number[]} params.target.worldPos Target World-space 3D position.
   * @param {Boolean} [params.visible=true] Whether to initially show the {@link DistanceMeasurement}.
   * @param {Boolean} [params.originVisible=true] Whether to initially show the {@link DistanceMeasurement} origin.
   * @param {Boolean} [params.targetVisible=true] Whether to initially show the {@link DistanceMeasurement} target.
   * @param {Boolean} [params.wireVisible=true] Whether to initially show the direct point-to-point wire between {@link DistanceMeasurement#origin} and {@link DistanceMeasurement#target}.
   * @param {Boolean} [params.axisVisible=true] Whether to initially show the axis-aligned wires between {@link DistanceMeasurement#origin} and {@link DistanceMeasurement#target}.
   * @param {Boolean} [params.isAutoGenerated=false] Создан с помощью Shift
   * @returns {DistanceMeasurement} The new {@link DistanceMeasurement}.
   */
  createMeasurement(params = {}) {
    if (this.viewer.scene.components[params.id]) {
      this.error("Viewer scene component with this ID already exists: " + params.id);
      delete params.id;
    }
    const origin = params.origin;
    const target = params.target;

    const measurement = new Measurement(this, {
      id: params.id,
      plugin: this,
      container: this._container,
      origin: {
        // entity: origin.entity, TODO: когда нужна будет привязка
        worldPos: origin.worldPos
      },
      target: {
        // entity: target.entity, TODO: когда нужна будет привязка
        worldPos: target.worldPos
      },
      visible: params.visible,
      wireVisible: params.wireVisible,
      axisVisible: params.axisVisible !== false && this.defaultAxisVisible !== false,
      xAxisVisible: params.xAxisVisible !== false && this.defaultXAxisVisible !== false,
      yAxisVisible: params.yAxisVisible !== false && this.defaultYAxisVisible !== false,
      zAxisVisible: params.zAxisVisible !== false && this.defaultZAxisVisible !== false,
      labelsVisible: params.labelsVisible !== false && this.defaultLabelsVisible !== false,
      originVisible: params.originVisible,
      targetVisible: params.targetVisible,
      color: params.color,
      onMouseOver: this._onMouseOver,
      onMouseLeave: this._onMouseLeave,
      onContextMenu: this._onContextMenu,

      isAutoGenerated: params.isAutoGenerated,
      createDate: Date.now(),
      mode: params?.mode || XeokitMediator.RegimeMeasurement.rulerMode,
      numberSettings: XeokitMediator.DistanceMeasurement.distanceMeasurementNumberSettings
    });
    measurement.clickable = false

    this._measurements[measurement.id] = measurement;
    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()

    measurement.on("destroyed", () => {
      delete this._measurements[measurement.id];
      XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
    });

    this.fire("measurementCreated", measurement);
    return measurement;
  }

  updateAllDistanceMeasurement() {
    let measurements = []
    Object.values(this._measurements).forEach(measurement => {
      console.log(measurement)
      measurements.push({
        origin: measurement._originWorld,
        target: measurement._targetWorld,
        visible: measurement._visible,
        wireVisible: measurement._wireVisible
      })
      measurement.destroy()
    });
    measurements.forEach((measurement) => {
      this.createMeasurement({
        id: math.createUUID(),
        origin: {
          worldPos: measurement.origin,
        },
        target: {
          worldPos: measurement.target,
        },
        visible: measurement.visible,
        wireVisible: measurement.wireVisible
      });
    })
  }

  removeDistanceMeasurements(distanceMeasurements) {
    distanceMeasurements.forEach(distanceMeasurement => {
      this._measurements[distanceMeasurement.id].destroy()
    })
    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
  }

  setDistanceMeasurementsVisible(distanceMeasurements, visible) {
    distanceMeasurements.forEach(distanceMeasurement => {
      this._measurements[distanceMeasurement.id].visible = visible
    })
    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
  }

  /**
   * Destroys a {@link DistanceMeasurement}.
   *
   * @param {String} id ID of DistanceMeasurement to destroy.
   */
  destroyMeasurement(id) {
    const measurement = this._measurements[id];
    if (!measurement) {
      this.log("DistanceMeasurement not found: " + id);
      return;
    }
    measurement.destroy();
    this.fire("measurementDestroyed", measurement);
  }

  /**
   * Shows all or hides the angle label of each {@link DistanceMeasurement}.
   *
   * @param {Boolean} labelsShown Whether or not to show the labels.
   */
  setLabelsShown(labelsShown) {
    for (const [key, measurement] of Object.entries(this.measurements)) {
      key
      measurement.labelShown = labelsShown;
    }
  }


  /**
   * Destroys all {@link DistanceMeasurement}s.
   */
  clear() {
    const ids = Object.keys(this._measurements);
    for (var i = 0, len = ids.length; i < len; i++) {
      this.destroyMeasurement(ids[i]);
    }
  }

  /**
   * Destroys this XeokitDistanceMeasurementsPlugin.
   *
   * Destroys all {@link DistanceMeasurement}s first.
   */
  destroy() {
    super.destroy();
  }
}