import { defineStore } from 'pinia'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export const usePickCoordinateViewerStore = defineStore('pickCoordinateViewer', {
  state: () => {
    return {
      isPickCoordinateViewerActive: false,
      pickCoordinates: [],
      selectedIndex: 0,
      history: [],
    }
  },

  actions: {
    setPickCoordinateViewerActive(activityStatus) {
      this.isPickCoordinateViewerActive = activityStatus
    },

    setPickCoordinates(coords, xktVersion) {
      this.pickCoordinates = coords
      
      if (coords.length) {
        this.history.push({
          coords: [...coords],
          xktVersion: xktVersion
        })
        this.selectedIndex = this.history.length - 1
      }
      else {
        this.selectedIndex = -1
      }
    },

    selectCoordsByIndex(index) {
      this.selectedIndex = index

      const selectedCoords = this.history[index]?.coords ?? []
      const xktVersion = this.history[index]?.xktVersion ?? ''
      this.pickCoordinates = selectedCoords
      
      if (selectedCoords.length === 0) return
      
      // TODO: отобразить выбранные координаты на модели
      // Повторно отправлять в стор не надо, так как это координата из истории
      XeokitMediator.PickCoordinateViewer.createCoordinatePoint(selectedCoords, xktVersion)
    },

    removeCoordsByIndex(index) {
      if (index >= this.history.length) return
      this.history.splice(index, 1)

      if (this.selectedIndex < index) return
      if (this.selectedIndex === index) {
        
        // TODO: скрыть координаты на модели
        // Метод необходим, так как после удаления указанных координат в истории может не остаться записей
        XeokitMediator.PickCoordinateViewer.destroyCoordinatePoint()
      }

      this.selectCoordsByIndex(0)
    }
  },
})