/**
 * @param {number[]} aabb 
 * @param {number[]} offset 
 * @returns {number[]}
 */
function shiftAABBCenter(aabb, offset) {
  const currentSizeX = aabb[3] - aabb[0]
  const currentSizeY = aabb[4] - aabb[1]
  const currentSizeZ = aabb[5] - aabb[2]

  const currentCenterX = (aabb[3] + aabb[0]) / 2
  const currentCenterY = (aabb[4] + aabb[1]) / 2
  const currentCenterZ = (aabb[5] + aabb[2]) / 2

  const newCenterX = currentCenterX + offset[0]
  const newCenterY = currentCenterY + offset[1]
  const newCenterZ = currentCenterZ + offset[2]

  const newAABB = [
    newCenterX - currentSizeX / 2,
    newCenterY - currentSizeY / 2,
    newCenterZ - currentSizeZ / 2,
    newCenterX + currentSizeX / 2,
    newCenterY + currentSizeY / 2,
    newCenterZ + currentSizeZ / 2,
  ]

  return newAABB
}

/**
 * @param {Array<number[]>} aabbList 
 * @returns {number[]}
 */
function mergeAABBs(aabbList) {
  if (!aabbList.length) {
    throw new Error('aabbList is empty')
  }

  if (aabbList.length === 1) {
    return aabbList[0]
  }

  let mergedAABB = [
    Number.POSITIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
    Number.NEGATIVE_INFINITY
  ]

  for (const aabb of aabbList) {
    mergedAABB[0] = Math.min(mergedAABB[0], aabb[0])
    mergedAABB[1] = Math.min(mergedAABB[1], aabb[1])
    mergedAABB[2] = Math.min(mergedAABB[2], aabb[2])
    mergedAABB[3] = Math.max(mergedAABB[3], aabb[3])
    mergedAABB[4] = Math.max(mergedAABB[4], aabb[4])
    mergedAABB[5] = Math.max(mergedAABB[5], aabb[5])
  }

  return mergedAABB
}

/**
 * @param {number[]} aabb 
 * @param {number[]} rotationDegrees 
 * @returns {number} 
 */
function rotateAABB(aabb, rotationDegrees) {
  const toRadians = angle => (angle * Math.PI) / 180.0

  const [xmin, ymin, zmin, xmax, ymax, zmax] = aabb
  const [rotateX, rotateY, rotateZ] = rotationDegrees.map(toRadians)

  // Поворот вокруг оси X
  const rotateMatrixX = [
    [1, 0, 0],
    [0, Math.cos(rotateX), -Math.sin(rotateX)],
    [0, Math.sin(rotateX), Math.cos(rotateX)]
  ]

  // Поворот вокруг оси Y
  const rotateMatrixY = [
    [Math.cos(rotateY), 0, Math.sin(rotateY)],
    [0, 1, 0],
    [-Math.sin(rotateY), 0, Math.cos(rotateY)]
  ]

  // Поворот вокруг оси Z
  const rotateMatrixZ = [
    [Math.cos(rotateZ), -Math.sin(rotateZ), 0],
    [Math.sin(rotateZ), Math.cos(rotateZ), 0],
    [0, 0, 1]
  ]

  // Применение матриц поворота к координатам AABB
  const rotate = (matrix, point) => {
    const [x, y, z] = point
    return [
      matrix[0][0] * x + matrix[0][1] * y + matrix[0][2] * z,
      matrix[1][0] * x + matrix[1][1] * y + matrix[1][2] * z,
      matrix[2][0] * x + matrix[2][1] * y + matrix[2][2] * z
    ]
  }

  const points = [
    [xmin, ymin, zmin],
    [xmin, ymin, zmax],
    [xmin, ymax, zmin],
    [xmin, ymax, zmax],
    [xmax, ymin, zmin],
    [xmax, ymin, zmax],
    [xmax, ymax, zmin],
    [xmax, ymax, zmax]
  ]

  const rotatedPoints = points.map(point => {
    let rotated = rotate(rotateMatrixX, point)
    rotated = rotate(rotateMatrixY, rotated)
    rotated = rotate(rotateMatrixZ, rotated)
    return rotated
  })

  // Находим новые граничные координаты AABB после поворота
  const newAABB = [
    Math.min(...rotatedPoints.map(p => p[0])),
    Math.min(...rotatedPoints.map(p => p[1])),
    Math.min(...rotatedPoints.map(p => p[2])),
    Math.max(...rotatedPoints.map(p => p[0])),
    Math.max(...rotatedPoints.map(p => p[1])),
    Math.max(...rotatedPoints.map(p => p[2]))
  ]

  return newAABB
}

/**
 * @param {number[]} aabb 
 * @param {number} factor 
 * @returns {number[]} */
function expandAABB(aabb, factor) {
  return aabb.map((num, idx) => idx > 2 ? num + factor : num - factor)
}

function transformPoint(point, matrix) {
  const x = point[0]
  const y = point[1]
  const z = point[2]

  const transformedX = matrix[0] * x + matrix[4] * y + matrix[8] * z + matrix[12]
  const transformedY = matrix[1] * x + matrix[5] * y + matrix[9] * z + matrix[13]
  const transformedZ = matrix[2] * x + matrix[6] * y + matrix[10] * z + matrix[14]

  return [transformedX, transformedY, transformedZ]
}

function transformAABB(originalAABB, transformMatrix) {
  const min = [originalAABB[0], originalAABB[1], originalAABB[2]]
  const max = [originalAABB[3], originalAABB[4], originalAABB[5]]

  const corners = [
    [min[0], min[1], min[2]],
    [min[0], min[1], max[2]],
    [min[0], max[1], min[2]],
    [min[0], max[1], max[2]],
    [max[0], min[1], min[2]],
    [max[0], min[1], max[2]],
    [max[0], max[1], min[2]],
    [max[0], max[1], max[2]],
  ]

  const transformedCorners = corners.map((corner) => transformPoint(corner, transformMatrix))

  const newMin = [
    Math.min(...transformedCorners.map((corner) => corner[0])),
    Math.min(...transformedCorners.map((corner) => corner[1])),
    Math.min(...transformedCorners.map((corner) => corner[2])),
  ]

  const newMax = [
    Math.max(...transformedCorners.map((corner) => corner[0])),
    Math.max(...transformedCorners.map((corner) => corner[1])),
    Math.max(...transformedCorners.map((corner) => corner[2])),
  ]

  return [
    newMin[0], newMin[1], newMin[2],
    newMax[0], newMax[1], newMax[2],
  ]
}

function setAABBCenter(originalAABB, newCenter) {
  const oldCenter = [
    (originalAABB[0] + originalAABB[3]) / 2,
    (originalAABB[1] + originalAABB[4]) / 2,
    (originalAABB[2] + originalAABB[5]) / 2,
  ]

  const offset = [
    newCenter[0] - oldCenter[0],
    newCenter[1] - oldCenter[1],
    newCenter[2] - oldCenter[2],
  ]

  const newAABB = [
    originalAABB[0] + offset[0],
    originalAABB[1] + offset[1],
    originalAABB[2] + offset[2],
    originalAABB[3] + offset[0],
    originalAABB[4] + offset[1],
    originalAABB[5] + offset[2],
  ]

  return newAABB
}

export {
  expandAABB,
  mergeAABBs,
  rotateAABB,
  setAABBCenter,
  shiftAABBCenter,
  transformAABB,
}
