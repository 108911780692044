import { defineStore } from 'pinia'

export const useModelFulcrumStore = defineStore('modelFulcrum', {
  state: () => {
    return {
      modelFulcrumCoord: [0, 0, 0]
    }
  },

  actions: {
    setModelFulcrum(modelFulcrumCoord) {
      this.modelFulcrumCoord = modelFulcrumCoord
    }
  }
})