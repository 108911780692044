import { api } from '@/api'

const getDefaultState = () => {
  return {
    projectDicts: [],
    selectedProjectDict: null,
    selectedProjectDictAttributeTree: [],
    selectedAttribute: null,
    selectedAttributeElements: [],
    isTreeInPreparation: false,
    selectedElement: [],
    copyMatchingAttributes: null,
    showPanel: false,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  
  state,

  mutations: {
    resetState(state) { Object.assign(state, getDefaultState()) },

    setTreeInPreparation: (state, flag) => {
      state.isTreeInPreparation = flag
    },

    setCopyMatchingAttributes(state, copyName) {
      state.copyMatchingAttributes = copyName
    },

    setShowPanel(state, boolean) {
      state.showPanel = boolean
    }
  },

  actions: {
    loadProjectDicts({ state, rootGetters }) {
      const projectUuid = rootGetters['project/projectUuid']
      
      api.projectDict.getProjectDicts(projectUuid).then(projectDicts => {
        state.projectDicts = projectDicts
      })
    },

    createProjectDict({ state, commit, dispatch }, projectDict) {
      commit('setTreeInPreparation', true)
      api.projectDict.createProjectDict(projectDict).then((data) => {
        state.projectDicts.push(data)
        state.selectedProjectDict = data.uuid
        dispatch('updateSelectedProjectDictAttributeTree')
        commit('setTreeInPreparation', false)
      })
    },

    updateSelectedProjectDictAttributeTree({ state, commit }) {
      const selectedProjectDict = state.selectedProjectDict
      commit('setTreeInPreparation', true)
      if (selectedProjectDict) {
        api.projectDict.getProjectDictAttributeTree(selectedProjectDict).then(attributeTree => {
          state.selectedProjectDictAttributeTree = attributeTree
          commit('setTreeInPreparation', false)
        })
      } 
      else {
        state.selectedProjectDictAttributeTree = []
        state.selectedAttribute = null
      }
    },

    createAttributeGroup({ dispatch }, { attributeGroup, projectDictUuid }) {
      api.projectDict.createAttributeGroup(attributeGroup, projectDictUuid).then(() => {
        dispatch('updateSelectedProjectDictAttributeTree')
      })
    },

    createAttribute({ state, dispatch }, {attribute, attributeGroupUuid}) {
      api.projectDict.createAttribute(attribute, attributeGroupUuid).then((data) => {
        dispatch('updateSelectedProjectDictAttributeTree')
        state.selectedAttribute = data
      })
    },

    setSelectedProjectDict({ state, dispatch }, projectDict) {
      state.selectedProjectDict = projectDict
      state.selectedAttribute = null
      dispatch('updateSelectedProjectDictAttributeTree')
    },

    setSelectedAttribute({ state }, attribute) {
      // const projectUuid = rootGetters['project/projectUuid']
      state.selectedAttribute = attribute
      // api.projectDict.hightLightGroup(projectUuid, attribute.uuid).then((data) => {
      //   state.selectedAttributeElements = data
      // })
    },

    editAttribute({ state, dispatch, commit }, attribute) {
      commit('setTreeInPreparation', true)
      api.projectDict.editAttribute(attribute).then(() => {
        state.selectedAttribute = attribute
        dispatch('updateSelectedProjectDictAttributeTree')
        commit('setTreeInPreparation', false)
      })
    },

    setSelectedElements({state}, elementUuid) {
      state.selectedElement = elementUuid
    },

    deleteElement({dispatch}, elementUuid) {
      api.projectDict.deleteItem(elementUuid).then(() => {
        dispatch('updateSelectedProjectDictAttributeTree')
        state.selectedAttribute = null
      })
    },

    classificateAttribute({ commit }, { projectUuid, attributeDictionaryUuid }) {
      commit('setTreeInPreparation', true)
      api.projectDict.classificate({projectUuid, attributeDictionaryUuid}).then(() => {
        commit('setTreeInPreparation', false)
      })
    },

    deleteProjectDict({state, commit, dispatch}, dictUuid) {
      commit('setTreeInPreparation', true)
      api.projectDict.deleteDict(dictUuid).then(() => {
        dispatch('loadProjectDicts')
        state.selectedProjectDict = null
        state.selectedProjectDictAttributeTree = []
        commit('setTreeInPreparation', false)
      })
    },

    classificateGroupAttribute({ commit }, {projectUuid, projectAttributeDictionaryUuid, attributeDictionaryUuid}) {
      commit('setTreeInPreparation', true)
      api.projectDict.classificate({ projectUuid, projectAttributeDictionaryUuid, attributeDictionaryUuid }).then(() => {
        commit('setTreeInPreparation', false)
      })
    }
  }
}