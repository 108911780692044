export default ({ Factory, Presets }) => ({
  /**
   * Получить Оси, которые создал пользователь для указанного проекта
   * @param {String} projectUuid UUID проекта
   * @param {String} hashProject hash проекта
   */
  fetchCustomAxisGroup: (projectUuid, hashProject = null) => {
    if (hashProject) return Factory.projects.axis[projectUuid][hashProject].get(Presets.CLOUDY)
    return Factory.projects.axis[projectUuid].get(Presets.CLOUDY)
  },

  /**
   * Создание и изменение Оси в одном флаконе (идея Романа)
   * @param {Object} axisData Набор полей Оси, включающий в себя группировки, условия и прочее
   */
  createOrUpdate: (axisData) => Factory.projects.axis.post(axisData, Presets.CLOUDY),
  
  /**
   * Удаление выбранной Оси (Axis)
   * @param {String} axisUuid UUID Оси
   */
  delete: (axisUuid) => Factory.projects.axis[axisUuid].delete(Presets.CLOUDY),

  share: (axisUuid) => Factory.projects.axis[axisUuid].patch(Presets.CLOUDY),

  /**
   * Получить дерево элементов Оси для указаного проекта
   * @param {String} projectUuid UUID проекта
   * @param {String | Number} axisId ID оси (Axis): если это FixedAxis, то от 1 до 4, иначе UUID пользовательской Оси (CustomAxis)
   */
  fetchCollisionTree: (projectUuid, axisId) => Factory.projects[projectUuid].axis[axisId].tree.get(),

  /**
   * Получить дерево элементов Оси для указаного проекта
   * @param {String} projectUuid UUID проекта
   * @param {String | Number} axisId ID оси (Axis): если это FixedAxis, то от 1 до 4, иначе UUID пользовательской Оси (CustomAxis)
   * @param {Boolean} custom Указывает, какое API вызывать - для кастомных Осей или для фиксированных
   * @param {String} hashProject hash проекта
   */
  fetchElementTree: (projectUuid, axisId, custom, hashProject = null) => {
    let subpath = custom ? 'elementgroup' : 'elementtreecache'
    if (hashProject)
      return Factory.projects[subpath][projectUuid][axisId][hashProject].get(Presets.CLOUDY)
    
    return Factory.projects[subpath][projectUuid][axisId].get(Presets.CLOUDY)
  },

  /**
   * Получить этажи для указанного проекта
   * @param {String} projectUuid UUID проекта
   */
  getFloors: (projectUuid) => {
    return Factory.projects.conditionparam[projectUuid].FLOOR.get()
  },

  /**
   * Получение слоев для указанного проекта
   * @param {String} projectUuid 
   */
  getLayers: (projectUuid) => {
    return Factory.floors[projectUuid].getlayers.get()
  },

  /**
   * Сохранение и редактирование этажа для указанного проекта 
   * @param {Object} savedFloor 
   * @param {String} projectUuid 
   */
  postFloor: (savedFloor, projectUuid ) => {
    return Factory.floors[projectUuid].savefloor.post(savedFloor)
  },

  /**
   * Перемещение элемента по этажам
   * @param { String } fromFloorId
   * @param { String } toFloorId
   * @param { String } elementId
   */
  moveElementFromToFloor: (fromFloorId, toFloorId, elementId) => {
    return Factory.floors.fromfloor[fromFloorId].tofloor[toFloorId].element[elementId].patch()
  },

  /**
   * Удаление этажа для указанного проекта 
   * @param {String} elUuid 
   * @param {String} projectUuid 
   */
  deleteFloor: (elUuid, projectUuid) => {
    return Factory.floors[projectUuid].deletefloor[elUuid].delete()
  },

  /**
   * Получение сетки для указанного проекта
   * @param {String} projectUuid 
   */
  getGrid: (projectUuid) => {
    return Factory.grids.placement[projectUuid].get()
  },

  getGrid1: (projectUuid) => {
    return Factory.projects.grid[projectUuid].get()
  },

  /**
   * Запрос IFC guids соответствующих переданному списку UUID ревизий
   * @param {String} projectUuid
   * @param {String[]} uuids Список UUID ревизий
   */
  fetchIfcGUIDs: (projectUuid, uuids) => Factory.projects[projectUuid].globalidtoifcid.post(uuids),
})