<template lang="pug">
.app-toolbar-item
  span.mr-2.d-none.d-sm-inline-block(v-if="label" :v-line-clamp="1") {{ label | upFirstLetter }}

  // Меню
  v-menu(open-on-hover offset-y content-class="app-toolbar-menu" nudge-bottom="10" close-delay="300" allow-overflow)

    template(v-slot:activator="{ on }")
      .app-toolbar-item__activator.d-sm-inline-block(:v-line-clamp="1" :style="{ minWidth: minWidth ? minWidth + 'px' : 'min-content' }" v-on="on")
        slot

    // Меню - Контент
    slot(name="menu")
      .menu-background(:style="'maxWidth: ' + maxWidth + 'px;'")
        .menu-body.menu-scroll(:style="maxHeight ? 'maxHeight: ' + maxHeight + 'px' : 'maxHeight: 100%; height: 100%'")
          div(v-for="(item, index) in items" :key="index")
            v-divider.mx-2(dark v-if="item.divider")
            //- TODO: Заменить v-list-item на что-то другое?
            v-list-item.app-toolbar-menu__item(v-else tag="div" inactive @click.stop="handleSelect(item, index)" style='min-width: 178px; max-width: 200px;')
              .app-toolbar-menu__item-content
                slot(name="list-item" :item="item" :index="index") 
                  span(v-if="itemPrefix") {{ itemPrefix }}
                  span.filters-fonts {{ keyName ? $t(item[keyName]) : item }}
                  v-icon(v-if="item['prepend-icon']" size="16px" color='var(--v-primary-base)' dark left) {{ item['prepend-icon'] }}
  slot(name="append")
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'app-toolbar-item',
  props: {
    label: String,
    items: Array,
    keyName: String,
    all: String,
    minWidth: {
      type: [String, Number],
      default: 120
    },
    maxWidth: [String, Number],
    maxHeight: [String, Number],
    itemPrefix: String,
    mainItem: String,
  },
  methods: {
    handleSelect (item, index) {
      if (item && item.action) {
        item.action()
      }
      this.$emit('select', item, index)
    }
  },
  filters: {
    upFirstLetter (str) {
      return str[0].toUpperCase() + str.slice(1)
    }
  }
}
</script>

<style lang="css">
.app-toolbar-item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  max-height: 24px;
  color: var(--v-primary-darken2) !important;
}

.app-toolbar-item__activator {
  /* padding: 0px 8px; */
  background: var(--v-surface-lighten1);
  border-radius: 5px;
  font-weight: normal;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

/* .app-toolbar-menu {
  border-radius: 0px 0px 5px 5px !important;
} */

.app-toolbar-menu__item {
  cursor: pointer;
  padding-left: 24px;
}

.app-toolbar-menu__item:hover {
  /* background: rgba(0, 0, 0, 0.2); */
  background: var(--v-surface-lighten2)
}
.app-toolbar-menu__item-content {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  flex-wrap: nowrap
}

.filters-fonts {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* color: #505254; */
  color: var(--v-accent-base);
}

.menu-background {
  /* background-color: var(--v-surface-base); */
  /* padding: 8px; */
  height: 100%;
}

.menu-body {
  /* border-radius: 5px; */
  background-color: #f0f0f0 !important;
}
</style>
