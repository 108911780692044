import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { MeasurementTool } from '../measurement/measurementTool'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { Dot, math } from '@xeokit/xeokit-sdk'
import { AlertService } from '@/assets/app/AlertService'
import i18n from '@/plugins/i18n'

/*eslint-disable no-dupe-class-members*/
export class PointToFaceMeasurement {
  static #_firstPointWorldPos = null
  static #_firstPointCornerSphere = null

  static #_pickedFaceEdges = [] // Массив ребер грани
  static #_pickedFaceTriangles = null

  static #_pickingPoint = true

  static #_hoveringTrianglesColor = [2.0, 0.5, 0.5]
  static #_hoveringTriangles = null
  static #_drawedCornerSphere = null

  static #_onMouseHoverSurface = null
  static #_onMouseHoverOut = null
  static #_onSceneTick = null
  static #_onInputMouseUp = null
  static #_onInputMouseDown = null
  static #_onInputKeyDown = null

  static #_mouseCanvasClickTolerance = 5
  static #_mouseDownCanvasX
  static #_mouseDownCanvasY
  static #_mouseDownLeft = false
  static #_mouseDownRight = false

  static pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedWorldPos = pickResult._worldPos

    const pickedFaceTriangles = pickResult.trianglesPositions
    const pickedFaceEdges = pickResult.faceEdges

    const isPointMesh = pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')

    let nearestCorner = null
    let edges = []

    if (!isPointMesh) {
      pickedEntity.meshes.forEach((mesh) => {
        if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
      })
    }

    if (!this.#_firstPointWorldPos) {
      nearestCorner = pickedWorldPos
      if (!isPointMesh) {
        nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(pickedWorldPos, edges)
      }

      this.#_firstPointWorldPos = nearestCorner
      this.#_firstPointCornerSphere = this.#_drawCornerSphere(nearestCorner)

      this.#_pickingPoint = false
    } 
    else if (!isPointMesh) {
      this.#_destroyPickedFace()
      this.#_drawFace(pickedFaceEdges, pickedFaceTriangles, [2.0, 1.0, 1.0])

      let shortestSegment = geometry.pointToFaceMeasurement.findShortestSegmentBetweenPointAndFace(this.#_firstPointWorldPos, pickedFaceEdges)
      
      MeasurementTool.createDistanceMeasurement(this.#_firstPointWorldPos, shortestSegment[0])
    }
  }

  static clearAll() {
    this.#_pickingPoint = true
    this.#_firstPointWorldPos = null

    this.#_destroyFirstPointCornerSphere()
    this.#_destroyPickedFace()
    this.#_destroyHoveringTriangles()
  }

  static #_destroyCornerSphere() {
    this.#_drawedCornerSphere?.destroy()
    this.#_drawedCornerSphere = null
  }

  static #_drawCornerSphere(worldPos) {
    const canvasPos = XeokitMediator.viewer.camera.projectWorldPos(worldPos)
    return new Dot(document.getElementById('measurementsScreen'), { x: canvasPos[0], y: canvasPos[1] })
  }

  static #_destroyHoveringTriangles() {
    this.#_hoveringTriangles?.destroy()
    this.#_hoveringTriangles = null
  }

  static #_destroyFirstPointCornerSphere() {
    this.#_firstPointCornerSphere?.destroy()
    this.#_firstPointCornerSphere = null
  }

  static #_destroyPickedFace() {
    this.#_pickedFaceEdges?.forEach((edge) => {
      edge?.destroy()
    })
    this.#_pickedFaceEdges = []

    this.#_pickedFaceTriangles?.destroy()
    this.#_pickedFaceTriangles = null
  }

  static #_drawTriangles(triangles, color) {
    this.#_hoveringTriangles = geometry.drawer.createSurfaceMesh(triangles, color)
  }

  static #_drawFace(edges, triangles, color) {
    let drawedEdges = []
    edges.forEach((edge) => {
      drawedEdges.push(
        XeokitMediator.SceneObjects.createSegmentMesh({
          segmentPositions: edge,
        })
      )
    })

    this.#_pickedFaceEdges = drawedEdges
    this.#_pickedFaceTriangles = geometry.drawer.createSurfaceMesh(triangles, color)
  }

  static activate() {
    this.#_activateInputListeners()
    this.#_activateHoverSurfaceListener()
  }

  static #_activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#_onInputMouseDown)
    input.off(this.#_onInputMouseUp)

    this.#_onInputMouseDown = input.on('mousedown', (coords) => {
      this.#_mouseDownCanvasX = coords[0]
      this.#_mouseDownCanvasY = coords[1]
      this.#_mouseDownLeft = input.mouseDownLeft
      this.#_mouseDownRight = input.mouseDownRight
    })

    this.#_onInputMouseUp = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#_mouseDownCanvasX + this.#_mouseCanvasClickTolerance ||
        coords[0] < this.#_mouseDownCanvasX - this.#_mouseCanvasClickTolerance ||
        coords[1] > this.#_mouseDownCanvasY + this.#_mouseCanvasClickTolerance ||
        coords[1] < this.#_mouseDownCanvasY - this.#_mouseCanvasClickTolerance
      ) {
        this.#_mouseDownLeft = false
        this.#_mouseDownRight = false
        return
      }

      let pickResult = null

      if (this.#_mouseDownLeft) {
        if (this.#_pickingPoint) {
          pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
            canvasPos: coords,
            pickSurface: true,
          })
        } 
        else {
          pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
            canvasPos: coords,
            pickSurface: true,
            needPickedFace: true,
          })
        }

        if (pickResult) {
          if (pickResult.isSectionControl) {
            return
          }

          if (pickResult.entity.model.isCollisionIntersection) {
            AlertService.info({info: i18n.t('viewer.distanceMeasurement.collisionIntersectionException')})
            return
          }
          this.pick(pickResult)
        }
      }

      this.#_mouseDownLeft = false
      this.#_mouseDownRight = false
    })

    this.#_onInputKeyDown = input.on('keydown', (keyCode) => {
      if (keyCode == input.KEY_N) {
        this.clearAll()
      }
    }) 
  }

  static #_activateHoverSurfaceListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    const camera = XeokitMediator.viewer.camera
    const scene = XeokitMediator.viewer.scene

    let lastNearestCorner = null
    let nearestCorner = null

    let lastDist = -1
    let tempVec3a = math.vec3()

    this.#_onSceneTick = scene.on('tick', () => {
      if (this.#_firstPointCornerSphere) {
        this.#_destroyFirstPointCornerSphere()
        this.#_firstPointCornerSphere = this.#_drawCornerSphere(this.#_firstPointWorldPos)
      }

      if (this.#_mouseDownLeft || this.#_mouseDownRight) {
        this.#_destroyCornerSphere()
        return
      }
      if (nearestCorner) {
        math.subVec3(camera.eye, nearestCorner, tempVec3a)
        const dist = Math.abs(math.lenVec3(tempVec3a))
        if (lastDist != dist) {
          this.#_destroyCornerSphere()
          this.#_drawedCornerSphere = this.#_drawCornerSphere(nearestCorner)
          lastDist = dist
        }
      }
    })

    this.#_onMouseHoverSurface = cameraControl.on('hoverSurface', (event) => {
      if (this.#_mouseDownLeft || this.#_mouseDownRight) {
        this.#_destroyHoveringTriangles()
        return
      }

      let pickResult = null

      if (this.#_pickingPoint) {
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: event.canvasPos,
        })
      } 
      else {
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: event.canvasPos,
          needPickedFace: true,
        })
      }

      if (!pickResult) {
        return
      }

      if (pickResult.isSectionControl) {
        return
      }

      if (pickResult.entity.model.isCollisionIntersection) {
        AlertService.info({info: i18n.t('viewer.distanceMeasurement.collisionIntersectionException')})
        cameraControl.off(this.#_onMouseHoverSurface)
        return
      }

      if (!pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) {
        if (this.#_pickingPoint) {
          let edges = []
          pickResult.entity.meshes.forEach((mesh) => {
            if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
          })
          nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(pickResult.worldPos, edges)

          if (!lastNearestCorner) {
            lastNearestCorner = nearestCorner
          }

          if (geometry.utils.arePointsClose(lastNearestCorner, nearestCorner)) {
            return
          }
          this.#_destroyCornerSphere()
          this.#_drawedCornerSphere = this.#_drawCornerSphere(nearestCorner)
        } 
        else {
          const pickedFaceTriangles = pickResult.trianglesPositions
          this.#_destroyHoveringTriangles()
          this.#_drawTriangles(pickedFaceTriangles, this.#_hoveringTrianglesColor)
        }
      }
    })

    this.#_onMouseHoverOut = cameraControl.on('hoverOff', () => {
      if (this.#_pickingPoint) {
        this.#_destroyCornerSphere()
      } 
      else {
        this.#_destroyHoveringTriangles()
      }
    })
  }

  static deactivate() {
    const input = XeokitMediator.viewer.scene.input
    const cameraControl = XeokitMediator.viewer.cameraControl
    const scene = XeokitMediator.viewer.scene

    input.off(this.#_onInputMouseDown)
    input.off(this.#_onInputMouseUp)
    input.off(this.#_onInputKeyDown)
    cameraControl.off(this.#_onMouseHoverSurface)
    cameraControl.off(this.#_onMouseHoverOut)
    scene.off(this.#_onSceneTick)

    this.#_destroyHoveringTriangles()
    this.#_destroyPickedFace()
    this.#_destroyCornerSphere()

    this.#_firstPointWorldPos = null
    this.#_pickingPoint = true
    this.#_destroyFirstPointCornerSphere()
  }
}
