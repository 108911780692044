export default ({ Factory }) => ({

  orgsList: () => Factory.corp.orgs.get(),

  orgsByAdmin: (userUuid) => Factory.corp.orgs.admin[userUuid].get(),

  orgForUser: (userUuid) => Factory.corp.org.info[userUuid].get(),

  saveOrg: (obj) => Factory.corp.org.post(obj),

  deleteOrg: (id) => Factory.corp.org[id].delete(),

  org: (id) => Factory.corp.org[id].get(),

  createOrganizationToken: (orgUuid) => Factory.corp.org[orgUuid].token.get(),
  deleteOrganizationToken: (orgUuid) => Factory.corp.org[orgUuid].token.delete(),

  orgStructure: (id) => Factory.corp.org[id].structure.get(),
  saveStructure: (obj) => Factory.corp.structure.post(obj),
  deleteStructure: (id) => Factory.corp.structure[id].delete(),

  orgEmployees: (id) => Factory.corp.org[id].employees.get(),
  saveEmployee: (obj) => Factory.corp.employee.post(obj),
  deleteEmployee: (id) => Factory.corp.employee[id].delete(),
  orgCloud:(id) => Factory.corp.org[id].cloud.post(),
  orgEmployee: (id) => Factory.corp.org.employee[id].get(),

  syncOrg: (id) => Factory.nextcloud.syncorg[id].get(),

  orgPositions: (id) => Factory.corp.org[id].positions.get(),
  savePosition: (obj) => Factory.corp.position.post(obj),
  deletePosition: (id) => Factory.corp.position[id].delete(),

  myOrgsList: () => Factory.corp.orgs.my.get(),

  orgRoles: (id) => Factory.corp.org[id].roles.get(),
  saveRole: (obj) => Factory.corp.role.post(obj),
  deleteRole: (id) => Factory.corp.role[id].delete(),

  orgGroups: (id) => Factory.corp.org[id].groups.get(),
  saveGroup: (obj) => Factory.corp.group.post(obj),
  deleteGroup: (id) => Factory.corp.group[id].delete(),

  delMemberFromGroup: (orgId, memberId) => Factory.corp.group[orgId].member[memberId].delete(),

  maintenancePeriod: () => Factory.corp.equipments.period.get(),

  orgEquipments: (id) => Factory.corp.equipments.org[id].get(),
  getEquipment: (id) => Factory.corp.equipments[id].get(),
  saveEquipment: (obj) => Factory.corp.equipments.post(obj),
  deleteEquipment: (id) => Factory.corp.equipments[id].delete(),

  orgMaintenanceRules: (id) => Factory.corp.equipments.maintenancerules[id].get(),
  saveMaintenanceRule: (obj) => Factory.corp.equipments.maintenancerule.post(obj),
  deleteMaintenanceRule: (id) => Factory.corp.equipments.maintenancerule[id].delete(),

  saveMaintenanceCheck: (obj) => Factory.corp.equipments.maintenancecheck.post(obj),
  deleteMaintenanceCheck: (id) => Factory.corp.equipments.maintenancecheck[id].delete(),

  getArchivedOrg: (id) => Factory.corp.archivedOrg[id].get(),
  archivedOrgsList: () => Factory.corp.archivedOrgs.get(),
  restoreOrg: (id) => Factory.corp.org.restore[id].post(),
})