import { api } from "@/api"
import router from '../router'

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

let flatlist = function (tree, level = 0) {
  let array = []
  tree.forEach(element => {
    element.level = level
    array.push(element, ...flatlist(element.children, level + 1))
  })
  return array
}

export default {
  namespaced: true,

  state: {
    currentTab: null,
    orgs: {
      list: [],
      status: status.INITIAL
    },
    archivedOrgs: {
      list: [],
      status: status.INITIAL
    },
    myOrgs: {
      list: [],
      status: status.INITIAL
    },
    org: {
      item: null,
      status: status.INITIAL,
      structure: [],
      structureStatus: status.INITIAL
    },
    selectedStructure: null,
    employees: {
      list: [],
      status: status.INITIAL
    },
    positions: {
      list: [],
      status: status.INITIAL
    },
    roles: {
      orgId: null,
      list: [],
      status: status.INITIAL
    },
    groups: {
      orgId: null,
      list: [],
      status: status.INITIAL
    },
    equipments: {
      orgId: null,
      list: [],
      status: status.INITIAL
    },

    equipmentSpecification: {
      item: null,
      status: status.INITIAL,
      maintenanceRules: [],
      maintenanceRulesStatus: status.INITIAL
    },

    maintenancePeriod: {
      list: [],
      status: status.INITIAL
    },

    selectedGroup: null,
    selectedEmployee: null,
    selectedRule: null,
    users: [],
    selectedArchive: {title: 'headerToolbar.active', value: false},
  },

  getters: {

    orgs: ({ orgs }) => orgs.list,
    archivedOrgs: ( {archivedOrgs} ) => archivedOrgs.list,
    isOrgsLoaded: ({ orgs }) => orgs.status == status.LOADED,

    myOrgs: ({ myOrgs }) => myOrgs.list,
    isMyOrgsLoaded: ({ myOrgs }) => myOrgs.status == status.LOADED,

    org: ({ org }) => org.item,
    orgStructure: ({ org }) => org.structure,
    isOrgStructureLoaded: ({ org }) => org.structureStatus == status.LOADED,
    isOrgLoaded: ({ org }) => org.status == status.LOADED,

    flatlist: ({ org }) => flatlist(org.structure),

    employees: ({ employees }) => employees.list,
    isEmployeesLoaded: ({ employees }) => employees.status == status.LOADED,

    positions: ({ positions }) => positions.list,
    isPositionsLoaded: ({ positions }) => positions.status == status.LOADED,

    roles: ({ roles }) => roles.list.sort((a, b) => a.title.localeCompare(b.title)),
    isRolesLoaded: ({ roles }) => roles.status == status.LOADED,

    groups: ({ groups }) => groups.list,
    isGroupsLoaded: ({ groups }) => groups.status == status.LOADED,

    equipments: ({ equipments }) => equipments.list,
    isEquipmentsLoaded: ({ equipments }) => equipments.status == status.LOADED,

    equipmentSpecification: ({ equipmentSpecification }) => equipmentSpecification.item,
    isEquipmentSpecificationLoaded: ({ equipmentSpecification }) => equipmentSpecification.status == status.LOADED,
    maintenanceRules: ({ equipmentSpecification }) => equipmentSpecification.maintenanceRules.sort((a, b) => a.title.localeCompare(b.title)),
    isMaintenanceRulesLoaded: ({ equipmentSpecification }) => equipmentSpecification.maintenanceRulesStatus == status.LOADED,

    maintenancePeriod: ({ maintenancePeriod }) => maintenancePeriod.list,
    isPeriodLoaded: ({ maintenancePeriod }) => maintenancePeriod.status == status.LOADED,

    maintenanceChecks: ({selectedRule}) => selectedRule ? selectedRule.maintenanceChecks.sort((a, b) => a.number - b.number) : []
  },

  mutations: {
    updateCurrentTab: (state, tab) => { state.currentTab = tab },

    setOrgs: (state, list) => { state.orgs.list = list },
    setOrgsStatus: (state, status) => { state.orgs.status = status },

    setArchivedOrgs: (state, list) => { state.archivedOrgs.list = list },
    setArchivedOrgsStatus: (state, status) => { state.archivedOrgs.status = status },

    setMyOrgs: (state, list) => { state.myOrgs.list = list },
    setMyOrgsStatus: (state, status) => { state.myOrgs.status = status },

    setEmployees: (state, list) => { state.employees.list = list },
    setEmployeesStatus: (state, status) => { state.employees.status = status },

    setPositions: (state, list) => { state.positions.list = list },
    setPositionsStatus: (state, status) => { state.positions.status = status },

    setPeriod: (state, list) => { state.maintenancePeriod.list = list },
    setPeriodStatus: (state, status) => { state.maintenancePeriod.status = status },

    setRolesStatus: (state, status) => { state.roles.status = status },

    setGroups: (state, payload) => { 
        state.groups.list = payload.list 
        state.groups.orgId = payload.orgId
    },
    setGroupsStatus: (state, status) => { state.groups.status = status },

    setEquipments: (state, payload) => { 
        state.equipments.list = payload.list 
        state.equipments.orgId = payload.orgId
    },
    setEquipmentsStatus: (state, status) => { state.equipments.status = status },

    setEquipmentSpecification: (state, equipmentSpecification) => { state.equipmentSpecification.item = equipmentSpecification },
    setEquipmentSpecificationStatus: (state, status) => { state.equipmentSpecification.status = status },

    setMaintenanceRules: (state, list) => { state.equipmentSpecification.maintenanceRules = list },
    setMaintenanceRulesStatus: (state, status) => { state.equipmentSpecification.maintenanceRulesStatus = status },


    setOrgStructure: (state, structure) => { state.org.structure = structure },
    setOrgStructureStatus: (state, status) => { state.org.structureStatus = status },

    setOrg: (state, org) => { state.org.item = org },
    setOrgStatus: (state, status) => { state.org.status = status },

    updateOrg: ({ orgs }, org) => {
      let exist = false
      if(orgs.list.length > 0) { 
        let curr = orgs.list.find(item => item.uuid === org.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, org)
        }
      } 
      if(!exist) orgs.list.push(org)
    },

    deleteOrg: ({ orgs, archivedOrgs, myOrgs }, id) => {
      let index = orgs.list.findIndex(item => item.uuid === id)
      orgs.list[index].archived = true
      archivedOrgs.list.push(orgs.list[index])
      orgs.list.splice(index, 1)
      myOrgs.list.splice(myOrgs.list.findIndex(item => item.uuid === id), 1)
    },

    restoreOrg: ({ orgs, archivedOrgs, users, myOrgs }, id) => {
      let index = archivedOrgs.list.findIndex(item => item.uuid === id)
      archivedOrgs.list[index].adminsInfo = users.filter(u => archivedOrgs.list[index].admins.includes(u.uuid)).map(u => { return u.name + ' (' + u?.email + ')' }).join(", ")
      archivedOrgs.list[index].archived = false
      orgs.list.push(archivedOrgs.list[index])
      myOrgs.list.push(archivedOrgs.list[index])
      archivedOrgs.list.splice(index, 1)
      
    },

    setSelectedStructure: (state, structure) => { state.selectedStructure = structure },
    setSelectedGroup: (state, group) => { state.selectedGroup = group },
    setSelectedEmployee: (state, empl) => { state.selectedEmployee = empl },
    setSelectedRule: (state, rule) => { state.selectedRule = rule },

    updateEmployee: ({ employees }, empl) => {
      let exist = false
      if(employees.list.length > 0) { 
        let curr = employees.list.find(item => item.uuid === empl.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, empl)
        }
      } 
      if(!exist) employees.list.push(empl)
    },

    deleteEmployee: ({ employees }, id) => {
      let index = employees.list.findIndex(item => item.uuid === id)
      employees.list.splice(index, 1)
    },

    updatePosition: ({ positions }, pos) => {
      let exist = false
      if(positions.list.length > 0) { 
        let curr = positions.list.find(item => item.uuid === pos.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, pos)
        }
      } 
      if(!exist) positions.list.push(pos)
    },

    deletePosition: ({ positions }, id) => {
      let index = positions.list.findIndex(item => item.uuid === id)
      positions.list.splice(index, 1)
    },

    updateRole: ({ roles }, role) => {
      let exist = false
      if(roles.list.length > 0) { 
        let curr = roles.list.find(item => item.uuid === role.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, role)
        }
      } 
      if(!exist) roles.list.push(role)
    },

    deleteRole: ({ roles }, id) => {
      let index = roles.list.findIndex(item => item.uuid === id)
      roles.list.splice(index, 1)
    },

    updateGroup: ({ groups }, group) => {
      let exist = false
      if(groups.list.length > 0) { 
        let curr = groups.list.find(item => item.uuid === group.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, group)
        }
      } 
      if(!exist) groups.list.push(group)
    },

    deleteGroup: ({ groups }, id) => {
      let index = groups.list.findIndex(item => item.uuid === id)
      groups.list.splice(index, 1)
    },

    updateEquipment: ({ equipments }, equipment) => {
      let exist = false
      if(equipments.list.length > 0) { 
        let curr = equipments.list.find(item => item.uuid === equipment.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, equipment)
        }
      } 
      if(!exist) equipments.list.push(equipment)
    },

    deleteEquipment: ({ equipments }, id) => {
      let index = equipments.list.findIndex(item => item.uuid === id)
      equipments.list.splice(index, 1)
    },

    updateUser: ({ employees }, user) => {
      if(employees.list.length > 0) { 
        employees.list.filter(item => item.profile?.uuid === user.uuid).forEach(e => {
          e.profile = user
        })
      } 
    },

    updateMaintenanceRule: ({ equipmentSpecification }, role) => {
      let exist = false
      if(equipmentSpecification.maintenanceRules.length > 0) { 
        let curr = equipmentSpecification.maintenanceRules.find(item => item.uuid === role.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, role)
        }
      } 
      if(!exist) equipmentSpecification.maintenanceRules.push(role)
    },

    deleteMaintenanceRule: ({ equipmentSpecification }, id) => {
      let index = equipmentSpecification.maintenanceRules.findIndex(item => item.uuid === id)
      equipmentSpecification.maintenanceRules.splice(index, 1)
    },

    updateMaintenanceCheck: ({ selectedRule }, check) => {
      let exist = false
      if(selectedRule.maintenanceChecks.length > 0) { 
        let curr = selectedRule.maintenanceChecks.find(item => item.uuid === check.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, check)
        }
      } 
      if(!exist) selectedRule.maintenanceChecks.push(check)
    },

    deleteMaintenanceCheck: ({ selectedRule }, id) => {
      let index = selectedRule.maintenanceChecks.findIndex(item => item.uuid === id)
      selectedRule.maintenanceChecks.splice(index, 1)
    },

    setUsers: (state, allUsers) => {
      state.users = allUsers
    },

    selectArchive: (state, archive) => {
      state.selectedArchive = archive
    },

  },

  actions: {
    INIT: ({ state, dispatch }) => {
      dispatch("LOAD_ORGS")
      if(state.archivedOrgs.status != status.LOADED){
        dispatch("LOAD_ARCHIVED_ORGS")
      }
    },

    LOAD_ORGS: ({ state, commit }) => {
      let users = state.users
      
      if (state.orgs.status == status.INITIAL) {
        commit('setOrgsStatus', status.LOADING)

        api.corp.orgsList().then(data => {
          data = data.map(o => {
            o.adminsInfo = users.filter(u => o.admins.includes(u.uuid)).map(u => { return u.name + ' (' + u?.email + ')' }).join(", ")
            return o
          })

          commit('setOrgs', data)
          commit('setOrgsStatus', status.LOADED)
        })
      } 
    },

    LOAD_ARCHIVED_ORGS: ({ state, commit }) => {
      let users = state.users

      if (state.archivedOrgs.status == status.INITIAL) {
        commit('setArchivedOrgsStatus', status.LOADING)

        api.corp.archivedOrgsList().then(data => {
          data = data.map(o => {
            o.adminsInfo = users.filter(u => o.admins.includes(u.uuid)).map(u => { return u.name + ' (' + u?.email + ')' }).join(", ")
            return o
          })

          commit('setArchivedOrgs', data)
          commit('setArchivedOrgsStatus', status.LOADED)
        })
      } 
    },

    LOAD_USERS: ({ commit }) => {
      return api.users.getActiveUsersShort().then(data => {
        commit('setUsers', data)
        return true
      })
    },

    LOAD_MY_ORGS: ({ state, commit }) => {
      if (state.myOrgs.status == status.INITIAL) {
        commit('setMyOrgsStatus', status.LOADING)
        api.corp.myOrgsList().then(data => {
          commit('setMyOrgs', data)
          commit('setMyOrgsStatus', status.LOADED)
        })
      } 
    },


    LOAD_ORG_FULL: ({ dispatch },  orgId) => {
      dispatch("LOAD_ORG", orgId)
      dispatch("LOAD_ORG_STRUCTURE", orgId)
      dispatch("LOAD_ORG_EMPLOYEES", orgId)
      dispatch("LOAD_ORG_POSITIONS", orgId)
      dispatch("LOAD_ORG_ROLES", orgId)
      dispatch("LOAD_ORG_GROUPS", orgId)
      dispatch('LOAD_ORG_EQUIPMENTS', orgId)
      dispatch('roles/LOAD_FUNCTIONS', null, { root: true })
    },

    LOAD_ORG: ({ commit },  orgId) => {
      commit('setOrgStatus', status.LOADING)
      api.corp.org(orgId).then(data => {
        commit('setOrg', data)
        commit('setOrgStatus', status.LOADED)
      }).catch(error => {
        if(error.response?.data.error ==  "organization_is_archived"){
          router.back()
        }
      })
    },

    LOAD_ORG_STRUCTURE: ({ commit },  orgId) => {
      commit('setOrgStructureStatus', status.LOADING)
      commit('setOrgStructure', [])
      api.corp.orgStructure(orgId).then(data => {
        commit('setOrgStructure', data)
        commit('setOrgStructureStatus', status.LOADED)
      })
    },

    LOAD_ORG_EMPLOYEES: ({ commit },  orgId) => {
      commit('setEmployeesStatus', status.LOADING)
      api.corp.orgEmployees(orgId).then(data => {
        commit('setEmployees', data)
        commit('setEmployeesStatus', status.LOADED)
      })
    },

    LOAD_ORG_POSITIONS: ({ commit },  orgId) => {
      commit('setPositionsStatus', status.LOADING)
      api.corp.orgPositions(orgId).then(data => {
        commit('setPositions', data)
        commit('setPositionsStatus', status.LOADED)
      })
    },

    LOAD_ORG_ROLES: ({ commit, state, dispatch },  orgId) => {
      if(orgId != null){
        if (state.roles.status == status.INITIAL || state.roles.orgId != orgId) {
          commit('setRolesStatus', status.LOADING)
          api.corp.orgRoles(orgId).then(data => {
            dispatch('setRoles', {"list":data, "orgId": orgId})
            commit('setRolesStatus', status.LOADED)
          })
        }
      } else {
        dispatch('setRoles', {"list":[], "orgId": null})
      }
    },

    LOAD_ORG_GROUPS: ({ commit, state },  orgId) => {
      if(orgId != null){
        if (state.groups.status == status.INITIAL || state.groups.orgId != orgId) {
          commit('setGroupsStatus', status.LOADING)
          api.corp.orgGroups(orgId).then(data => {
            commit('setGroups', {"list":data, "orgId": orgId})
            commit('setGroupsStatus', status.LOADED)
          })
        }
      } else {
        commit('setGroups', {"list":[], "orgId": null})
      }
    },

    LOAD_ORG_EQUIPMENTS: ({ commit, state, dispatch },  orgId) => {
      dispatch("LOAD_MAINTENANCE_PERIOD")
      if (state.equipments.status == status.INITIAL || state.equipments.orgId != orgId) {
        commit('setEquipmentsStatus', status.LOADING)
        api.corp.orgEquipments(orgId).then(data => {
          commit('setEquipments', {"list":data, "orgId": orgId})
          commit('setEquipmentsStatus', status.LOADED)
        })
      }
    },

    LOAD_MAINTENANCE_RULES: ({ commit, state, dispatch },  specId) => {
      dispatch("LOAD_MAINTENANCE_PERIOD")
      if (state.equipmentSpecification.status == status.INITIAL || state.equipmentSpecification.item.uuid != specId) {
        commit('setSelectedRule', null)
        commit('setMaintenanceRulesStatus', status.LOADING)
        commit('setEquipmentSpecificationStatus', status.LOADING)
        api.corp.getEquipment(specId).then(data => {
          commit('setEquipmentSpecification', data)
          commit('setEquipmentSpecificationStatus', status.LOADED)
          api.corp.orgMaintenanceRules(specId).then(data => {
            commit('setMaintenanceRules', data)
            commit('setMaintenanceRulesStatus', status.LOADED)
          })
        })

      }
    },

    LOAD_MAINTENANCE_PERIOD: ({ commit, state }) => {
      if (state.maintenancePeriod.status == status.INITIAL) {
        commit('setPeriodStatus', status.LOADING)
        api.corp.maintenancePeriod().then(data => {
          commit('setPeriod', data)
          commit('setPeriodStatus', status.LOADED)
        })
      }
    },

    LOAD_EMPLOYEE: ({ commit },  id) => {
      api.corp.orgEmployee(id).then(data => {
        let empl = data.employee
        empl.project_info = data.project_info
        commit('setSelectedEmployee', empl)
      })
    },

    loadArchivedOrg: ({ dispatch } , orgId) => {
      dispatch
      return api.corp.getArchivedOrg(orgId).then(data => {
        return data
      })
    },


    gotoOrg: ({ commit }) => {
      commit('setOrgStatus', status.INITIAL)
    },

    saveOrg: ({ commit }, obj) => {
      return api.corp.saveOrg(obj).then(data => {
        commit('updateOrg', data)
      })
    },

    createOrgToken: ({ commit }, orgUuid) => {
      return api.corp.createOrganizationToken(orgUuid).then(data => {
        commit('updateOrg', data)
        return data
      })
    },

    deleteOrgToken: ({ commit }, orgUuid) => {
      return api.corp.deleteOrganizationToken(orgUuid).then(data => {
        commit('updateOrg', data)
        return data
      })
    },

    deleteOrg: ({ commit }, id) => {
      api.corp.deleteOrg(id).then(() => {
        commit('deleteOrg', id)
      })
    },

    restoreOrganization: ({ commit }, id) => {
      api.corp.restoreOrg(id).then(() => {
        commit('restoreOrg', id)
      })
    },

    saveStructure: ({ dispatch, getters }, obj) => {
      const { org } = getters
      api.corp.saveStructure(obj).then(() => {
        dispatch('LOAD_ORG_STRUCTURE', org.uuid)
      })
    },

    deleteStructure: ({ dispatch, getters }, id) => {
      const { org } = getters
      api.corp.deleteStructure(id).then(() => {
        dispatch('LOAD_ORG_STRUCTURE', org.uuid)
      })
    },

    saveEmployee: ({ commit, dispatch, state }, obj) => {
      api.corp.saveEmployee(obj).then(data => {
        commit('updateEmployee', data)
        state.groups.status = status.INITIAL 
        dispatch("LOAD_ORG_GROUPS", data.organization.uuid)
      })
    },

    deleteEmployee: ({ commit }, id) => {
      api.corp.deleteEmployee(id).then(() => {
        commit('deleteEmployee', id)
      })
    },

    savePosition: ({ commit }, obj) => {
      api.corp.savePosition(obj).then(data => {
        commit('updatePosition', data)
      })
    },

    deletePosition: ({ commit }, id) => {
      api.corp.deletePosition(id).then(() => {
        commit('deletePosition', id)
      })
    },

    saveRole: ({ commit }, obj) => {
      api.corp.saveRole(obj).then(data => {
        commit('updateRole', data)
      })
    },

    deleteRole: ({ commit }, id) => {
      return api.corp.deleteRole(id).then(() => {
        commit('deleteRole', id)
      })
    },

    saveGroup: ({ commit }, obj) => {
      api.corp.saveGroup(obj).then(data => {
        commit('updateGroup', data)
      })
    },

    deleteGroup: ({ commit }, id) => {
      api.corp.deleteGroup(id).then(() => {
        commit('deleteGroup', id)
      })
    },

    delMemberFromGroup: ({ commit }, obj) => {
      api.corp.delMemberFromGroup(obj.groupId, obj.memberId).then(data => {
        commit('updateGroup', data)
      })
    },

    editUser: ({ commit }, user) => {
      return api.users.editWithAvatar(user).then(data => {
        commit('updateUser', data)
      })
      // return api.users.edit(user).then(data => {
      //   commit('updateUser', data)
      // })
    },

    setRoles: ({state,rootGetters}, payload) => {
      payload.list.forEach(role => {
        role.show = !!rootGetters['project/openedRoles']?.find(r => r === role.uuid)
      })
      state.roles.list = payload.list 
      state.roles.orgId = payload.orgId
    },

    saveEquipment: ({ commit }, obj) => {
      return api.corp.saveEquipment(obj).then(data => {
        commit('updateEquipment', data)
        return data
      })
    },

    deleteEquipment: ({ commit }, id) => {
      api.corp.deleteEquipment(id).then(() => {
        commit('deleteEquipment', id)
      })
    },

    saveMaintenanceRule: ({ commit }, obj) => {
      if(obj.includeRule?.uuid == null) obj.includeRule = null
      api.corp.saveMaintenanceRule(obj).then(data => {
        commit('updateMaintenanceRule', data)
      })
    },

    deleteMaintenanceRule: ({ commit }, id) => {
      api.corp.deleteMaintenanceRule(id).then(() => {
        commit('deleteMaintenanceRule', id)
      })
    },

    saveMaintenanceCheck: ({ commit }, obj) => {
      api.corp.saveMaintenanceCheck(obj).then(data => {
        commit('updateMaintenanceCheck', data)
      })
    },

    deleteMaintenanceCheck: ({ commit }, id) => {
      api.corp.deleteMaintenanceCheck(id).then(() => {
        commit('deleteMaintenanceCheck', id)
      })
    },

  }
}
