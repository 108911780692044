import { defineStore } from 'pinia'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export const RulerMode = Object.freeze({
  ruler: 'ruler',
  edge: 'edge',
  edgeToEdge: 'edgeToEdge',
  pointToEdge: 'pointToEdge',
  pointToPoint: 'pointToPoint',
  pointToFace: 'pointToFace',
  faceToFace: 'faceToFace',
})

export const useRulerStore = defineStore('viewer.ruler', {
  
  getters: {
    mode: () => {
      let regime = XeokitMediator.RegimeMeasurement

      if (regime.RegimeMeasurementModeClass == regime.modeClasses.EdgeMeasurement) return RulerMode.edge
      if (regime.RegimeMeasurementModeClass == regime.modeClasses.EdgeToEdgeMeasurement) return RulerMode.edgeToEdge
      if (regime.RegimeMeasurementModeClass == regime.modeClasses.PointToEdgeMeasurement) return RulerMode.pointToEdge
      if (regime.RegimeMeasurementModeClass == regime.modeClasses.PointToPointMeasurement) return RulerMode.pointToPoint
      if (regime.RegimeMeasurementModeClass == regime.modeClasses.PointToFaceMeasurement) return RulerMode.pointToFace
      if (regime.RegimeMeasurementModeClass == regime.modeClasses.FaceToFaceMeasurement) return RulerMode.faceToFace
      
      return RulerMode.ruler
    },
  }
})