import { defineStore } from 'pinia'
import { api } from '@/api'
import { debounce } from 'lodash'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import vuexStore from '@/store'
import { hexToRgb } from '@/utils'
import { AxisUtils } from '@/assets/model/axis'

let debouncedSaveActiveColorCodings = debounce((projectUuid, uuids) => {
  api.viewerTools.saveActiveColorCodings(projectUuid, uuids)
}, 1000)

export const ColorCodingType = Object.freeze({
  MODELS: 1,
  CLASSES: 2,
  GROUPS: 3,
})

export class ColorCoding {
  constructor (json) {
    this.uuid = json.uuid
    this.title = json.title
    this.color = json.color
    // this.type = json.type
    this.type = { value: json.type }
    this.items = json.items
  }
}

export const useViewerColorsStore = defineStore('viewer.colors', {
  state: () => {
    return {
      all: [],
      selected: [],
    }
  },

  getters: {
    isAllVisible (state) {
      return state.all.length == this.selected.length
    },

    getColorByGroupUuid: (state) => (groupUuid) => {
      let colorCoding = state.all.find(cc => {
        if (state.selected.includes(cc.uuid) == false) return null
        if (cc.type.value != ColorCodingType.GROUPS) return null
        if (cc.items.includes(groupUuid) == false) return null
        return cc
      })
      return colorCoding?.color ?? 'white'
    },
  },

  actions: {

    async presetActiveColorCodings (activeColorCodings) {
      this.all = Array.from(activeColorCodings).sort((a, b) => a.title.localeCompare(b.title))
      this.selected = activeColorCodings.map(item => item.uuid)
      
      // XeokitMediator.xktLoader.once('loadingDone', () => {
      //   setTimeout(() => {
      //     // this.colorizeActive()
      //     this.recolorizeAll()
      //   }, 10)
      // })
    },

    async getColorizedItemsUuid() {
      let cleared = new Set()
      let colored = []
      
      let total = this.all.length

      return new Promise((resolve) => {
        this.all.forEach(async cc => {
          let ids = await this.findIds(cc)
          let clearColorize = this.selected.includes(cc.uuid) ? false : true
          
          if (clearColorize) {
            ids.forEach(id => cleared.add(id))
          }
          else {
            colored.push(ids)
          }
  
          total--
  
          if (total == 0) {
            resolve(colored.flat())
          }
        })
      })
    },

    async recolorizeAll () {
      let cleared = new Set()
      let colored = new Map()
      
      let total = this.all.length
      
      this.all.forEach(async (cc, index) => {
        let ids = await this.findIds(cc)
        let clearColorize = this.selected.includes(cc.uuid) ? false : true
        
        if (clearColorize) {
          ids.forEach(id => cleared.add(id))
        }
        else {
          let color = hexToRgb(cc.color)
          colored.set(index, {color, ids})
        }

        total--

        if (total == 0) {
          this.startRecolorize(cleared, colored)
        }
      })
      
    },
    
    startRecolorize (cleared, colored) {
      XeokitMediator.ElementsSettings.setElementsColorized(Array.from(cleared), null)
      
      const coloredSize = colored.size

      for (let i = 0; i < coloredSize; i++) {
        const colorItem = colored.get(i)
        XeokitMediator.ElementsSettings.setElementsColorized(colorItem.ids, colorItem.color)
      }
    },

    async findIds (cc) {
      if (cc.type.value == ColorCodingType.CLASSES) {
        return this.classIds(cc.items)
      }

      if (cc.type.value == ColorCodingType.MODELS) {
        return this.modelIds(cc.items)
      }
      
      if (cc.type.value == ColorCodingType.GROUPS) {
        return await this.groupIds(cc.items)
      }
    },

    classIds (items) {
      let list = []
      items.forEach(type => {
        let objects = XeokitMediator.viewer.metaScene.metaObjectsByType[type] ?? []
        let ids = Object.keys(objects)
        list = list.concat(ids)
      })
      return list
    },

    modelIds (items) {
      let list = []
      const workModel = vuexStore.state.project.projectSettings.workModel ?? []
      console.log(items)
      
      
      items.forEach(modelUuid => {
        let revisionUuid = workModel[modelUuid]?.revisionUuid
        console.log(revisionUuid)
        if (revisionUuid) {
          console.log(XeokitMediator.viewer.metaScene)
          console.log(XeokitMediator.viewer.metaScene.metaModels)
          let model = XeokitMediator.viewer.metaScene.metaModels[revisionUuid]
          let ids = model?.metaObjects.map(obj => obj.id) ?? []
          list = list.concat(ids)
        }
      })
      return list
    },

    async groupIds (items) {
      let list = []

      for (const groupUuid of items) {
        let ids = await this.fetchGroupElements(groupUuid)
        list = list.concat(ids)
      }

      // items.forEach(async groupUuid => {
      //   let ids = await this.fetchGroupElements(groupUuid)
      //   list = list.concat(ids)
      // })
      return list
    },

    async colorizeActive () {
      this.all.forEach(cc => {
        let clearColorize = this.selected.includes(cc.uuid) ? false : true
        let color = clearColorize ? null : hexToRgb(cc.color)

        if (cc.type.value == ColorCodingType.CLASSES) {
          this.colorizeClasses(cc.items, color)
        }

        if (cc.type.value == ColorCodingType.MODELS) {
          this.colorizeModels(cc.items, color)
        }

        if (cc.type.value == ColorCodingType.GROUPS) {
          this.colorizeGroups(cc.items, color)
        }
      })
    },

    colorizeClasses (items, color) {
      items.forEach(type => {
        let list = XeokitMediator.viewer.metaScene.metaObjectsByType[type]
        if (!list || list.length == 0) return

        let ids = Object.keys(list)
        XeokitMediator.ElementsSettings.setElementsColorized(ids, color)
      })
    },

    colorizeModels (items, color) {
      const workModel = vuexStore.state.project.projectSettings.workModel
      console.log(workModel)
      if (!workModel || workModel.length == 0) return

      items.forEach(modelUuid => {
        let revisionUuid = workModel[modelUuid]?.revisionUuid
        if (revisionUuid) {
          let model = XeokitMediator.viewer.metaScene.metaModels[revisionUuid]
          let ids = model?.metaObjects.map(obj => obj.id) ?? []
          XeokitMediator.ElementsSettings.setElementsColorized(ids, color)
        }
      })
    },

    colorizeGroups (items, color) {
      items.forEach(async groupUuid => {
        let ids = await this.fetchGroupElements(groupUuid)
        XeokitMediator.ElementsSettings.setElementsColorized(ids, color)
      }) 
    },

    async fetchGroupElements (groupUuid) {
      let projectUuid = vuexStore.getters['project/projectUuid']
      let hashProject = vuexStore.getters['project/hashProject']
      let tree = await api.axes.fetchElementTree(projectUuid, groupUuid, true, hashProject)
      let onlyEndpoints = tree.reduce(AxisUtils.onlyEndpointsReducer, [])
      return onlyEndpoints.map(item => item.uuid)
    },

    async fetchViewerColorCodings () {
      let projectUuid = vuexStore.getters['project/projectUuid']
      let list = await api.viewerTools.fetchColorCodings(projectUuid)
      this.all = list.sort((a, b) => a.title.localeCompare(b.title))
    },

    addColorCoding (title, color, type, items) {
      let cc = new ColorCoding({ title, color, type, items })
      
      let projectUuid = vuexStore.getters['project/projectUuid']
      api.viewerTools.addColorCoding(projectUuid, cc).then(addedCC => {
        this.all.push(addedCC)
        this.all.sort((a, b) => a.title.localeCompare(b.title))

        this.selected.push(addedCC.uuid)
        this.updateVisible(this.selected)
      })
    },
    
    updateColorCoding(title, color, type, items, uuid) {
      let cc = new ColorCoding({ title, color, type, items, uuid })
      api.viewerTools.updateColorCoding(cc).then(updatedCC => {
        this.all = this.all.map(aCC => aCC.uuid === updatedCC.uuid ? updatedCC : aCC)
        this.all.sort((a, b) => a.title.localeCompare(b.title))

        if (this.selected.includes(updatedCC.uuid) == false) {
          this.selected.push(updatedCC.uuid)
        }
        this.updateVisible(this.selected)
      })
    },

    async remove (item) {
      let projectUuid = vuexStore.getters['project/projectUuid']
      await api.viewerTools.removeColorCoding(projectUuid, item.uuid)

      if (this.selected.includes(item.uuid)) {
        this.selected = this.selected.filter(uuid => uuid != item.uuid)
        this.updateVisible(this.selected)
      }

      this.all = this.all.filter(cc => cc.uuid != item.uuid)
    },

    selectAll () {
      let uuids = this.all.map(item => item.uuid)
      this.updateVisible(uuids)
    },
    
    deselectAll () {
      let uuids = []
      this.updateVisible(uuids)
    },

    updateVisible(uuids) {
      this.selected = uuids
      let projectUuid = vuexStore.getters['project/projectUuid']
      debouncedSaveActiveColorCodings(projectUuid, uuids)
      // this.colorizeActive()
      this.recolorizeAll()
    },
  }
})