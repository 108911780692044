import { useDistanceMeasurementStore } from '@/pinia'
import { VTF } from '@/plugins/viewerTools/VTF'
import store from '@/store'

/*eslint-disable no-dupe-class-members*/
export class DistanceMeasurement {

  static get #_distanceMeasurementStore() {
    return useDistanceMeasurementStore()
  }

  /**
   * Текущая активность измерения.
   * @returns {Boolean}
   */
  static get isDistanceMeasurementActive() {
    return this.#_distanceMeasurementStore.distanceMeasurementActive
  }

  /**
   * Текущая активность обнаружения коллизий.
   * @returns {Boolean}
   */
  static get isCollisionDetectActive() {
    return this.#_distanceMeasurementStore.collisionDetectActive
  }

  /**
   * Текущие настройки измерений.
   * @returns {Object}
   */
  static get distanceMeasurementNumberSettings() {
    //TODO: Создать в store getNumberSettings - то есть разделить отдельные настройки ИЛИ везде в зависимостях указывать нужное поле настроек
    return store.getters['authUser/getRulerSettings']
  }

  /**
   * Текущие измерения.
   * @returns {Object[]}
   */
  static get distanceMeasurements() {
    return this.#_distanceMeasurementStore.distanceMeasurements
  }

  static get measurementsDirty() {
    return VTF.DistanceMeasurement.measurementsDirty
  }

  static init() {
    VTF.DistanceMeasurement.init()
  }

  /**
   * Активировать функцию измерения.
   */
  static activate() {
    VTF.DistanceMeasurement.activate()
    this.#_distanceMeasurementStore.setDistanceMeasurementActive(true)
  }

  /**
   * Деактивировать функцию измерения.
   */
  static deactivate() {
    VTF.DistanceMeasurement.deactivate()
    this.#_distanceMeasurementStore.setDistanceMeasurementActive(false)
    // this.#_distanceMeasurementStore.$reset()
  }

  /**
   * Установить активность функции обнаружения коллизий.
   * @param {Boolean} collisionDetectActive
   */
  static setCollisionDetectActive(collisionDetectActive) {
    this.#_distanceMeasurementStore.setCollisionDetectActive(collisionDetectActive)
  }

  static createFullDistanceMeasurement(params) {
    VTF.DistanceMeasurement.createFullDistanceMeasurement(params)
  }

  /**
   * Сохранить измерения в pinia store.
   * @param {Object[]} distanceMeasurements
   */
  static setDistanceMeasurements() {
    const rulerMeasurements = Object.values(VTF.DistanceMeasurement.measurementsDirty)
    const regimeMeasurements = Object.values(VTF.RegimeMeasurement.measurementsDirty)

    const distanceMeasurements = rulerMeasurements.concat(regimeMeasurements)
    const distanceMeasurementShorts = distanceMeasurements.map(distanceMeasurement => (
      {
        id: distanceMeasurement.id,
        mode: distanceMeasurement.mode,
        xLength: distanceMeasurement.xLength, 
        yLength: distanceMeasurement.yLength, 
        zLength: distanceMeasurement.zLength, 
        length: distanceMeasurement.length,
        visible: distanceMeasurement.visible,
        isAutoGenerated: distanceMeasurement.isAutoGenerated,
        originWorldPos: distanceMeasurement.origin.worldPos,
        targetWorldPos: distanceMeasurement.target.worldPos,
        createDate: distanceMeasurement.createDate
      })
    ).sort((a, b) => b.createDate - a.createDate)
    this.#_distanceMeasurementStore.setDistanceMeasurements(distanceMeasurementShorts)
  }

  /**
   * Создать измерение.
   * @param {Number[]} originWorldPos Первая точка измерения в формате World-pos [x, y, z].
   * @param {Number[]} targetWorldPos Вторая точка измерения в формате World-pos [x, y, z].
   * @param {Boolean} visible Видимость измерения
   */
  static createDistanceMeasurement(originWorldPos, targetWorldPos, visible = true) {
    VTF.DistanceMeasurement.createDistanceMeasurement(originWorldPos, targetWorldPos, visible)
  }

  /**
   * Обновить все измерения.
   */
  static updateAllDistanceMeasurement() {
    VTF.DistanceMeasurement.updateAllDistanceMeasurement()
  }

  /**
   * Удалить указанные измерения.
   * @param {Object[]} distanceMeasurements=[] Измерения.
   */
  static removeDistanceMeasurements(distanceMeasurements = []) {
    VTF.DistanceMeasurement.removeDistanceMeasurements(distanceMeasurements)
  }

  /**
   * Установить видимость указанных измерений.
   * @param {Object[]} distanceMeasurements=[] Измерения.
   * @param {Boolean} visible True/False
   */
  static setDistanceMeasurementsVisible(distanceMeasurements = [], visible) {
    VTF.DistanceMeasurement.setDistanceMeasurementsVisible(distanceMeasurements, visible)
  }

  static clearAllMeasurement() {
    VTF.DistanceMeasurement.clearAllMeasurement()
  }
}