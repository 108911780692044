export default ({ Factory, Presets}) => ({

  getTariffCloud: () => {
    return Factory.cloud.tariff.get(Presets.GUEST)
  },

  /**
   * Получить список всех своих проектов 
   * и проектов, где пользователь является частью команды
   */
  getAvailableProjects: () => {
    return Factory.projects.mylist.get()
  },

  /**
   * Изменить превью проекта
   * @param {String} uuid UUID проекта
   * @param {File} file Файл (изображение)
   */
  setPreview: (uuid, file) => {
    let formData = new FormData()
    formData.append("file", file)
    return Factory.projects[uuid].preview.post(formData, Presets.headers.MULTIPART)
  },

  /**
   * Изменить облачный корень проекта
   * @param {String} uuid UUID проекта
   * @param {path} path папка проекта в облаке
   */
  setCloudPath: (uuid, path) => {
    /*let formData = new FormData()
    formData.append("path", path)*/
    return Factory.projects[uuid].cloudpath.patch({path: path}, Presets.headers.MULTIPART)
  },

//   Дополнительно для гостевых проектов

// GET .../projects/{projectUuid}/tree -> получение дерева моделей проекта
// PATCH .../projects/{cloudProjectUuid}/convert -> преобразование гостевого проекта в персональный
// POST .../revisions/move (Body: UUID modelUuid, UUID cloudProjectUuid)  -> перемещение модели из гостевого проекта в выбранную из структуры модель и сделать ее тукущей

  /** 
   * Пполучение дерева моделей проекта
   * @param {String} projectUuid UUID проекта
   */
  getTreeModel: (projectUuid) => {
    return Factory.projects[projectUuid].tree.get()
  },

  /** 
   * преобразование гостевого проекта в персональный
   * @param {String} modelUuid UUID гостевого проекта
   * @param {String} hashProject hash проекта
   */
   convert: (cloudProjectUuid, hashProject) => {
    return Factory.projects[cloudProjectUuid].convert[hashProject].patch()
  },

  /** 
   * перемещение модели из гостевого проекта в выбранную из структуры модель и сделать ее тукущей
   * @param {String} modelUuid UUID модели
   * @param {String} cloudProjectUuid UUID гостевого проекта
   * @param {String} hashProject hash проекта
   */
   addModelToProject: (modelUuid, cloudProjectUuid, hashProject) => {
     console.log("modelUuid", modelUuid)
     console.log("cloudProjectUuid", cloudProjectUuid)

    let formData = new FormData()
    formData.append("modelUuid", modelUuid)
    formData.append("cloudProjectUuid", cloudProjectUuid)
    return Factory.revisions.move[hashProject].post(formData, Presets.headers.MULTIPART)
    // Factory.revisions.move.post({modelUuid: modelUuid, cloudProjectUuid: cloudProjectUuid})
  },

  makeDemo: () => {
    return Factory.projects.demo.get()
  },

  /** 
   * Добавить файл элемента
   * @param {String} projectUuid UUID проекта
   * @param {String} elementGlobalId Глобальный ID элемента
   * @param {File} file Файл
   */
  uploadElementFile: (projectUuid, elementGlobalId, file) => {
    let formData = new FormData()
    formData.append("file", file)
    return Factory.projects.elementfile[projectUuid][elementGlobalId].post(formData, Presets.headers.MULTIPART)
  },
  /** 
   * Скачать файл элемента
   * @param {String} elementFileUuid UUID файла элемента
   */
   downloadElementFile: (elementFileUuid) => {
    return Factory.projects.elementfile[elementFileUuid].get()
  },
  /** 
   * Удалить файл элемента
   * @param {String} elementFileUuid UUID файла элемента
   */
   deleteElementFile: (elementFileUuid) => {
    return Factory.projects.elementfile[elementFileUuid].delete()
  },

  cloudListProjectsByHash: (hashProject) => {
    return Factory.cloud.projects[hashProject].get()
  },


  // REVISIONS
  // /revisions/{revisionOneUuid}/compare/{revisionTwoUuid}
  /** 
   * Сравнение ревисий
   * @param {String} firstUuid UUID первой ревизии
   * @param {String} secondUuid UUID второй ревизии
   */
  compareRevisions: (firstUuid, secondUuid) => {
    return Factory.revisions[firstUuid].compare[secondUuid].get()
  },
  
  // PATCH

  /** 
   * Получить список патчей
   * @param {String} projectUuid UUID проекта
   */
  getPatchList: (projectUuid) => {
    return Factory.patches.project[projectUuid].listpatchs.get()
  },

  /** 
   * Добавить патч
   * @param {String} projectUuid UUID проекта
   * @param {File} patchData.ifcFile Файл IFC патча
   * @param {Object} patchData.params Параметры 
   * @param {String} patchData.params.name Название
   * @param {String} patchData.params.annotation Аннотация
   * @param {String[]} patchData.params.elements Заменяемые элементы
   */
  createPatch: (projectUuid, patchData) => {
    let formData = new FormData()
    formData.append("projectUuid", projectUuid)
    formData.append("file", patchData.ifcFile)
    formData.append("annotation", patchData.params.annotation)
    formData.append("name", patchData.params.name)
    formData.append("elements", JSON.stringify(patchData.params.elements))
  
    return Factory.patches.post(formData, Presets.headers.MULTIPART)
  },

  /** 
   * Изменить данные патча
   * @param {String} patch Патч
   */
  editPatch: (patch) => {
    return Factory.patches.patch(patch)
  },

  /** 
   * Изменить данные патча
   * @param {String} patchUuid UUID патча
   */
  deletePatch(patchUuid) {
    return Factory.patches[patchUuid].delete()
  },

  /** 
   * Вкл/выкл патча
   * @param {String} patchUuid UUID патча
   */
  switchActivatePatch(patchUuid) {
    return Factory.patches[patchUuid].switchactivate.post()
  },

   /** 
  * Сохранение добавляемых элементов
  * @param {String} patchUuid UUID добавляемого элемента
  * @param {String[]} elements Заменяемые элементы
  */
  editPatchElements(patchUuid, elements) { 
    let formData = new FormData()
    formData.append("elements", elements)
    return Factory.patches[patchUuid].editpatchelements.patch(formData, Presets.headers.MULTIPART)
  },

   /** 
  * Добавить файл патча
  * @param {String} patchUuid UUID добавляемого элемента
  * @param {File} file Файл IFC патча
  */
  editPatchFile: (patchUuid, file) => {
    let formData = new FormData()
    formData.append("file", file)
    return Factory.patches[patchUuid].editpatchfile.post(formData, Presets.headers.MULTIPART)
  },
   /** 
  * Ожидание загрузки файла патча
  * @param {String} patchUuid UUID добавляемого элемента
  */
  getPatch: (patchUuid) => {
    return Factory.patches[patchUuid].get()
  },

  getPatches: (projectUuid) => {
    return Factory.patches.project[projectUuid].get()
  },

  /** 
  * Удалить файл патча
  * @param {String} patchUuid UUID удаляемого патча
  */
  deletePatchFile: (patchUuid) => {
    return Factory.patches[patchUuid].patchfile.delete()
  },

  /** 
  * Изменение координат модели изменений
  * @param {String} patchUuid UUID удаляемого патча
  */

  editPatchOffset: (patchUuid, formData) => {
    return Factory.patches[patchUuid].editpatchoffset.put(formData)
  },

  globalIdToIcfGlobalId: (ids, projectUuid) => {
    return Factory.projects[projectUuid].globalidtoifcid.post(ids)
  },

  icfGlobalIdToGlobalId: (ids, projectUuid) => {
    return Factory.projects[projectUuid].ifcglobalidtoglobalid.post(ids)
  },

  elementFlatWithFiles: (ids, projectUuid) => {
    return Factory.projects[projectUuid].elementflatwithfiles.post(ids)
  },

  /**
   * Запрос токена проекта
   * @param {*} uuid uuid проекта
   * @returns 
   */
  getTokenProject: (uuid) => {
    return Factory.projects.token[uuid].get()
  },

  /**
   * Запрос на получение проектов организации
   * @param {String} uuid проекта
   */
  getListOrganizationProjects: (uuid) => {
    return Factory.projects.organizations[uuid].projects.get()
  }

})