export default ({ Factory }) => ({

  getProjectDicts(projectUuid) {
    return Factory.modelclassification.projectdicts[`?projectUuid=${projectUuid}`].get()
  },

  getProjectDictAttributeTree(projectDictUuid) {
    return Factory.modelclassification.projectdicts[projectDictUuid].list.get()
  },

  createProjectDict(projectDict) {
    return Factory.modelclassification.projectdicts.create.post(projectDict)
  },

  createAttributeGroup(attributeGroup, projectDictUuid) {
    return Factory.modelclassification.projectdicts[projectDictUuid].addgroup.post(attributeGroup)
  },

  createAttribute(attribute, attributeGroupUuid) {
    return Factory.modelclassification.projectdicts.group[attributeGroupUuid].addattribute.post(attribute)
  },

  editAttribute(attribute) {
    // return Factory.modelclassification.projectdicts.addattribute[attribute.uuid].edit.post(attribute)
    return Factory.modelclassification.projectdicts.attribute[attribute.uuid].edit.post(attribute)
  },

  hightLightGroup(projectUuid, attributeUuid) {
    return Factory.modelclassification.hightlightgroup[`?projectUuid=${projectUuid}&attributeUuid=${attributeUuid}`].get() 
  },

  getUnits() {
    return Factory.modelclassification.units.get()
  },

  getPowerTypes() {
    return Factory.modelclassification.powertypes.get()
  },

  classificate(by) {
    return Factory.modelclassification.projectdicts.classificate.post(by)
  },

  deleteItem(element) {
    return Factory.modelclassification.projectdicts.deleteitem[element].delete()
  },

  deleteDict(dict) {
    return Factory.modelclassification.projectdicts[dict].delete()
  }
})