import store from '@/store'

/*eslint-disable no-dupe-class-members*/
export class PanelController {

  static get #_panels() {
    return store.state.layoutControler.panels
  }

  static get #_activeGlobalTab() {
    return store.state.project.activeGlobalTab
  }

  static get #_leftPanel() {
    return document.getElementById('left-panel')
  }

  static get #_rightPanel() {
    return document.getElementById('right-panel')
  }

  static get #_bottomPanel() {
    return document.getElementById('bottom-panel')
  }

  static get #_topPanel() {
    return document.getElementById('top-panel')
  }

  static #_leftPanelObserver = null
  static #_rightPanelObserver = null
  static #_bottomPanelObserver = null

  static initPanels() {
    this.#_leftPanelObserver = new ResizeObserver(() => {
      let leftPanelSize = this.#_leftPanel.clientWidth
      // let elem = document.getElementById('measurementScrim')
      // let style = elem ? window.getComputedStyle(elem) : null
      // let padding = style ? style.getPropertyValue('padding-left') : "0px"
      // let paddingValue = padding ? padding.replace("px", "") : 0
      document.getElementById('measurementScrim').style.setProperty('--left-panel-width',  leftPanelSize + "px")
      // if(leftPanelSize < paddingValue) this.setPanelPadding(true)
    })

    this.#_rightPanelObserver = new ResizeObserver(() => {
      let rightPanelSize = this.#_rightPanel.clientWidth
      // let elem = document.getElementById('measurementScrim')
      // let style = elem ? window.getComputedStyle(elem) : null
      // let padding = style ? style.getPropertyValue('padding-right') : "0px"
      // let paddingValue = padding ? padding.replace("px", "") : 0

      document.getElementById('measurementScrim').style.setProperty('--right-panel-width',  rightPanelSize + "px")
      // if(rightPanelSize < paddingValue) this.setPanelPadding(true)
    })

    this.#_bottomPanelObserver = new ResizeObserver(() => {
      if(this.#_activeGlobalTab != 'collision') this.setPanelPadding(true, false)
    })

    this.#_leftPanelObserver.observe(this.#_leftPanel)
    this.#_rightPanelObserver.observe(this.#_rightPanel)
    this.#_bottomPanelObserver.observe(this.#_bottomPanel)

    let layers = {
      right: this.#_rightPanel,
      left: this.#_leftPanel,
      top: this.#_topPanel,
      bottom: this.#_bottomPanel
    }

    store.dispatch('layoutControler/initLayers', layers)
    store.dispatch('authUser/LOAD_CURRENT_USER')
  }

  static setPanelPadding(on, all = true) {
    if(on){
      let bottomHeight = document.getElementById('collisiontable')?.offsetHeight || document.getElementById('plugintable')?.offsetHeight || document.getElementById('attributecheckingtable')?.offsetHeight || 0
      let topHeight = document.getElementById('page-header')?.offsetHeight || 0

      let paddingBottom = (bottomHeight - topHeight) > 0 ? bottomHeight - topHeight : 0
      let existBottomPanel = this.#_panels.filter(panel => panel.exist && panel.orientation === "BOTTOM")
      if (existBottomPanel.size > 0) {
        paddingBottom = (bottomHeight - topHeight) > 0 ? bottomHeight : 0
      }
      
      if(all) {
        let width = 0
        let ProjectInstrumentPanelWidth = 40
        let existLeftPanel = this.#_panels.filter(panel => panel.exist && panel.orientation === "LEFT")
        if (existLeftPanel.size > 0) {
          width = this.#_leftPanel?.clientWidth - ProjectInstrumentPanelWidth
        }
        document.getElementById('measurementScrim')?.style.setProperty('padding-left', width + "px")
        document.getElementById('measurementScrim')?.style.setProperty('padding-right', this.#_rightPanel.clientWidth + "px")
      }
      document.getElementById('measurementScrim')?.style.setProperty('padding-bottom', paddingBottom + "px")
    }
    else {
      document.getElementById('measurementScrim').style.setProperty('padding-left', "0")
      document.getElementById('measurementScrim').style.setProperty('padding-right', "0")
      document.getElementById('measurementScrim').style.setProperty('padding-bottom', "0")
    }
  }

  static clearResizeObservers() {
    this.#_rightPanelObserver?.disconnect()
    this.#_leftPanelObserver?.disconnect()
    this.#_bottomPanelObserver?.disconnect()
  }
}