import { useSelectedElementsStore } from '@/pinia'
import { VTF } from '@/plugins/viewerTools/VTF'

/*eslint-disable no-dupe-class-members*/
export class ElementsSelection {

  static get #_selectedElementsStore() {
    return useSelectedElementsStore()
  }

  /** Выбранные элементы */
  static get selectedElements() {
    return this.#_selectedElementsStore.selectedElements
  }

  /** Активный элемент модели */
  static get pickedElement() {
    return this.#_selectedElementsStore.pickedElement
  }

  /** Скрытие выбранных элементов */
  static get isHideSelectedElements() {
    return this.#_selectedElementsStore.isHideSelectedElements
  }
  
  /** Отображение только выбранных элементов */
  static get isShowOnlySelectedElements() {
    return this.#_selectedElementsStore.isShowOnlySelectedElements
  }

  static setSelectedElements(selectedElements) {
    this.#_selectedElementsStore.setSelectedElements(selectedElements)
  }

  static setPickedElement(pickedElement) {
    return this.#_selectedElementsStore.setPickedElement(pickedElement)
  }
  
  /** Скрывать выделенные элементы */
  static setHideSelectedElements(hideSelectedElements) {
    this.#_selectedElementsStore.setHideSelectedElements(hideSelectedElements)

    if (hideSelectedElements) {
      this.#_selectedElementsStore.setShowOnlySelectedElements(false)

      VTF.SelectElements.hideSelectedElements()
    }
    else {
      VTF.SelectElements.showAllElements()
    }

  }

  /** Отображать только выделенные элементы */
  static setShowOnlySelectedElements(showOnlySelectedElements) {
    this.#_selectedElementsStore.setShowOnlySelectedElements(showOnlySelectedElements)

    if (showOnlySelectedElements) {
      this.#_selectedElementsStore.setHideSelectedElements(false)

      VTF.SelectElements.showOnlySelectedElements()
    }
    else {
      VTF.SelectElements.showAllElements()
    }
  }

  /** Выбор элементов модели
   * @param {Array<String>} [elementUuids = []] Массив UUID'ов выделяемых элементов
   */
  static selectElements(elementUuids = []) {
    VTF.SelectElements.selectElements(elementUuids)
  }

  static unselectElements(elementsUuids = []) {
    VTF.SelectElements.unselectElements(elementsUuids)
  }

  /** Последовательный выбор элементов модели
   * @param {String} [elementUuid = null] UUID первого/следующего выделяемого элемента
   */
  static addSelectedElement(elementUuid) {
    VTF.SelectElements.addSelectedElement(elementUuid)
  }

  /** Последовательный выбор элементов модели
   * @param {String} [elementUuid = null] UUID первого/следующего выделяемого элемента
   */
  static removeSelectedElement(elementUuid) {
    VTF.SelectElements.removeSelectedElement(elementUuid)
  }

  /** Выбор активного элемента модели
   * @param {Array<String>} [elementUuid = null] UUID выделяемого активного элемента
   */
  static pickElement(elementUuid = null) {
    VTF.SelectElements.pickElement(elementUuid)
  }

  static dropAll() {
    VTF.SelectElements.dropAll()
  }

  static onSelectByShift() {
    VTF.SelectElements.onSelectByShift()
  }

  static selectByShiftRemove() {
    VTF.SelectElements.selectByShiftRemove()
  }

  static showAllElements() {
    VTF.SelectElements.showAllElements()
  }
}