import { defineStore } from 'pinia'
import { api } from '@/api'
import i18n from '@/plugins/i18n'
import debounce from 'lodash/debounce'

import AttributeHighlightService from '@/components/project/panel/left/components/attributeChecking/AttributeHighlightService'
import vuexStore from '@/store'

import { projectService } from '@/_services'

export const defaultHeaders = () => [
  {
    order: 1,
    text: i18n.t('section.collision.attrTable.headers.rule'),
    value: 'rule',
    view: true,
    width: 270,
    align: "left",
  },
  {
    order: 2,
    text: i18n.t('section.collision.attrTable.headers.attr'),
    value: 'attr',
    view: true,
    width: 400,
    align: "left",
  },
  {
    order: 3,
    text: i18n.t('section.collision.attrTable.headers.condition'),
    value: 'condition',
    view: true,
    width: 400,
    align: "left",
  },
  {
    order: 4,
    text: i18n.t('section.collision.attrTable.headers.count'),
    value: 'countElement',
    view: true,
    width: 150,
    align: "left",
  },
]

const defaultFilter = {
  projectUuid: null,
  collisionRules: [],
  collisionRule: null,
  collisionAttr: null,
  collisionAttrList: [],
  taskUuid: null,
}

const getHeaders = () => {
  return defaultHeaders()
}

const getDefaultState = () => {
  return {
    items: [],
    itemsBuffer: [],
    headers: getHeaders(),

    task: {
      active: false,
      taskId: null,
    },

    selectedElement: null,
    selectedAtrrOrRuleUuid: null,
    tableIsLoading: false,
  }
}

export const useAttributeCheckingTableStore = defineStore('attributeTable', {
  state: getDefaultState,

  getters: {
    visibleHeaders() {
      return this.headers.filter((o) => o.view).sort((a, b) => b.order - a.order)
    },

    itemsWithoutHidden() {
      return this.items.filter((el) => !el.hidden)
    },

    selectedElementId() {
      return this.selectedElement?.globalId
    },
  },

  actions: {
    async getCollisionAttrRules(rules = null) {
      if (vuexStore.getters['rule/markedAttrRules']?.length === 0) return

      this.tableIsLoading = true
      let filter = Object.assign({}, defaultFilter)
      filter.projectUuid = vuexStore.getters['project/projectUuid']
      if (!rules) rules = vuexStore.getters['rule/selectedAttrRules']

      filter.collisionRules = rules.filter((rule) => rule.countCollisions > 0).map((rule) => rule.uuid)

      api.collisions.attrSearch(filter).then((data) => {
        let tableItems = this.prepareAttributeItems(data)
        if (this.task.active) this.itemsBuffer = tableItems
        else {
          this.items = tableItems
          this.itemsBuffer = []
        }
        this.tableIsLoading = false
      })
    },

    getColumnWidth() {
      let vuexVisibleHeaders = vuexStore.getters['collision/table/attrTableVisibleHeaders']
      let arr = []
      let headersValues = new Set(defaultHeaders().map(item => item.value))

      for (let i = 0; i < vuexVisibleHeaders?.length; i++) {
        if (headersValues.has(vuexVisibleHeaders[i].value)) {
          arr.push(vuexVisibleHeaders[i]);
        }
      }
      this.headers = arr
    },
    
    async getElements(attribute) {
      this.tableIsLoading = true
      let filter = Object.assign({}, defaultFilter)
      filter.projectUuid = vuexStore.getters['project/projectUuid']
      filter.collisionAttr = attribute.uuid

      return api.collisions.attrElementSearch(filter).then((data) => {
        attribute.elementsloaded = true
        this.addElementsToItems(attribute, data)
        this.tableIsLoading = false
        return data
      })
    },

    async getTaskAttrRules(taskUuid = null, collisionRuleUuid) {
      if (!taskUuid) taskUuid = this.task.taskId
      this.tableIsLoading = true

      let filter = Object.assign({}, defaultFilter)
      filter.projectUuid = vuexStore.getters['project/projectUuid']
      filter.taskUuid = taskUuid

      api.collisions.attrTaskSearch(filter).then((data) => {
        this.items = this.prepareAttributeItems(data)
        if (collisionRuleUuid) this.openRuleAttribute(collisionRuleUuid)

        this.task.active = true
        this.task.taskId = taskUuid

        this.tableIsLoading = false
      })
    },

    changeItemsSource(isTask, taskUuid = null, collisionRuleUuid = null) {
      if (taskUuid) this.task.taskId = taskUuid
      if (isTask && !this.task.active) this.itemsBuffer = this.items
      this.task.active = isTask
      AttributeHighlightService.restoreSelectedObjects()

      if (isTask) this.getTaskAttrRules(null, collisionRuleUuid)
      else {
        if (!this.itemsBuffer || this.itemsBuffer.length === 0) {
          this.getCollisionAttrRules()
        } else {
          this.items = this.itemsBuffer.map((item) => {
            item.selected = false
            return item
          })
          this.itemsBuffer = []
        }
      }
    },

    async openRuleAttribute(collisionRuleUuid) {
      if (!collisionRuleUuid) return
      let item = this.items.find((item) => item.uuid == collisionRuleUuid)
      if (item) {
        item.isOpen = true
        this.changeItemsVisible(item)
      }
    },

    saveHeader(id, width) {
      let item = this.headers.find((field) => field.value === id)
      if (item) item.width = width
      debounced_sendUpdatedColumn(item)
    },

    saveHeaderAlign(header) {
      let item = this.headers.find((field) => field.value === header.value)
      if (item) item.align = header.align
    },

    resetHeaders() {
      this.headers = defaultHeaders()
    },

    addElementsToItems(attribute, elements) {
      let arr = elements.map((el) => {
        let ei = new ElementItem(el)
        ei.selected = attribute.selected
        return ei
      })
      this.items.splice(attribute.index + 1, 0, ...arr)
      this.items = this.items.map((el, index) => {
        el.index = index
        return el
      })
    },

    prepareAttributeItems(items) {
      let arr = []
      items.forEach((el) => {
        arr.push(new RuleItem(el))
        el?.attribute.forEach((el) => {
          arr.push(new AttributeItem(el))
        })
      })

      return arr.map((el, index) => {
        el.index = index
        return el
      })
    },

    changeItemsVisible(item, hidden = false) {
      let rootlevel = item.level
      for (let i = item.index + 1; i < this.items.length; i++) {
        if (this.items[i].level <= rootlevel) break //скрываем всё дочернее
        if (!hidden && this.items[i].level > rootlevel + 1) continue //раскрываем только прямых детей рута
        this.items[i].hidden = hidden
        this.items[i].isOpen = false
      }
    },

    toggleAllGroup(open = false) {
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i]
        if (open) {
          if (item.level > 1) continue
          item.hidden = false
          if (item.level == 0) item.isOpen = true
        } else {
          item.isOpen = false
          if (item.level != 0) item.hidden = true
        }
      }
    },

    exportToReport(fileType) {
      let option = {}
      option.fileType = { value: fileType }
      option.collisionAttr = true
      option.rulesUuid = vuexStore.getters['rule/selectedAttrRules'].filter((rule) => rule.countCollisions > 0).map((rule) => rule.uuid)
      if (this.task.active) {
        option.taskCollision = true
        option.taskUuid = this.task.taskId
      }

      api.collisions.initCollisionsReportProcess(vuexStore.getters['project/projectUuid'], option)
    },

    async loadSelectedElement(globalId) {
      if (globalId && globalId != '_') {
        projectService.loadElementByGlobal(globalId, vuexStore.getters['project/projectUuid']).then((element) => {
          this.selectedElement = buildElement(element)
        })
      }
    },

    async clearSelection() {
      this.selectedElement = null
      this.items.forEach(item => item.selected = false)
    },

    async resetAll() {
      Object.assign(this, getDefaultState())
    },
  },
})

class BaseItem {
  constructor(el, index = 0) {
    this.uuid = el.uuid
    this.isOpen = false
    this.index = index
    this.hidden = false
    this.selected = false
  }
}

class RuleItem extends BaseItem {
  constructor(el, index) {
    super(el, index)
    this.title = el.title
    this.countElement = el.countElement
    this.level = 0
  }
}

class AttributeItem extends BaseItem {
  constructor(el, index) {
    super(el, index)
    this.attribute = el.attribute
    this.countElement = el.countElement
    this.globalIds = el.globalIds
    this.collisionRule = el.collisionRule
    this.attrName = el.attrName
    this.attrFullName = el.attrFullName
    this.operand = el.operand
    this.elementsloaded = false
    this.hidden = true
    this.level = 1
  }
}

class ElementItem extends BaseItem {
  constructor(el, index) {
    super(el, index)
    this.uuid = el.globalId
    this.name = el.name || "No name"
    this.level = 2
  }
}

function buildElement(element) {
  if (element) {
    if (element.geometryInfo) {
      let json_data = JSON.parse(element.geometryInfo.additionalData)
      element.geometry = Object.keys(json_data).map((key) => ({
        name: key,
        doubleValue: json_data[key],
        stringValue: '' + json_data[key],
        unit:
          key.indexOf('_AREA') > 0
            ? '{unit.shortName.square_metre}'
            : key.indexOf('_SIZE') > 0
            ? '{unit.shortName.metre}'
            : key.indexOf('_VOLUME') > 0
            ? '{unit.shortName.cubic_metre}'
            : '',
      }))
    }
    element.details = element.children.sort(
      (a, b) => (a.children.length > 0 ? 1 : 0) - (b.children.length > 0 ? 1 : 0) || a.name.localeCompare(b.name)
    )
    element.details.forEach((el) => {
      if (el.children.length > 0) el.children.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
    })
  }

  return element
}

const debounced_sendUpdatedColumn = debounce((header) => api.collisionSettings.updateTableHeader(header), 500)