import { math as mathematics } from "@xeokit/xeokit-sdk"

export const math = {

  addVec3(v, u) {
    const v0 = v[0]
    const u0 = u[0]
    const v1 = v[1]
    const u1 = u[1]
    const v2 = v[2]
    const u2 = u[2]
    const result = []
    result[0] = v0 + u0
    result[1] = v1 + u1
    result[2] = v2 + u2
    return result
  },

  subVec3(v, u) {
    return [v[0] - u[0], v[1] - u[1], v[2] - u[2]]
  },

  dotVec3(v, u) {
    return v[0] * u[0] + v[1] * u[1] + v[2] * u[2]
  },

  mulVec3Scalar(v, a) { //TODO: то же самое
    return [v[0] * a, v[1] * a, v[2] * a];
  },

  lenVec3(v) {
    return Math.sqrt(this.sqLenVec3(v));
  },

  sqLenVec3(v) {
    return this.dotVec3(v, v);
  },

  normalizeVec3(v) {
    const f = 1.0 / this.lenVec3(v);
    return this.mulVec3Scalar(v, f);
  },

  crossVec3(v, u) { //TODO: чем не устроил math.crossVec3()?
    return [
      v[1] * u[2] - v[2] * u[1],
      v[2] * u[0] - v[0] * u[2],
      v[0] * u[1] - v[1] * u[0]
    ];
  },

  mulMat4v3(m, v, dest = mathematics.vec4()) {
    const v0 = v[0];
    const v1 = v[1];
    const v2 = v[2];
    const v3 = 1;
    dest[0] = m[0] * v0 + m[4] * v1 + m[8] * v2 + m[12] * v3;
    dest[1] = m[1] * v0 + m[5] * v1 + m[9] * v2 + m[13] * v3;
    dest[2] = m[2] * v0 + m[6] * v1 + m[10] * v2 + m[14] * v3;
    dest[3] = m[3] * v0 + m[7] * v1 + m[11] * v2 + m[15] * v3;
    return dest;
  },

  determinantMat3(mat) {
    // Cache the matrix values (makes for huge speed increases!)
    const a00 = mat[0]
    const a01 = mat[1]
    const a02 = mat[2]
    const a10 = mat[3]
    const a11 = mat[4]
    const a12 = mat[5]
    const a20 = mat[6]
    const a21 = mat[7]
    const a22 = mat[8]

    return a00 * a11 * a22 + a01 * a12 * a20 + a02 * a10 * a21 - a02 * a11 * a20 - a01 * a10 * a22 - a00 * a12 * a21
  },

  distance(point1, point2) {
    // Вычисляем расстояние между двумя точками

    const [x1, y1, z1] = point1
    const [x2, y2, z2] = point2

    const dx = x2 - x1
    const dy = y2 - y1
    const dz = z2 - z1

    return Math.sqrt(dx * dx + dy * dy + dz * dz)
  },

  distanceBetweenByCoordinates(first, second) {
    const distance = Math.sqrt(Math.pow(first[0] - second[0], 2) + Math.pow(first[1] - second[1], 2) + Math.pow(first[2] - second[2], 2))

    return distance
  },

  frustumMat4(left, right, bottom, top, near, far) {
    let dest = mathematics.mat4();

    const rl = (right - left);
    const tb = (top - bottom);
    const fn = (far - near);

    // // как в учебнике
    // dest[0] = (near * 2) / rl
    // dest[1] = 0
    // dest[2] = 0
    // dest[3] = 0
    // dest[4] = 0
    // dest[5] = (near * 2) / tb
    // dest[6] = 0
    // dest[7] = 0
    // dest[8] = 0
    // dest[9] = 0
    // dest[10] = -(far + near) / fn
    // dest[11] = -1
    // dest[12] = -near * (right + left) / rl
    // dest[13] = -near * (top + bottom) / tb
    // dest[14] = (2 * far * near) / (near - far)
    // dest[15] = 0

    dest[0] = (near * 2) / rl;
    dest[1] = 0;
    dest[2] = 0;
    dest[3] = 0;
    dest[4] = 0;
    dest[5] = (near * 2) / tb;
    dest[6] = 0;
    dest[7] = 0;
    dest[8] = (right + left) / rl;
    dest[9] = (top + bottom) / tb;
    dest[10] = -(far + near) / fn;
    dest[11] = -1;
    dest[12] = 0;
    dest[13] = 0;
    dest[14] = -(far * near * 2) / fn;
    dest[15] = 0;

    return dest;
  },

  transposeMat4(mat) {
    // If we are transposing ourselves we can skip a few steps but have to cache some values
    const m4 = mat[4];

    const m14 = mat[14];
    const m8 = mat[8];
    const m13 = mat[13];
    const m12 = mat[12];
    const m9 = mat[9];
    
    let dest = mathematics.mat4()

    dest[0] = mat[0];
    dest[1] = m4;
    dest[2] = m8;
    dest[3] = m12;
    dest[4] = mat[1];
    dest[5] = mat[5];
    dest[6] = m9;
    dest[7] = m13;
    dest[8] = mat[2];
    dest[9] = mat[6];
    dest[10] = mat[10];
    dest[11] = m14;
    dest[12] = mat[3];
    dest[13] = mat[7];
    dest[14] = mat[11];
    dest[15] = mat[15];

    return dest;
  },
}