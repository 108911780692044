import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { MeasurementTool } from '../measurement/measurementTool'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { AlertService } from '@/assets/app/AlertService'
import i18n from '@/plugins/i18n'

/*eslint-disable no-dupe-class-members*/
export class FaceToFaceMeasurement {
  static #_originPickedFaceEdges = [] // Массив отрисованных ребер origin грани
  static #_originPickedFaceTriangles = null
  static #_originPickedFaceColor = [2.0, 0.7, 0.7] //[2.0, 0.3, 2.0]

  static #_targetPickedFaceEdges = [] // Массив отрисованных ребер target грани
  static #_targetPickedFaceTriangles = null
  static #_targetPickedFaceColor = [2.0, 1.3, 1.3] //[2.0, 1.1, 2.0]

  static #_hoveringTrianglesColor = [2.0, 0.5, 0.5]
  static #_hoveringTriangles = null

  static #_onMouseHoverSurface = null
  static #_onMouseHoverOut = null
  static #_onSceneTick = null
  static #_onInputMouseUp = null
  static #_onInputMouseDown = null
  static #_onInputKeyDown = null

  static #_mouseCanvasClickTolerance = 5
  static #_mouseDownCanvasX
  static #_mouseDownCanvasY

  static #_mouseDownLeft = false
  static #_mouseDownRight = false

  static pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedFaceTriangles = pickResult.trianglesPositions
    const pickedFaceEdges = pickResult.faceEdges

    if (pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')) {
      return
    }

    if (this.#_originPickedFaceEdges.length == 0) {
      let drawedFace = this.#_drawFace(pickedFaceEdges, pickedFaceTriangles, this.#_originPickedFaceColor)
      this.#_originPickedFaceTriangles = drawedFace.triangles
      this.#_originPickedFaceEdges = drawedFace.edges
    } 
    else {
      this.#_destroyTargetPickedFace()
      this.#_destroyMeasurement()

      const originFaceEdges = geometry.utils.edgeObjectsToEdgesArray(this.#_originPickedFaceEdges)

      let drawedFace = this.#_drawFace(pickedFaceEdges, pickedFaceTriangles, this.#_targetPickedFaceColor)
      this.#_targetPickedFaceTriangles = drawedFace.triangles
      this.#_targetPickedFaceEdges = drawedFace.edges

      const shortestSegment = geometry.faceToFaceMeasurement.findShortestSegmentBetweenFaceAndFace(originFaceEdges, pickedFaceEdges)

      MeasurementTool.createDistanceMeasurement(shortestSegment[0], shortestSegment[1])
    }
  }

  static clearAll() {
    this.#_destroyMeasurement()
    this.#_destroyOriginPickedFace()
    this.#_destroyTargetPickedFace()
  }

  static #_destroyMeasurement() {
  }

  static #_destroyHoveringTriangles() {
    this.#_hoveringTriangles?.destroy()
    this.#_hoveringTriangles = null
  }

  static #_destroyOriginPickedFace() {
    this.#_originPickedFaceEdges?.forEach((edge) => {
      edge?.destroy()
    })
    this.#_originPickedFaceEdges = []

    this.#_originPickedFaceTriangles?.destroy()
    this.#_originPickedFaceTriangles = null
  }

  static #_destroyTargetPickedFace() {
    this.#_targetPickedFaceEdges?.forEach((edge) => {
      edge?.destroy()
    })
    this.#_targetPickedFaceEdges = []

    this.#_targetPickedFaceTriangles?.destroy()
    this.#_targetPickedFaceTriangles = null
  }

  static #_drawFace(edges, triangles, color) {
    let drawedEdges = []
    edges.forEach((edge) => {
      drawedEdges.push(
        XeokitMediator.SceneObjects.createSegmentMesh({
          segmentPositions: edge,
        })
      )
    })

    let drawedTriangles = geometry.drawer.createSurfaceMesh(triangles, color)
    return { triangles: drawedTriangles, edges: drawedEdges }
  }

  static activate() {
    this.#_activateInputListeners()
    this.#_activateHoverSurfaceListener()
  }

  static #_activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#_onInputMouseDown)
    input.off(this.#_onInputMouseUp)

    this.#_onInputMouseDown = input.on('mousedown', (coords) => {
      this.#_mouseDownCanvasX = coords[0]
      this.#_mouseDownCanvasY = coords[1]
      this.#_mouseDownLeft = input.mouseDownLeft
      this.#_mouseDownRight = input.mouseDownRight
    })

    this.#_onInputMouseUp = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#_mouseDownCanvasX + this.#_mouseCanvasClickTolerance ||
        coords[0] < this.#_mouseDownCanvasX - this.#_mouseCanvasClickTolerance ||
        coords[1] > this.#_mouseDownCanvasY + this.#_mouseCanvasClickTolerance ||
        coords[1] < this.#_mouseDownCanvasY - this.#_mouseCanvasClickTolerance
      ) {
        this.#_mouseDownLeft = false
        this.#_mouseDownRight = false
        return
      }

      if (this.#_mouseDownLeft) {
        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
          needPickedFace: true,
        })

        if (pickResult) {
          if (pickResult.isSectionControl) {
            return
          }
          if (pickResult.entity.model.isCollisionIntersection) {
            AlertService.info({info: i18n.t('viewer.distanceMeasurement.collisionIntersectionException')})
            return
          }
          this.pick(pickResult)
        }
      }

      this.#_mouseDownLeft = false
      this.#_mouseDownRight = false
    })

    this.#_onInputKeyDown = input.on('keydown', (keyCode) => {
      if (keyCode == input.KEY_N) {
        this.clearAll()
      }
    })
  }

  static #_activateHoverSurfaceListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    this.#_onMouseHoverSurface = cameraControl.on('hoverSurface', (event) => {

      if (this.#_mouseDownLeft || this.#_mouseDownRight) {
        this.#_destroyHoveringTriangles()
        return
      }

      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
        needPickedFace: true,
      })

      if (!pickResult) {
        return
      }

      if (pickResult.isSectionControl) {
        return
      }

      if (pickResult.entity.model.isCollisionIntersection) {
        AlertService.info({info: i18n.t('viewer.distanceMeasurement.collisionIntersectionException')})
        cameraControl.off(this.#_onMouseHoverSurface)
        return
      }

      if (!pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) {
        const pickedFaceTriangles = pickResult.trianglesPositions
        this.#_destroyHoveringTriangles()
        this.#_hoveringTriangles = this.#_drawTriangles(pickedFaceTriangles, this.#_hoveringTrianglesColor)
      }
    })

    this.#_onMouseHoverOut = cameraControl.on('hoverOff', () => {
      if (this.#_hoveringTriangles) this.#_destroyHoveringTriangles()
    })
  }

  static deactivate() {
    const input = XeokitMediator.viewer.scene.input
    const cameraControl = XeokitMediator.viewer.cameraControl
    const scene = XeokitMediator.viewer.scene

    input.off(this.#_onInputMouseDown)
    input.off(this.#_onInputMouseUp)
    input.off(this.#_onInputKeyDown)
    cameraControl.off(this.#_onMouseHoverSurface)
    cameraControl.off(this.#_onMouseHoverOut)
    scene.off(this.#_onSceneTick)

    this.#_destroyHoveringTriangles()
    this.#_destroyOriginPickedFace()
    this.#_destroyTargetPickedFace()
  }

  static #_drawTriangles(triangles, color) {
    let drawedTriangles = geometry.drawer.createSurfaceMesh(triangles, color)

    return drawedTriangles
  }
}
